import React, { useState, useEffect } from 'react';
import PopupDefault from "../../components/popups/PopupDefault";
import {GiLetterBomb} from "react-icons/gi";
import {BlogService} from "../../services";
import {Link} from "react-router-dom";
import Routes from "../../routes";
import AdminBlogCard from "../components/AdminBlogCard";

const PublishedBlogs = () => {
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [blogs, setBlogs] = useState();
    const [loading, setLoading] = useState(true);

    const [currentBlog, setCurrentBlog] = useState();

    const [alertTitle, setAlertTitle] = useState('');
    const [alertDesc, setAlertDesc] = useState('');

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await BlogService.fetchBlogs(); // Replace with actual endpoint
                setBlogs(response);
                setLoading(false);
                console.log(response);
                // setGameContent(response.games);
            } catch (error) {
                console.error('Error fetching blogs:', error);
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    const onOpenPopup = (title, desc, id, state) => {
        console.log(title, desc, id, state);
        setAlertTitle(title);
        setAlertDesc(desc);
        setCurrentBlog(id);
        setIsAlertOpen(true);
    };

    const popupApprove = () =>
    {
        deleteBlog();
    }

    const deleteBlog = async () => {
        try {
            const response = await BlogService.deleteBlog(currentBlog);
            console.log(response);
            setIsAlertOpen(false);
        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="col-span-8 overflow-hidden rounded-xl sm:bg-gray-50 sm:px-8 sm:shadow">
            {/* Overlay for blurry background */}
            {isAlertOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm z-10"></div>
            )}

            <PopupDefault
                isOpen={isAlertOpen}
                onClose={() => setIsAlertOpen(false)}
                title={"Do you want to delete blog?"}
                desc={"Blog will deleted from database"}
                icon={<GiLetterBomb size={30}/>}
                onApproveButton={popupApprove}/>

            <div className="container mx-auto mb-8">
                <h1 className="text-3xl font-bold mb-4 mt-4">Published Blogs</h1>

                {loading ? (
                    <div>Loading...</div>
                ) : (
                    blogs?.map((blog) => (
                        <AdminBlogCard
                            blog={blog}
                            key={blog._id}
                            openPopup={onOpenPopup}/>
                    ))
                )}

            </div>
        </div>
    );
};

export default PublishedBlogs;
