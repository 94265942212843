
import React from 'react';

const DashboardBilling = () => {
    return (
        <div className="col-span-8 overflow-hidden rounded-xl bg-white dark:bg-gray-800 sm:px-8 sm:shadow">

            <div className="">
                <div className="pt-4">
                    <h1 className="py-2 text-2xl font-semibold text-gray-900 dark:text-gray-100">Billing settings</h1>
                </div>
                <hr className="mt-4 mb-8 border-gray-200 dark:border-gray-700" />

                <div className="mb-10 grid gap-y-8 lg:grid-cols-2 lg:gap-y-0">
                    <div className="space-y-8">
                        <div className="">
                            <div className="flex">
                                <p className="font-medium mb-1 text-gray-900 dark:text-gray-100">Billing Period</p>
                                <button className="ml-auto inline-flex text-sm font-semibold text-blue-600 underline decoration-2">Change</button>
                            </div>
                            <div className="flex items-center rounded-md border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-600 py-3 shadow dark:shadow-md">
                                {/*
                        <p className="ml-4 w-56">
                            <strong className="block text-lg font-medium">MONTHLY</strong>
                            <span className="text-xs text-gray-400 dark:text-gray-500"> Next Renewal: 1 June 2024 </span>
                        </p>
                        */}
                                <p className="ml-4 w-56">
                                    <strong className="block text-lg font-medium text-gray-900 dark:text-gray-100">. </strong>
                                    <span className="text-xs text-gray-400 dark:text-gray-500">. </span>
                                </p>
                            </div>
                        </div>
                        <div className="">
                            <div className="flex">
                                <p className="font-medium mb-1 text-gray-900 dark:text-gray-100">Payment Method</p>
                                <button className="ml-auto inline-flex text-sm font-semibold text-blue-600 underline decoration-2">Change</button>
                            </div>
                            <div className="flex items-center rounded-md border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-600 py-3 shadow dark:shadow-md">
                                <img className="h-10 object-contain pl-4" src="https://componentland.com/images/kt10d0A1TgzZpAoNM_YPX.png" alt="" />
                                <p className="ml-4 w-56">
                                    <strong className="block text-lg font-medium text-gray-900 dark:text-gray-100">**** **** **** *** </strong>
                                    <strong className="block text-lg font-medium text-gray-900 dark:text-gray-100">...</strong>
                                    <span className="text-xs text-gray-400 dark:text-gray-500"> Expires on: Mm Yy </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="grid gap-y-6 gap-x-3 sm:grid-cols-2 lg:px-8">
                        <label className="block" htmlFor="name">
                            <p className="text-sm text-gray-900 dark:text-gray-100">Name</p>
                            <input className="w-full rounded-md border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-600 py-2 px-2 outline-none ring-blue-600 focus:ring-1 dark:ring-blue-500" type="text" value="" />
                        </label>
                        <label className="block" htmlFor="name">
                            <p className="text-sm text-gray-900 dark:text-gray-100">Email Address</p>
                            <input className="w-full rounded-md border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-600 py-2 px-2 outline-none ring-blue-600 focus:ring-1 dark:ring-blue-500" type="text" value="" />
                        </label>
                        <label className="block sm:col-span-2" htmlFor="name">
                            <p className="text-sm text-gray-900 dark:text-gray-100">Billing Address</p>
                            <input className="w-full rounded-md border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-600 py-2 px-2 outline-none ring-blue-600 focus:ring-1 dark:ring-blue-500" type="text" value="" />
                        </label>
                        <label className="block" htmlFor="name">
                            <p className="text-sm text-gray-900 dark:text-gray-100">VAT #</p>
                            <input className="w-full rounded-md border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-600 py-2 px-2 outline-none ring-blue-600 focus:ring-1 dark:ring-blue-500" type="text" value="" />
                        </label>
                        <label className="block" htmlFor="name">
                            <p className="text-sm text-gray-900 dark:text-gray-100">Country</p>
                            <input className="w-full rounded-md border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-600 py-2 px-2 outline-none ring-blue-600 focus:ring-1 dark:ring-blue-500" type="text" value="" />
                        </label>
                    </div>
                </div>

                <div className="mx-auto mb-10 overflow-hidden rounded-lg border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-600">
                    <p className="mb-6 bg-gray-100 dark:bg-gray-800 py-1 text-center text-lg font-medium text-gray-900 dark:text-gray-100">Billing History</p>
                    <table className="w-full">
                        <thead>
                        <tr>
                            <th className="text-center font-semibold text-gray-900 dark:text-gray-100">Date</th>
                            <th className="text-center font-semibold text-gray-900 dark:text-gray-100">Invoice #</th>
                            <th className="text-center font-semibold text-gray-900 dark:text-gray-100">Amount</th>
                            <th className="text-center font-semibold text-gray-900 dark:text-gray-100"></th>
                        </tr>
                        </thead>

                        <tbody>
                        {/*
                    <tr>
                        <td className="border-b py-2 text-center text-sm text-gray-900 dark:text-gray-100">20 Apr 2024</td>
                        <td className="border-b py-2 text-center text-sm text-gray-900 dark:text-gray-100">X-111222</td>
                        <td className="border-b py-2 text-center text-sm text-gray-900 dark:text-gray-100">$1000.00</td>
                        <td className="border-b py-2 text-center text-sm text-gray-900 dark:text-gray-100"><button className="text-sm text-blue-600 underline dark:text-blue-400">PDF</button></td>
                    </tr>
                    */}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    );
};

export default DashboardBilling;