import React, { useCallback, useEffect, useState } from 'react';
import { logEvent } from 'firebase/analytics';
import { useFirebase } from '../../../firebase/FirebaseContext';
import { useNavigate, useParams } from 'react-router-dom';

/* Services */
import { GameService, AuthenticationService } from '../../../services';
import OfferService from '../../../services/offer.service';

/* Routes */
import Routes from '../../../routes';

/* Utilities */
import { formatTime, formatPrice } from '../../../utilities';
import parseDeveloper from './utilities/parseDeveloper.utility';

/* Components */
import LoadingElement from '../../general/Loading/LoadingElement';
import GameInfoCard from './components/GameInfoCard';
import GameStatsCard from './components/GameStatsCard';
import GameDetailsCard from './components/GameDetailsCard';
import SellerCard from './components/SellerCard';
import FullScreenModal from './FullScreenModal';
import PopupDefault from '../../popups/PopupDefault';
import LOIForm from './LOIForm';
import RevenueProofs from './RevenueProofs';
import AnalyticsProofs from './AnalyticsProofs';
import ListingDescription from './ListingDescription';
import GameMedia from './GameMedia';
import GameDescription from './GameDescription';

/* Icons */
import { GiLetterBomb } from 'react-icons/gi';

/* Enums */
import { ApprovalStatus } from '../../../enums';

import GameStatsDetails from './GameStatsDetails';
import {FaEye} from "react-icons/fa";

const GameDetails = () => {
    const analytics = useFirebase();
    const navigate = useNavigate();
    const { id } = useParams();

    const [game, setGame] = useState(null);
    const [developer, setDeveloper] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isRevenueSectionOpen, setIsRevenueSectionOpen] = useState(false);
    const [isAnalyticsSectionOpen, setIsAnalyticsSectionOpen] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [user, setUser] = useState(AuthenticationService.getUser());
    const [offer, setOffer] = useState({
        offer: { value: undefined },
        submittedBy: { value: undefined },
        approval_status: { value: ApprovalStatus.PENDING },
        email: undefined,
        profilePicture: undefined,
        title: undefined,
        icon: undefined,
    });

    const catchError = useCallback((error) => {
        const code = error?.response?.status;
        switch (code) {
            case 404:
                navigate(Routes.Errors.NotFound);
                break;
            case 500:
                navigate(Routes.Errors.ServerError);
                break;
            default:
                setError(error);
        }
    }, [navigate]);

    useEffect(() => {
        const fetchGameDetails = async () => {
            try {
                const response = await GameService.fetchGame(id);
                setGame(response);
                setDeveloper(parseDeveloper(response));
                setOffer(prevOffer => ({
                    ...prevOffer,
                    submittedBy: user?.name,
                    email: user?.email,
                    profilePicture: user?.profilePicture,
                    title: response.title,
                    icon: response.icon,
                }));
                setLoading(false);

                logEvent(analytics, 'page_view', {
                    page_path: `/games/${id}`,
                    page_title: response.name || 'Loading...',
                });

                await GameService.gameViewed(id);
            } catch (error) {
                catchError(error);
            }
        };

        fetchGameDetails();
    }, [id, analytics, user, catchError]);

    if (loading) {
        return (
            <div className="mt-36 mb-36">
                <LoadingElement />
            </div>
        );
    }

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setIsModalOpen(true);
    };

    const handlePublish = async (event) => {
        event.preventDefault();
        try {
            await OfferService.publishOffer({
                ...offer,
                submittedBy: user?._id,
                status: { step: 1, status: 'LOISent' },
            });
            setIsAlertOpen(true);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChangeOffer = (event) => {
        const { name, value } = event.target;
        setOffer(prevOffer => ({ ...prevOffer, [name]: value }));
    };

    function formatNumber(number) {
        return number.toLocaleString('tr-TR'); // 'tr-TR' is for Turkish locale
    }

    return (
        <>
            <section className="py-12 sm:py-16">

                <div className="container mx-auto px-4">
                    <nav className="flex">
                        <ol role="list" className="flex items-center">
                            <li className="text-left">
                                <div className="-m-1">
                                    <a href="#" className="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800 dark:text-white"> Games </a>
                                </div>
                            </li>

                            <li className="text-left">
                                <div className="flex items-center">
                                    <span className="mx-2 text-gray-400">/</span>
                                    <div className="-m-1">
                                        <a href="#" className="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800 dark:text-white"> App Store </a>
                                    </div>
                                </div>
                            </li>

                            <li className="text-left">
                                <div className="flex items-center">
                                    <span className="mx-2 text-gray-400">/</span>
                                    <div className="-m-1">
                                        <a href="#" className="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800 dark:text-white" aria-current="page"> {game.genre} </a>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </nav>

                    <div className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                        <div className="lg:col-span-3 lg:row-end-1">
                            <GameInfoCard game={game} user={user} />

                            <GameMedia game={game} />

                            <GameStatsCard game={game} />
                            <GameDetailsCard game={game} />
                        </div>

                        <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                            <h1 className="sm: text-2xl font-bold text-gray-900 dark:text-white sm:text-3xl">{game.title}</h1>

                            <div className="mt-5 flex items-center">
                                <div className="flex items-center">
                                    <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
                                    </svg>
                                    <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
                                    </svg>
                                    <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
                                    </svg>
                                    <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
                                    </svg>
                                    <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
                                    </svg>
                                </div>
                                <p className="ml-2 text-sm font-medium text-gray-500 dark:text-white">{game?.reviewsCount} reviews</p>
                            </div>

                            {/*
                            <h2 className="mt-8 text-base text-gray-900">Game Metrics</h2>
                            <div className="mt-3 flex select-none flex-wrap items-center gap-1">
                                <label className="">
                                    <input type="radio" name="type" value="Powder" className="peer sr-only" checked />
                                    <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">D1: %35</p>
                                </label>
                                <label className="">
                                    <input type="radio" name="type" value="Whole Bean" className="peer sr-only" />
                                    <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">D7: %6</p>
                                </label>
                                <label className="">
                                    <input type="radio" name="type" value="Groud" className="peer sr-only" />
                                    <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">D30: ...</p>
                                </label>
                            </div>
                            */}

                            <h2 className="mt-8 text-base text-gray-900 dark:text-white">Analytics</h2>
                            <div className="mt-3 flex select-none flex-wrap items-center gap-1">
                                <label className="">
                                    <input type="radio" name="subscription" value="4 Months" className="peer sr-only" />
                                    <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border dark:text-white border-black dark:border-white px-6 py-2 font-bold">{`Installs +${formatNumber(game.total_downloads)}`}</p>
                                </label>
                                {/*
                                <label className="">
                                    <input type="radio" name="subscription" value="8 Months" className="peer sr-only" checked />
                                    <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">1M Store View</p>
                                </label>
                                <label className="">
                                    <input type="radio" name="subscription" value="12 Months" className="peer sr-only" />
                                    <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">12 Months</p>
                                </label>
                                */}
                            </div>

                            <LOIForm
                                offer={offer}
                                handleChangeOffer={handleChangeOffer}
                                handlePublish={handlePublish}
                            />

                            <SellerCard developer={developer} />

                            <div className="mt-6 flex flex-col items-center justify-between space-y-4 border-t border-b border-gray-200 dark:border-gray-700 py-4 sm:flex-row sm:space-y-0">
                                <div className="flex items-end">
                                    <h1 className="text-3xl font-bold text-gray-900 dark:text-white">PRICE: </h1>
                                    <span className="text-base text-gray-600 dark:text-gray-300">Contact Sales</span>
                                </div>

                                <button type="button" className="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-blue-600 hover:bg-green-500 px-12 py-3 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                    PURCHASE
                                </button>
                            </div>

                            <ul className="mt-8 space-y-2">
                                <li className="flex items-center text-left text-sm font-medium text-gray-600 dark:text-gray-300">
                                    <svg className="mr-2 block h-5 w-5 align-middle text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    Secure payment options
                                </li>

                                <li className="flex items-center text-left text-sm font-medium text-gray-600 dark:text-gray-300">
                                    <svg className="mr-2 block h-5 w-5 align-middle text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                                    </svg>
                                    Invoice
                                </li>
                            </ul>

                        </div>

                        <div class="lg:col-span-3">

                            <div className="border-b border-gray-300 dark:border-gray-700">
                                <nav className="flex gap-4">
                                    <a
                                        href="#"
                                        title=""
                                        className="border-b-2 border-gray-900 dark:border-white py-4 text-sm font-medium text-gray-900 dark:text-white hover:border-gray-400 dark:hover:border-gray-500 hover:text-gray-800 dark:hover:text-gray-300"
                                    >
                                        Description
                                    </a>

                                    <a
                                        href="#"
                                        title=""
                                        className="inline-flex items-center border-b-2 border-transparent py-4 text-sm font-medium text-gray-600 dark:text-gray-400 hover:border-gray-400 dark:hover:border-gray-500 hover:text-gray-800 dark:hover:text-gray-300"
                                    >
                                        Reviews
                                        <span className="ml-2 block rounded-full bg-gray-500 dark:bg-gray-600 px-2 py-px text-xs font-bold text-gray-100 dark:text-gray-200">
                {game?.reviewsCount}
            </span>
                                    </a>
                                </nav>
                            </div>


                            <ListingDescription game={game} />

                            <RevenueProofs
                                game={game}
                                isRevenueSectionOpen={isRevenueSectionOpen}  // This prop is being used to control the section visibility
                                setIsRevenueSectionOpen={setIsRevenueSectionOpen}  // This should be passed down correctly
                                handleImageClick={handleImageClick}
                            />


                            <AnalyticsProofs
                                game={game}
                                isAnalyticsSectionOpen={isAnalyticsSectionOpen}
                                setIsAnalyticsSectionOpen={setIsAnalyticsSectionOpen}
                                handleImageClick={handleImageClick}
                            />


                            <GameDescription game={game} />

                            <GameStatsDetails game={game} />
                        </div>
                    </div>
                </div>
            </section>

            <FullScreenModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <img src={selectedImage} alt="Selected" className="max-w-full max-h-full" />
            </FullScreenModal>

            {isAlertOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm z-10"></div>
            )}

            <PopupDefault
                isOpen={isAlertOpen}
                onClose={() => setIsAlertOpen(false)}
                title="LOI Sent Successfully"
                desc="Our Team Will Respond To Your LOI As Soon As Possible"
                icon={<GiLetterBomb size={30} />}
            />
        </>
    );
};

export default GameDetails;
