import { StatusCodes } from "http-status-codes";
/* Services */
import { AuthenticationService } from "../../services";
/* Brokers */
import { HTTPBroker } from "../index";
/* Custom Errors */
import { Forbidden,
         Unauthorized,
         NotFound
} from "../../errors";
/* Configurations */
import config from "../../configurations";

/*
    This class is responsible for handling the response.
    Weeding out the unwanted data from the response.
    Verifying that the response is valid, meaning not getting a 404 or 500 error.
 */
class TheResponseInterceptor {

    async onFulfilled(response){
        /* this method is called whenever a successful response is sent by the server */

        const acceptedStatusCodes = [StatusCodes.OK, StatusCodes.CREATED, StatusCodes.ACCEPTED, StatusCodes.NO_CONTENT];

        if(response && acceptedStatusCodes.includes(response.status)){
            // this means that the response is successful, parse the response body and return
            return response.data;
        }
        else{

            return Promise.reject( new Error("The response is not successful.") );
        }
    }
    async onRejected(error){
        /* this method is called whenever the server responds with an error */

        if(error){
            /* get the error message and error code */
            const errorMessage = error.response?.data?.message;
            const errorCode = error.response?.status;

            switch (errorCode) {
                case StatusCodes.NOT_FOUND:
                    return Promise.reject( new NotFound(errorMessage) );

                case StatusCodes.INTERNAL_SERVER_ERROR:
                    return Promise.reject(error);

                case StatusCodes.BAD_REQUEST:
                    return Promise.reject(error);

                case StatusCodes.UNAUTHORIZED:
                    // in this case the user might be authenticated, but the access token might have expired.
                    if(AuthenticationService.isAuthenticated()){
                        // refresh the access token
                        await AuthenticationService.refreshAuthTokens();

                        // retry the original request
                        const originalRequest = error.config;
                        // put the new access token in the header of the original request
                        originalRequest.headers.Authorization = `Bearer ${AuthenticationService.getAccessToken()}`;

                        for( let iteration = 1;
                             iteration <= config.getConfig("REFRESH_TOKEN_REQUEST_MAXIMUM_TRY_NUMBER");
                             iteration++){

                                try {
                                    // send the original request again
                                    const response = await HTTPBroker.request(originalRequest);
                                    return response?.data;
                                }
                                catch (error) {
                                    // if the request fails, retry for maximum number of times
                                    continue;
                                }
                        }
                        /* if the request fails after the maximum number of retries, then reject the request */
                        return Promise.reject(error);
                    }
                    // if the user is not authenticated, then throw an error
                    else return Promise.reject( new Unauthorized(errorMessage) );

                case StatusCodes.FORBIDDEN:
                    return Promise.reject( new Forbidden(errorMessage) );

                case StatusCodes.CONFLICT:
                    return Promise.reject(error);

                default:
                    return Promise.reject(error);
            }
        }
    }
}

export default TheResponseInterceptor;