import React from "react";
import { GiLetterBomb } from "react-icons/gi";

const LOIForm = ({ offer, handleChangeOffer, handlePublish }) => {
    return (
        <div className="mt-6 mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700">
            <div className="text-center">
                <p className="text-xl font-semibold text-gray-800 dark:text-gray-100">Letter of Intent</p>

                <label
                    htmlFor="offer"
                    className="mt-4 block relative rounded-md border border-gray-300 dark:border-gray-600 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                >
                    <input
                        type="number"
                        id="offer"
                        onChange={handleChangeOffer}
                        value={offer["offer"] || ""}
                        name="offer"
                        className="w-full px-4 py-2 border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 dark:text-gray-100"
                        placeholder="$1,000"
                    />
                    <span
                        className="pointer-events-none absolute left-4 top-1/2 transform -translate-y-1/2 bg-white dark:bg-gray-800 px-1 text-sm text-gray-500 dark:text-gray-400 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-focus:top-2 peer-focus:text-sm"
                    >
                Offer
            </span>
                </label>

                <div className="mt-6 flex justify-center">
                    <button
                        onClick={handlePublish}
                        className="group flex items-center justify-center w-full max-w-xs px-6 py-3 font-medium text-white bg-blue-600 rounded-full hover:bg-green-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50 transition-transform transform active:scale-95 dark:hover:bg-green-600"
                    >
                        <GiLetterBomb size={30} className="mr-3" />
                        <span>Submit Letter of Intent</span>
                        <div className="group-hover:flex absolute top-0 left-full z-10 hidden h-full items-center justify-center pl-4">
                            <div className="shrink-0 inline-block w-32 rounded-md bg-gray-700 dark:bg-gray-900 px-1 py-1 font-normal shadow" role="tooltip">
                                <p className="text-xs text-gray-100 dark:text-gray-300">Send your offer to owner of the game or broker</p>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>

    );
};

export default LOIForm;
