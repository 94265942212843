import React, { useEffect, useRef } from 'react';

const YouCanBookMe = () => {
    const scriptContainerRef = useRef(null); // Create a ref to reference the container div

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://embed.ycb.me';
        script.async = true;
        script.setAttribute('data-domain', 'bgojs');

        // Use the ref to append the script to the specific container div instead of the body
        scriptContainerRef.current.appendChild(script);

        return () => {
            // Make sure to remove the script from the specific container div
            if(script !== null)
                scriptContainerRef.current.removeChild(script);
        };
    }, []); // The empty array ensures the effect runs only once after the initial render

    return (
        <div className="mt-8">
            <div ref={scriptContainerRef}>
                {/* The script will be injected into this div */}
            </div>
        </div>
    );
};

export default YouCanBookMe;
