/* Contexts */
import { useGamePublishContext } from "../../contexts";
/* Helper Components */
import { Badge } from "flowbite-react";
import GameDetailsPreviewLoading from "./GameDetailsPreviewLoading";
import { Rating } from "../../../../general";
import { Platform } from "../../../common";

const GameDetailsPreview = () => {

    const { gameLinkLoading } = useGamePublishContext();
    const [ isGameLinkLoading, setIsGameLinkLoading ] = gameLinkLoading;

    const { gameDetailsFromStoreState } = useGamePublishContext();
    const [ gameDetailsFromStore, setGameDetailsFromStore ] = gameDetailsFromStoreState;

    return (
        <div className="flex justify-center">
            {
                <div className="rounded-xl shadow-lg bg-white max-w-xs">
                    {
                        !gameDetailsFromStore &&
                            <GameDetailsPreviewLoading loading={isGameLinkLoading}/>
                    }
                    {
                        /*
                           When the page loads for the first time, render a GameDetailsPreview as skeleton,
                           but do not animate, because  there is no loading since the user has not clicked the verify button yet,
                           whenever the user clicks the verify button, render loading skeleton animation (pulse)
                           and right after the details of the game is fetched from the server, remove the preview skeleton completely
                         */
                    }
                    {
                        gameDetailsFromStore &&
                        !isGameLinkLoading &&
                        <div>
                            <div className="p-6">
                                <img className="rounded-t-lg" src={gameDetailsFromStore.icon}
                                     alt={gameDetailsFromStore.title}/>
                            </div>
                            <div className="p-6">
                                <h5 className="text-gray-900 text-xl font-medium mb-2">{gameDetailsFromStore.title}</h5>
                                <div className="flex flex-wrap mt-3 mb-3">
                                    <Platform platform={gameDetailsFromStore.platform} rounded={false}/>
                                </div>
                                {
                                    gameDetailsFromStore?.installs &&
                                    <div className="flex flex-wrap mt-3 mb-3">
                                        <Badge color="pink" className="pr-3 pl-3">
                                            {gameDetailsFromStore.installs}
                                        </Badge>
                                    </div>
                                }
                                {
                                    gameDetailsFromStore?.score &&
                                    <div className="flex flex-wrap mt-4 mb-4">
                                        <Rating
                                            linkToReviews = {gameDetailsFromStore?.url}
                                            totalReviews = {gameDetailsFromStore?.reviews}
                                            rating = {gameDetailsFromStore?.score}/>
                                    </div>
                                }
                                <p className="text-gray-700 text-base mb-4 line-clamp-6">
                                    {gameDetailsFromStore?.description}
                                </p>
                                <div className="flex flex-wrap mt-3 mb-3">
                                    <p className="font-extrabold text-transparent text-6xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 tracking-wider text-gradi md:text-lg dark:text-gray-400">
                                        {gameDetailsFromStore?.developer}
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default GameDetailsPreview;