
const Currencies = Object.freeze({
  USD: {
    code: 'USD',
    symbol: '$',
  },
  EUR: {
    code: 'EUR',
    symbol: '€',
  },
  GBP: {
    code: 'GBP',
    symbol: '£',
  },
  JPY: {
    code: 'JPY',
    symbol: '¥',
  },
  AUD: {
    code: 'AUD',
    symbol: '$',
  },
  CAD: {
    code: 'CAD',
    symbol: '$',
  },
  CHF: {
    code: 'CHF',
    symbol: 'Fr',
  },
  CNY: {
    code: 'CNY',
    symbol: '¥',
  }
});

export default Currencies;