// layouts/DashboardLayout.js
import React from 'react';
import DashboardSidebar from "../navbar/DashboardSidebar";
import LoadingGrid from "../general/Loading/LoadingGrid";
import ApproveCard from "../../admin/mobile-components/ApproveCard";
import Navbar from "../navbar";

const DashboardLayout = ({ children }) => {
    return (
        <div>
            <Navbar/>
            <div className="flex h-full"> {/* This div becomes the flex container */}
                {/* Sidebar */}
                <DashboardSidebar/>

                {/* Main content area */}
                <div className="p-1 h-full w-full bg-gray-50">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default DashboardLayout;
