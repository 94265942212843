import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="App mt-12 max-w-4xl mx-auto p-4 bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-100">
            <div className="container mx-auto py-8">
                <h1 className="text-3xl font-semibold mb-6">Privacy Policy</h1>
                <p className="mb-4">
                    Last updated Apr 12, 2024. We NEVER sell your data to anyone.
                </p>
                <p className="mb-4">
                    This Privacy Policy applies to personal data processed by Scapcher.com in our business, including on our websites and other online or offline offerings (a “Service” or collectively, the “Services”). Scapcher.com enables the visitors of its site, <a href="https://scapcher.com/" className="text-blue-600 dark:text-blue-400">https://scapcher.com/</a>, to be in control of their Personal data. We also provide controls that allow Scapcher.com’s users/customers to have control over the privacy of Personal Data that is captured by our Services. This Privacy Policy provides information about how Scapcher.com processes and protects this data.
                </p>
                <p className="mb-4">
                    Scapcher.com considers data protection and privacy to be of paramount importance. We never sell Personal Data and we carry out all processing operations in compliance with the European Union General Data Protection Regulation (“GDPR”) (specifically but not limited to Article 6(1)(b) to (f) and Article 28) and other applicable global privacy and data protection laws including the California Consumer Privacy Act (“CCPA”) (collectively, the “Applicable Law”).
                </p>
                <p className="mb-4">
                    For this Privacy Policy, "Personal Data" means any information relating to an identified or identifiable natural person (or household as applicable); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person. Personal Data also includes any equivalent definition in the Applicable Law.
                </p>
                <h2 className="text-2xl font-semibold mb-4">Responsibility for Processing Personal Data</h2>
                <p className="mb-4">
                    Scapcher.com bears the responsibility for lawfully processing your data as carried out on our site.
                </p>
                <h2 className="text-2xl font-semibold mb-4">What kind of Personal Data is collected and/or processed, and for what purpose?</h2>
                <p className="mb-4">
                    <strong>Usage Data</strong><br />
                    When you visit our site, the website from which you visited us, the parts of our site you visit, the date and duration of your visit, and information from the device (device type, operating system, language, the country you are located in, and web browser type) you used during your visit. While we process your IP address during the duration of your session, the IP address is completely deleted before it is ever written to disk on our servers.
                </p>
                <p className="mb-4">
                    We process this usage data to facilitate your access to our Services (e.g. to adjust our Services to the device you are using) and to recognize and stop any misuse which is in our legitimate interest. The legal basis for this data processing is Article 6(1)(f) GDPR. We also process usage data in an aggregated or de-identified form for statistical purposes and to improve our site.
                </p>
                <p className="mb-4">
                    <strong>Cookies</strong><br />
                    Cookies are small data files transferred onto computers or devices. Scapcher.com uses cookies to process information including standard internet log information and details of the visitor’s behavioral patterns upon visiting our site. This is done to (i) operate our site and to (ii) provide you with a better experience by providing us with insights on how our users use our site.
                </p>
                <p className="mb-4">
                    Scapcher.com's tracking technology is free of cookies.
                </p>
                <p className="mb-4">
                    <strong>Contact us via email</strong><br />
                    On our site, you have the opportunity to contact us to ask us questions, for example via the contact form, where we ask you for your contact information (e.g. email address). We use this data solely in connection with answering the queries we receive. The legal basis for this processing is Article 6(1)(f) GDPR.
                </p>
                <p className="mb-4">
                    <strong>Scapcher.com Account</strong><br />
                    When you sign up for and open an account with Scapcher.com, or sign up for content or offers, we may ask you to provide us with information such as your name, email address and details about your organization. As otherwise detailed in this Privacy Policy, we will solely process this information to provide you with the Service you signed up for in accordance with Article 6(1)(b) GDPR.
                </p>
                <p className="mb-4">
                    You may deactivate your Scapcher.com account and/or unsubscribe from receiving content or offers from us at any time. Following termination of your account, we may retain your personal data (in part or in whole) in order to meet any regulatory and reporting requirements for the timeframes stipulated by law and in order to be able to address customer service issues. Any other personal data we would have been processing on your behalf would be deleted permanently within 30 calendar days.
                </p>
                <p className="mb-4">
                    <strong>Use of De-Identified and Aggregated Information</strong><br />
                    We may use Personal Data and other data about you to create de-identified and aggregated information, including: de-identified demographic information, de-identified location information, information about computer or device from which you access our Services, or other analyses we create.
                </p>
                <p className="mb-4">
                    We use Userguiding for these purposes. Your data is protected under Scapcher.com's and our tool partners' privacy policies, with complete rights to the collected data.
                </p>
                <h2 className="text-2xl font-semibold mb-4">Access and Disclosure to Third Parties</h2>
                <p className="mb-4">
                    Scapcher.com does not sell, and has not sold, consumers’ Personal Data at any time. Except as provided below, we also do not share or disclose your Personal Data.
                </p>
                <p className="mb-4">
                    We use a select number of trusted external service providers for certain technical data analysis, processing and/or storage offerings (e.g., IT and related services). These service providers are carefully selected and meet high data protection and security standards. We only share information with them that is required for the services offered and we contractually bind them to keep any information we share with them as confidential and to process Personal Data only according to our instructions.
                </p>
                <p className="mb-4">
                    In addition to services providers, other categories of third parties may include:
                </p>
                <ul className="list-disc pl-8 mb-4">
                    <li>Vendors/public institutions. To the extent that this is necessary in order to make use of certain services requiring special expertise (such as legal, accounting or auditing services) we may share your personal data with vendors of such services or public institutions that offer them (e.g. courts). The legal basis of this data processing is Art. 6(1)(f) GDPR.</li>
                    <li>Disclosures to Protect Us or Others. We may access, preserve, and disclose any information we store associated with you to external parties if we, in good faith, believe doing so is required or appropriate to: comply with law enforcement or national security requests and legal process, such as a court order or subpoena; to protect your, our or others’ rights, property, or safety; enforce our policies or contracts; collect amounts owed to us; or to assist with an investigation or prosecution of suspected or actual illegal activity; depending on the concrete issue, the legal basis for such processing may be Art. 6(1)(b), (c) or (f) GDPR.</li>
                    <li>Disclosure in the Event of Merger, Sale, or Other Asset Transfers. If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service to another provider, then your information may be sold or transferred as part of such a transaction, as permitted by law and/or contract. The legal basis for such processing would be Art. 6(1)(f) GDPR as such processes are in the legitimate interest of Scapcher.com .</li>
                </ul>
                <p className="mb-4">
                    Other than the cases mentioned above, we will only pass your data on to third parties without your express consent in accordance with Article 6(1)(a) GDPR or if we are obliged to do so by statutory law or an instruction by a public authority or court as outlined in our Terms of Service.
                </p>
                <h2 className="text-2xl font-semibold mb-4">Notice regarding Third-Party Websites</h2>
                <p className="mb-4">
                    The Services may contain links to other websites, and other websites may reference or link to our website or other Services. These other websites are not controlled by Scapcher.com . We encourage our users to read the privacy policies of each website and application with which they interact. We do not endorse, screen or approve and are not responsible for the privacy practices or content of such other websites or applications. Visiting these other websites or applications is at your own risk.
                </p>
                <h2 className="text-2xl font-semibold mb-4">Data Hosting</h2>
                <p className="mb-4">
                    Scapcher.com hosts data on AWS's eu-central-1 server in Frankfurt, Germany.
                </p>
                <h2 className="text-2xl font-semibold mb-4">We want to Communicate with You</h2>
                <p className="mb-4">
                    We may occasionally send you notification emails about updates to our product, legal documents, offer customer support or marketing emails. To the extent required by Applicable Law, we will only send you such messages if you have given your consent in accordance with Art. 6(1)(a) GDPR. In all other cases the legal basis of this data processing is Art. 6(1)(f) GDPR. Except for cases where we are required to do so by law (e.g. notifying you of a data breach), you shall have the opportunity to unsubscribe from receiving these messages free of charge. We process requests to be placed on do-not-contact lists as required by Applicable Law.
                </p>
                <h2 className="text-2xl font-semibold mb-4">Your Data, Your Rights</h2>
                <p className="mb-4">
                    You have a right to be informed of Personal Data processed by Scapcher.com, a right to rectification/correction, erasure, and restriction of processing (subject to certain exceptions and other requirements prescribed by law). You also have the right to receive from Scapcher.com a structured, common, and machine-readable format of Personal Data you provided to us.
                </p>
                <p className="mb-4">
                    To protect your privacy, we take steps to verify your identity before fulfilling your request. We can only identify you via your email address and we can only adhere to your request and provide information if we have Personal Data about you through you having made contact with us directly and/or you using our site and/or service. We cannot provide, rectify or delete any Personal Data that we store on behalf of our users or customers.
                </p>
                <p className="mb-4">
                    To exercise any of the rights mentioned in this Privacy Policy and/or in the event of questions or comments relating to the use of Personal Data you can contact Scapcher.com at <a href="mailto:info@melihbahri.com" className="text-blue-600 dark:text-blue-400">info@melihbahri.com</a>.
                </p>
                <p className="mb-4">
                    When you have provided consent, you may withdraw it at any time, without affecting the lawfulness of the processing that was carried out prior to withdrawing it. Whenever you withdraw consent, you acknowledge and accept that this may have a negative influence on the quality of the Scapcher.com Site and/or Services. Please be aware that when you withdraw consent, we may delete the Personal Data previously processed on the basis of your consent and will not be allowed to keep it further to be accessed, downloaded, or otherwise secured by you.
                </p>
                <p className="mb-4">
                    Where Personal Data is processed for the above purposes on the basis of Scapcher.com's legitimate interests, under the GDPR, you may object to such processing at any time. To do so please contact <a href="mailto:info@melihbahri.com" className="text-blue-600 dark:text-blue-400">info@melihbahri.com</a>.
                </p>
                <p className="mb-4">
                    We can sign a Data Processing Agreement at all times with your company.
                </p>
                <p className="mb-4">
                    In addition, you have the right to complain about the data protection authority.
                </p>
                <h2 className="text-2xl font-semibold mb-4">Duration of Processing</h2>
                <p className="mb-4">
                    We will store your usage data until such time when you withdraw your consent for us to do so. All other data as specified above will be retained for as long as is necessary for the purpose(s) for which we originally collected it or to provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, and enforce our agreements. We may also retain information as required by law.
                </p>
                <h2 className="text-2xl font-semibold mb-4">Children’s Information</h2>
                <p className="mb-4">
                    The Services are not directed to children under 13 (or other age as required by local law), and we do not knowingly collect Personal Data from children. If you learn that your child has provided us with Personal Data without your consent, you may contact us as set forth below. If we learn that we have collected any Personal Data in violation of Applicable Law, we will promptly take steps to delete such information and terminate the child’s account.
                </p>
                <h2 className="text-2xl font-semibold mb-4">Revisions to this Privacy Policy</h2>
                <p className="mb-4">
                    We may revise this Privacy Policy from time to time. The most current version of this Privacy Policy will govern our practices for collecting, processing, and disclosing personal data. We will provide notice of any modifications by posting a written notice on our site.
                </p>
                <p className="mb-4">
                    If you have an account with us, we will notify you of any material modifications by sending you an email to the email address associated with your account unless you have unsubscribed from all email communications. Your use of the site and/or Services following the effective date of any modifications to the Privacy Policy will constitute your acceptance of the Privacy Policy, as modified. All changes to this Privacy Policy automatically take effect on the sooner of the day you use the site and/or services or 30 calendar days after they are initially posted on our site. Your use of the site and/or Services following the effective date of any modifications to this Privacy Policy will constitute your acceptance of the Privacy Policy, as modified.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
