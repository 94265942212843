import React from 'react';

const GameDescription = ({ game }) => {
    return (
        <div>
            <div className="bg-white dark:bg-gray-900 border-2 border-gray-300 dark:border-gray-700 rounded-lg p-4 mb-4 flex flex-col mt-6">
                <p className="mt-2 font-bold text-black dark:text-white text-lg">Game Description from App Store</p>
                <p className="mt-6 text-gray-700 dark:text-white">{game.description}</p>
            </div>
        </div>

    );
};

export default GameDescription;
