import React, {Component} from 'react';
import Routes from "../../routes";

class Announcement extends Component {
    render() {
        return (
            <div className="relative">
                <div className="bg-blue-600 px-4 py-2 text-white rounded-md shadow-lg dark:shadow-xl backdrop-blur-md dark:bg-blue-800 dark:text-gray-200">
                    <p className="text-center text-sm font-medium">
                        Welcome to Exclusive Game Business
                        <a href={Routes.Games.Featured} className="pl-1 inline-block underline hover:text-blue-200 dark:hover:text-blue-300">
                            Marketplace
                        </a>
                    </p>
                </div>
            </div>

        );
    }
}

export default Announcement;