import {Currencies} from "../enums";


const DEFAULT_CURRENCY = Currencies.USD.code;

const DEFAULT_FRACTION_DIGITS = 2;


function formatPrice(value, currency, includeFractionDigits, numberofFractionDigits) {

    let fractionDigits;

    /* do not display any fraction digits */
    if( includeFractionDigits === false ) fractionDigits = 0;
    else if ( includeFractionDigits === true && !numberofFractionDigits) fractionDigits = DEFAULT_FRACTION_DIGITS;
    else fractionDigits = numberofFractionDigits;

    const options = {
        style: 'currency',
        currency: currency || DEFAULT_CURRENCY,
        minimumFractionDigits: fractionDigits
    };

    return new Intl.NumberFormat("en-US", options).format(value);
}

export default formatPrice;