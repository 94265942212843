import {GameBroker, OfferBroker, UserBroker} from "../agents";

const getOffers = async () => {
    const response = await OfferBroker.get("/");
    return response;
};

const publishOffer = async (offer) => {
    const response = await OfferBroker.post("/", offer);
    return response;
};

const deleteOffer = async (id) => {
    const response = await OfferBroker.delete(`/${id}`);
    return response;
};

const fetchOffer = async (id) => {
    const response = await OfferBroker.get(`/${id}`);
    return response;
};

const getOfferDetailsByOfferId = async (id) => {
    const response = await OfferBroker.get(`/offerDetails/${id}`);
    return response;
};

const fetchOffersByUserId = async (id) => {
    const response = await OfferBroker.get(`/${id}`);
    console.log(response);
    return response;
};

const handleUpdateFullNameAndLegalAddress = async (id, updatedVariables) => {
    const response = await OfferBroker.put(`/${id}/updateFullNameAndLegalAddress`, updatedVariables);
    return response;
};

const handleUpdateAppleIdAndTeamId = async (id, updatedVariables) => {
    const response = await OfferBroker.put(`/${id}/updateAppleIdAndTeamId`, updatedVariables);
    return response;
};

const handleUpdateContractLink = async (id, updatedVariables) => {
    const response = await OfferBroker.put(`/${id}/updateContractLink`, updatedVariables);
    return response;
};

const handleUpdateStatus = async (id, updatedVariables) => {
    const response = await OfferBroker.put(`/${id}/updateStatusTo${updatedVariables}`);
    return response;
};

const OfferService = {
    getOffers,
    publishOffer,
    deleteOffer,
    fetchOffer,
    fetchOffersByUserId,
    handleUpdateFullNameAndLegalAddress,
    handleUpdateAppleIdAndTeamId,
    handleUpdateContractLink,
    handleUpdateStatus,
    getOfferDetailsByOfferId
};

export default OfferService;