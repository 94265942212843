import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'
/* Use English Time Locale */

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo('en-US')


function formatTime (date, agoStampEnabled) {
    /* this method displays the given UTC Date string in the local date format
        with enabling or disabling "ago" phrase
     */

    const formattedTimeString = timeAgo.format(date);

    if ( agoStampEnabled === false ) return formattedTimeString.replace(" ago", "");
    else return " " + formattedTimeString;
}

export default formatTime;