import React, { useEffect, useState } from 'react';
import { AuthenticationService } from "../../services";
import UserService from "../../services/user.service";
import PopupDefault from "../popups/PopupDefault";
import { GiCheckMark, GiCancel } from "react-icons/gi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const UserAccount = () => {
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertType, setAlertType] = useState(''); // 'success' or 'error'
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        profilePicture: '',
        name: '',
        email: '',
        role: '',
        title: '',
        about: '',
        linkedin: '',
        twitter: '',
        phoneNumber: '',
        transactionsTotalling: 0,
        overallAcquisitionBudget: 0,
        preferredDealSize: 0,
        currentlyOwns: 0,
    });

    useEffect(() => {
        const userID = AuthenticationService.getUser().id;
        async function fetchData() {
            try {
                const response = await UserService.fetchUser(userID);
                setUser(response);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setUser({
            ...user,
            [name]: type === 'number' ? Number(value) : value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setUser({
                ...user,
                profilePicture: imageUrl,
            });
        }
    };

    const handleUpdate = async () => {
        setLoading(true);
        try {
            await UserService.updateUser(user.id, user);
            setLoading(false);
            setAlertType('success');
            setAlertMessage('Account updated successfully!');
            setIsAlertOpen(true);
        } catch (error) {
            setLoading(false);
            console.log(error);
            setAlertType('error');
            setAlertMessage('Failed to update account. Please try again.');
            setIsAlertOpen(true);
        }
    };

    const handleCancel = () => {
        const userID = AuthenticationService.getUser().id;
        async function fetchData() {
            try {
                const response = await UserService.fetchUser(userID);
                setUser(response);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    };

    useEffect(() => {
        if (isAlertOpen) {
            const timer = setTimeout(() => setIsAlertOpen(false), 3000);
            return () => clearTimeout(timer);
        }
    }, [isAlertOpen]);

    return (
        <div className="my-8 max-w-4xl mx-auto bg-white dark:bg-gray-800 shadow-xl rounded-lg p-6">
            <div className="border-b pb-6 mb-6 border-gray-200 dark:border-gray-700">
                <div className="flex items-center justify-between">
                    <div>
                        <h2 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">Account Details</h2>
                        <p className="text-gray-600 dark:text-gray-400">Edit your account details</p>
                    </div>
                    <div className="hidden sm:flex space-x-3">
                        <button
                            onClick={handleCancel}
                            className="px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleUpdate}
                            className="px-4 py-2 rounded-lg bg-blue-600 dark:bg-blue-500 text-white hover:bg-blue-700 dark:hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>

            {/* Profile Picture Section */}
            <div className="mb-6">
                <h3 className="text-lg font-medium mb-4 text-gray-900 dark:text-gray-100">Profile Picture</h3>
                <div className="flex items-center space-x-6">
                    <img
                        src={user.profilePicture || "/images/default-avatar.png"}
                        alt="Profile"
                        className="w-24 h-24 rounded-full object-cover border border-gray-300 dark:border-gray-600"
                    />
                    <div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            className="block w-full text-sm text-gray-600 dark:text-gray-300
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-blue-50 dark:file:bg-blue-600 file:text-blue-700 dark:file:text-blue-300
                    hover:file:bg-blue-100 dark:hover:file:bg-blue-500"
                        />
                        <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                            SVG, PNG, JPG or GIF (max. 800x800px)
                        </p>
                    </div>
                </div>
            </div>

            {/* Personal Information Section */}
            <div className="mb-6">
                <h3 className="text-lg font-medium mb-4 text-gray-900 dark:text-gray-100">Personal Information</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Full Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={user.name}
                            onChange={handleChange}
                            className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-blue-500 dark:focus:border-blue-400"
                            placeholder="John Doe"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Email Address
                        </label>
                        <input
                            type="email"
                            name="email"
                            value={user.email}
                            onChange={handleChange}
                            className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-blue-500 dark:focus:border-blue-400"
                            placeholder="you@example.com"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Phone Number
                        </label>
                        <input
                            type="text"
                            name="phoneNumber"
                            value={user.phoneNumber}
                            onChange={handleChange}
                            className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-blue-500 dark:focus:border-blue-400"
                            placeholder="+1 234 567 890"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Title
                        </label>
                        <input
                            type="text"
                            name="title"
                            value={user.title}
                            onChange={handleChange}
                            className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-blue-500 dark:focus:border-blue-400"
                            placeholder="Software Engineer"
                        />
                    </div>
                    <div className="sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            About
                        </label>
                        <textarea
                            name="about"
                            value={user.about}
                            onChange={handleChange}
                            rows={4}
                            className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-blue-500 dark:focus:border-blue-400"
                            placeholder="A brief description about yourself"
                        />
                    </div>
                </div>
            </div>

            {/* Social Links Section */}
            <div className="mb-6">
                <h3 className="text-lg font-medium mb-4 text-gray-900 dark:text-gray-100">Social Links</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            LinkedIn
                        </label>
                        <input
                            type="url"
                            name="linkedin"
                            value={user.linkedin}
                            onChange={handleChange}
                            className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-blue-500 dark:focus:border-blue-400"
                            placeholder="https://linkedin.com/in/username"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Twitter
                        </label>
                        <input
                            type="url"
                            name="twitter"
                            value={user.twitter}
                            onChange={handleChange}
                            className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-blue-500 dark:focus:border-blue-400"
                            placeholder="https://twitter.com/username"
                        />
                    </div>
                </div>
            </div>

            {/* Financial Information Section */}
            <div className="mb-6">
                <h3 className="text-lg font-medium mb-4 text-gray-900 dark:text-gray-100">Financial Information</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Transactions Totalling
                        </label>
                        <input
                            type="number"
                            name="transactionsTotalling"
                            value={user.transactionsTotalling}
                            onChange={handleChange}
                            className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-blue-500 dark:focus:border-blue-400"
                            placeholder="0"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Overall Acquisition Budget
                        </label>
                        <input
                            type="number"
                            name="overallAcquisitionBudget"
                            value={user.overallAcquisitionBudget}
                            onChange={handleChange}
                            className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-blue-500 dark:focus:border-blue-400"
                            placeholder="0"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Preferred Deal Size
                        </label>
                        <input
                            type="number"
                            name="preferredDealSize"
                            value={user.preferredDealSize}
                            onChange={handleChange}
                            className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-blue-500 dark:focus:border-blue-400"
                            placeholder="0"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Currently Owns
                        </label>
                        <input
                            type="number"
                            name="currentlyOwns"
                            value={user.currentlyOwns}
                            onChange={handleChange}
                            className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-blue-500 dark:focus:border-blue-400"
                            placeholder="0"
                        />
                    </div>
                </div>
            </div>

            {/* Role Information */}
            <div className="mb-6">
                <h3 className="text-lg font-medium mb-4 text-gray-900 dark:text-gray-100">Role Information</h3>
                <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Role
                    </label>
                    <p className="text-gray-800 dark:text-gray-300">{user.role || 'N/A'}</p>
                </div>
            </div>

            {/* Mobile Buttons */}
            <div className="flex justify-end space-x-3 sm:hidden">
                <button
                    onClick={handleCancel}
                    className="px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600"
                >
                    Cancel
                </button>
                <button
                    onClick={handleUpdate}
                    className="px-4 py-2 rounded-lg bg-blue-600 dark:bg-blue-500 text-white hover:bg-blue-700 dark:hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
                >
                    {loading ? (
                        <AiOutlineLoading3Quarters className="animate-spin h-5 w-5 mx-auto" />
                    ) : (
                        'Save'
                    )}
                </button>
            </div>

            {/* Success/Error Popup */}
            {isAlertOpen && (
                <>
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm z-10"></div>
                    <PopupDefault
                        isOpen={isAlertOpen}
                        onClose={() => setIsAlertOpen(false)}
                        title={alertType === 'success' ? "Account Updated Successfully" : "Update Failed"}
                        desc={alertMessage}
                        icon={alertType === 'success' ? <GiCheckMark size={30} /> : <GiCancel size={30} />}
                        iconColor={alertType === 'success' ? "text-green-500" : "text-red-500"}
                    />
                </>
            )}
        </div>

    );
};

export default UserAccount;
