import React from 'react';
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FiDollarSign } from 'react-icons/fi';
import OfferService from "../../services/offer.service";

const OfferPaymentSection = ({ currentOffer, openModal, refreshAsGameTransfer }) => {

    const handleUpdateStatus = async (status) => {
        try {
            const response = await OfferService.handleUpdateStatus(currentOffer?._id, status);
            refreshAsGameTransfer();
            openModal();
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>

            <div className="max-w-lg mb-8">
                <h2 className="text-2xl font-semibold mb-4">Payment</h2>
                <div className="max-w-md w-full p-8 bg-white rounded-lg shadow-lg">
                    <h2 className="text-2xl font-bold mb-4">Banking Information</h2>
                    <div className="mb-4">
                        <p className="text-gray-700">Currency: EUR | USD</p>
                    </div>
                    <div className="mb-4">
                        <p className="text-gray-700">Full Name of Client: MELIH BAHRI AKTAN</p>
                    </div>
                    <div className="mb-4">
                        <p className="text-gray-700">IBAN: BA391861815410019394</p>
                    </div>
                    <div className="mb-4">
                        <p className="text-gray-700">SWIFT CODE: TZBBBA22</p>
                    </div>
                    <div className="mb-4">
                        <p className="text-gray-700">Full Bank Name: ZIRAATBANK BH D.D.</p>
                    </div>
                    <div className="mb-4">
                        <p className="text-gray-700">Customer Address: FRANCUSKE REVOLUCIJE BB SARAJEVO ILIDZA BOSNA I HERCEGOVINA</p>
                    </div>
                </div>
                <div className="mt-6">
                    <h3 className="text-lg font-semibold mb-2">Amount to Pay</h3>
                    <p className="text-gray-600">Please transfer the amount in USD or EUR.</p>
                    {/* Add input for amount if needed */}
                    {/* Example: <input type="number" className="border border-gray-300 rounded-md px-3 py-2 mt-2 w-full" placeholder="Enter amount in EUR" /> */}
                </div>
                <button onClick={() => handleUpdateStatus('GameTransfer')} className="mt-6 bg-emerald-500 hover:bg-emerald-300 text-white font-semibold py-2 px-4 rounded-3xl shadow-md">
                    Proceed to Transfer
                </button>
            </div>

            <span className="flex items-center mb-8">
                <span className="pr-6">Currently Inactive, Will Be Activated After Q2 2024</span>
                <span className="h-px flex-1 bg-black"></span>
            </span>

            <div className="grid-cols-2 grid gap-4 ">
                <div className="cursor-not-allowed w-full mb-4 h-full bg-white rounded-3xl shadow-md overflow-hidden">
                    <div className="px-4 py-4">
                        <div className="mb-6 flex items-center justify-between">
                            <div className="flex items-center">
                                <div className="rounded-full bg-blue-500 text-white p-3">
                                    <FiDollarSign size={24} />
                                </div>
                                <div className="ml-4">
                                    <h3 className="text-lg font-semibold text-gray-800">Wise</h3>
                                    <p className="text-gray-600">Extra %0</p>
                                </div>
                            </div>
                        </div>
                        <button disabled className="cursor-not-allowed px-4 py-2 bg-black text-white font-semibold rounded-3xl  shadow-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 transition-colors">
                            Proceed with Wise
                        </button>
                    </div>
                </div>

                <div className="cursor-not-allowed w-full mb-4 h-full bg-white rounded-3xl shadow-md overflow-hidden">
                    <div className="px-4 py-4">
                        <div className="mb-6 flex items-center justify-between">
                            <div className="flex items-center">
                                <div className="rounded-full bg-blue-500 text-white p-3">
                                    <FiDollarSign size={24} />
                                </div>
                                <div className="ml-4">
                                    <h3 className="text-lg font-semibold text-gray-800">Stripe</h3>
                                    <p className="text-gray-600">Pay with Credit Card</p>
                                </div>
                            </div>
                        </div>
                        <button disabled className="cursor-not-allowed px-4 py-2 bg-black text-white font-semibold rounded-3xl  shadow-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 transition-colors">
                            Proceed with Stripe
                        </button>
                    </div>
                </div>

                <div className="cursor-not-allowed w-full mb-4 h-full bg-white rounded-3xl shadow-md overflow-hidden">
                    <div className="px-4 py-4">
                        <div className="mb-6 flex items-center justify-between">
                            <div className="flex items-center">
                                <div className="rounded-full bg-blue-500 text-white p-3">
                                    <FiDollarSign size={24} />
                                </div>
                                <div className="ml-4">
                                    <h3 className="text-lg font-semibold text-gray-800">Escrow</h3>
                                    <p className="text-gray-600">7 days, Extra +%10</p>
                                </div>
                            </div>
                        </div>
                        <button disabled className="cursor-not-allowed px-4 py-2 bg-black text-white font-semibold rounded-3xl  shadow-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 transition-colors">
                            Proceed on Escrow
                        </button>
                    </div>
                </div>

                <div className="cursor-not-allowed w-full mb-4 h-full bg-white rounded-3xl shadow-md overflow-hidden">
                    <div className="px-4 py-4">
                        <div className="mb-6 flex items-center justify-between">
                            <div className="flex items-center">
                                <div className="rounded-full bg-blue-500 text-white p-3">
                                    <FiDollarSign size={24} />
                                </div>
                                <div className="ml-4">
                                    <h3 className="text-lg font-semibold text-gray-800">PayPal</h3>
                                    <p className="text-gray-600">1 days, Extra +%2</p>
                                </div>
                            </div>
                        </div>
                        <button disabled className="cursor-not-allowed px-4 py-2 bg-black text-white font-semibold rounded-3xl  shadow-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 transition-colors">
                            Proceed on PayPal
                        </button>
                    </div>
                </div>

                <div className="cursor-not-allowed w-full mb-4 h-full bg-white rounded-3xl shadow-md overflow-hidden">
                    <div className="px-4 py-4">
                        <div className="mb-6 flex items-center justify-between">
                            <div className="flex items-center">
                                <div className="rounded-full bg-blue-500 text-white p-3">
                                    <FiDollarSign size={24} />
                                </div>
                                <div className="ml-4">
                                    <h3 className="text-lg font-semibold text-gray-800">Payoneer</h3>
                                    <p className="text-gray-600">1 days, Extra +%2</p>
                                </div>
                            </div>
                        </div>
                        <button disabled className="cursor-not-allowed px-4 py-2 bg-black text-white font-semibold rounded-3xl shadow-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 transition-colors">
                            Proceed on Payoneer
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default OfferPaymentSection;
