import React from 'react';
import Navbar from "../navbar";
import Footer from "../footer";
import DarkModeToggle from "../DarkModeToggle";

const LandingLayout = ({ children }) => {
    return (
        <div>
            {/* Common layout elements for landing page */}
            <Navbar/>
            <main>
                {children}
            </main>
            <Footer/>
        </div>
    );
}

export default LandingLayout;
