import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SteamService from "../../services/steam.service";

const SteamEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        subtitle: '',
        description: '',
        url: '',
        icon: '',
        screenshots: [''],
        video: '',
        developer: '',
    });

    useEffect(() => {
        const fetchGame = async () => {
            try {
                const response = await SteamService.fetchSteamGame(id);
                setFormData(response);
            } catch (error) {
                console.error('Error fetching game details', error);
            }
        };

        fetchGame();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleScreenshotChange = (index, e) => {
        const newScreenshots = formData.screenshots.map((screenshot, sIndex) => {
            if (index === sIndex) {
                return e.target.value;
            }
            return screenshot;
        });
        setFormData({
            ...formData,
            screenshots: newScreenshots,
        });
    };

    const addScreenshotField = () => {
        setFormData({
            ...formData,
            screenshots: [...formData.screenshots, ''],
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await SteamService.updateSteamGame(id, formData);
            console.log('Steam game updated successfully: ', response);
            alert('Steam entry updated successfully!');
            // navigate(`/game/${id}`);
        } catch (error) {
            console.error('Error updating Steam entry', error);
            alert('Failed to update Steam entry');
        }
    };

    return (
        <div className="max-w-2xl mx-auto p-8 bg-white shadow-md rounded-lg">
            <h1 className="text-2xl font-bold mb-6">Edit Steam Entry</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Title</label>
                    <input
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Subtitle</label>
                    <input
                        type="text"
                        name="subtitle"
                        value={formData.subtitle}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Description</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">URL</label>
                    <input
                        type="text"
                        name="url"
                        value={formData.url}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Icon URL</label>
                    <input
                        type="text"
                        name="icon"
                        value={formData.icon}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Screenshots</label>
                    {formData.screenshots.map((screenshot, index) => (
                        <div key={index} className="flex items-center mb-2">
                            <input
                                type="text"
                                value={screenshot}
                                onChange={(e) => handleScreenshotChange(index, e)}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder={`Screenshot ${index + 1} URL`}
                            />
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={addScreenshotField}
                        className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Add Screenshot
                    </button>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Video URL</label>
                    <input
                        type="text"
                        name="video"
                        value={formData.video}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Developer</label>
                    <input
                        type="text"
                        name="developer"
                        value={formData.developer}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="bg-green-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Save Changes
                </button>
            </form>
        </div>
    );
};

export default SteamEdit;
