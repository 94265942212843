import React from "react";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { Rating } from "../../../general";
import { Platform } from "../../common";
import Routes from "../../../../routes";

const GameInfoCard = ({ game, user }) => {
    return (
        <>
            <div className="group shadow-lg border-2 border-gray-200 dark:border-gray-700 relative flex flex-col md:flex-row bg-white dark:bg-gray-800 rounded-lg overflow-hidden mb-4">
                <img src={game?.icon} alt="Game Logo" className="w-full h-full md:w-1/4 object-cover" />
                <div className="p-4 flex-1">
                    <div className="flex items-center mb-4">
                        <div className="mr-2 px-2.5 py-0.5">
                            <Platform platform={game?.platform} size="lg" />
                        </div>
                        <Link to={game?.url} className="text-2xl underline font-semibold text-gray-900 dark:text-gray-100">{game?.title}</Link>
                    </div>
                    <Rating
                        linkToReviews={game?.url}
                        totalReviews={game?.reviewsCount}
                        rating={game?.score}
                    />
                    <h3 className="mt-4 mb-2 text-lg font-semibold text-gray-800 dark:text-gray-200">{game?.listing_subtitle}</h3>
                </div>

                {/* Conditional rendering of edit button */}
                {user?.role === "admin" && (
                    <div className="absolute top-0 right-0 mt-2 mr-2">
                        <Link to={Routes.Games.Edit.replace(":id", game?._id)} className="text-blue-600 hover:text-gray-700 dark:text-blue-400 dark:hover:text-gray-400">
                            <FaEdit size={30} />
                            Edit
                        </Link>
                    </div>
                )}
            </div>

        </>
    );
};

export default GameInfoCard;
