import { AuthenticationService, TokenService } from "../../services";

class TheRequestInterceptor {

    onRejected(error) {
        return Promise.reject(error);
    }

    async onFulfilled(config){
        /* this method checks whether the user is authenticated or not, and if so, it adds the access token to the request header */

        /* before sending the HTTP request, if the user is logged in, in other words, if there is a token,
           then add the token to the header of the HTTP request */
        if(AuthenticationService.isAuthenticated()){

            /* even tough the user is authenticated, his/her access token might have expired, so we need to check that too */
            /* and make sure that the request is not a refresh token request, because if it is, then we don't need to refresh the token */
            if(TokenService.isAccessTokenAboutToExpire() && !isTheRequestRefreshTokenRequest(config)){
                /* if the access token is too close to its expiration time, refresh the access token */
                await AuthenticationService.refreshAuthTokens();
            }

            config.headers.Authorization = `Bearer ${AuthenticationService.getAccessToken()}`;
        }

        return config;
    }
}

const isTheRequestRefreshTokenRequest = (config) => {
    /* this method checks whether a given HTTP request is a refresh token request or not */
    return config.url?.includes("refresh-tokens");
}

export default TheRequestInterceptor;