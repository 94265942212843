import { BlogBroker } from "../agents";

const fetchBlogs = async () => {
    const response = await BlogBroker.get("/");
    return response;
};

const publishBlog = async (blog) => {
    const response = await BlogBroker.post("/publish", blog);
    return response;
};

const deleteBlog = async (id) => {
    const response = await BlogBroker.delete(`/delete/${id}`);
    return response;
};

const fetchBlog = async (id) => {
    const response = await BlogBroker.get(`/${id}`);
    return response;
};

const updateBlog = async (id, blog) => {
    const response = await BlogBroker.put(`/update/${id}`, blog);
    return response;
};

const getBlogs = async () => {
    const response = await BlogBroker.get("/normal-blogs");
    return response;
};

const getExclusiveBlogs = async () => {
    const response = await BlogBroker.get("/exclusive-blogs");
    return response;
};

const getPackageDeals = async () => {
    const response = await BlogBroker.get("/package-deals");
    return response;
};


const BlogService = {
    fetchBlogs,
    deleteBlog,
    publishBlog,
    fetchBlog,
    updateBlog,
    getBlogs,
    getExclusiveBlogs,
    getPackageDeals
};

export default BlogService;