/* Contexts */
import { useGamePublishContext } from "../contexts";
/* Data */
import Messages from "./data/success_steps";
/* Styles */
import "./styles/success.css"

const Success = () => {
    /* this component renders when a game is successfully published */

    const { confirmationCodeState } = useGamePublishContext();
    const [ confirmationCode ] = confirmationCodeState;

    return (
        <div className="success_container text-success-700" role="alert">
            {/* Heading */}
            <h4 className="success_heading">{Messages.heading}</h4>
            {/* Subheading */}
            <p className="mb-4">
                {Messages.subheading}
            </p>
            {/* Confirmation Code */}
            <hr className="border-success-600 opacity-30"/>
            <h1 className="confirmation_code_heading">
                <span className="confirmation_code_style">{confirmationCode}</span></h1>
            {/* Message */}
            <p className="mt-6 mb-0">
                {Messages.message}
            </p>
            {/* Steps */}
            {
                Messages.steps.map((step, index) => (
                    <p key = {index+1} className="mt-4 mb-0">{`${index+1}. ${step}`}</p>
                ))
            }
        </div>
    );
}

export default Success;