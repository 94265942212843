import { useEffect, useState } from "react";
/* Steps Data */
import Steps from './steps';
/* Styles */
import "./publish.css"
/* Context */
import { UseContextProvider, useGamePublishContext } from './contexts/index';
/* Components */
import GameDetailsPreview from "./helpers/components/GameDetailsPreview";
/* Services */
import { GameService } from "../../../services";

const FIRST_STEP = 1;

const GamePublishInternal = () => {

    const [currentStep, setCurrentStep] = useState(FIRST_STEP);
    const [complete, setComplete] = useState(false);

    const { gameForPublish } = useGamePublishContext();
    const [ game, setGame ] = gameForPublish;

    const { gameLinkVerification } = useGamePublishContext();
    const [ isGameLinkVerified, setIsGameLinkVerified ] = gameLinkVerification;

    const { confirmationCodeState } = useGamePublishContext();
    const [ confirmationCode, setConfirmationCode ] = confirmationCodeState;

    const handlePublish = async (event) => {

        event.preventDefault(); /* prevent the default behavior of the form */

        try {
            const response = await GameService.publish(game);

            setConfirmationCode(response?.confirmationNumber);

            setCurrentStep( previous => previous + 1); /* advance to the next step */
        }
        catch (error) {

        }
    }

    useEffect(() => {

        if ( Steps.isFinalStep(currentStep) ) {
            setComplete(true);
        }

    }, [currentStep]);

    const handleAdvancement = (event) => {

        event.preventDefault(); /* prevent the default behavior of the form */

        setCurrentStep( previous => previous + 1); /* advance to the next step */

        if ( Steps.isFinalStep(currentStep) ) {
            setComplete(true);
        }

        switch (currentStep) {
            case Steps.SUCCESS.step:
                setComplete(true);
                break;
            case Steps.MONETIZATION_DETAILS.step:
                break;
            default:
                break;
        }
    };

    const handleDescend = (event) => {

        event.preventDefault(); /* prevent the default behavior of the form */

        switch (currentStep) {
            case Steps.SUCCESS.step:
                return;
        }

        if( Steps.isFirstStep(currentStep) ) return;
        else
            setCurrentStep( previous => previous - 1); /* descend to the previous step */
    };


    return <div className="flex ml-10 mr-10 mt-10 mb-20">
        <div className="w-full sm:w-3/5  rounded-xl shadow-xl bg-white">
            <div className="container mx-auto p-10 mt-4 mb-10">
                <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0">
                    {
                        Steps.all.map( (step, index) => {
                            return <li key = {index + 1}
                                       className={`step ${currentStep === step.step ? 'active' : 'inactive'} ${(complete || currentStep > step.step) && 'complete'} `}>
                                <span
                                    className={`mark ${currentStep === step.step ? 'active' : 'passive'} ${(complete || currentStep > step.step) && 'complete'} `}>
                                    { currentStep > step.step || complete ? '✓' : step.step}
                                </span>
                                <span>
                                    <h3 className="font-medium leading-tight">{step.title}</h3>
                                    <p className="text-sm">{step.description}</p>
                                </span>
                            </li>
                        })
                    }
                </ol>
                <div className="my-3 p-5 ">
                    {Steps.getComponentByStep(currentStep)}
                </div>
                <form>
                    <div className="flex justify-end space-x-3">
                        <button type="submit"
                                className="previous_step_button"
                                hidden={!isGameLinkVerified || Steps.isFirstStep(currentStep) || Steps.isFinalStep(currentStep) || complete  }
                                disabled={!isGameLinkVerified}
                                onClick={handleDescend}>
                            Previous Step
                        </button>
                        <button type="submit"
                                className="next_step_button"
                                hidden={!isGameLinkVerified || Steps.isFinalStep(currentStep) || (currentStep === Steps.MONETIZATION_DETAILS.step) || complete }
                                disabled={!isGameLinkVerified}
                                onClick={handleAdvancement}>
                            Next Step
                        </button>
                        <button type="submit"
                                className="publish_button"
                                hidden={!(currentStep === Steps.MONETIZATION_DETAILS.step)}
                                onClick={handlePublish}>
                            Publish
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div className="hidden sm:block w-2/5">
            <GameDetailsPreview />
        </div>
    </div>;
}

const GamePublish = () => {
    return <UseContextProvider>
        <GamePublishInternal/>
    </UseContextProvider>;
};

export default GamePublish;