import React from 'react';
import { Link } from "react-router-dom";
import Routes from "../../routes";
import { FaEdit } from "react-icons/fa";

const EditListingCard = ({ game }) => {
    return (
        <Link to={Routes.Games.Edit.replace(":id", game?._id)} className="flex items-center bg-white shadow-lg rounded-lg overflow-hidden w-full h-[120px] my-1 p-4 space-x-4">
            <img src={game?.icon} alt={game?.title} className="w-20 h-20 object-cover rounded-lg" />
            <div className="flex flex-col flex-grow text-xs w-full">
                <span className="text-black font-bold text-xs">
                    {game?.title}
                </span>
                <span className="text-gray-400 font-bold text-xs">
                    {game?.developer}
                </span>
            </div>

            <div>
                <span className="inline-flex overflow-hidden rounded-md border bg-white shadow-sm">
                    <Link to={Routes.Games.Edit.replace(":id", game?._id)}
                        className="inline-block border-e p-3 text-green-600 hover:bg-gray-50 focus:relative"
                        title="Edit Listing"
                    >
                        <FaEdit size={15}/>
                    </Link>
                </span>
            </div>

        </Link>
    );
};

export default EditListingCard;