import { Link } from "react-router-dom";
import { Badge } from "flowbite-react";

/* Routes */
import Routes from "../../../../routes";

/* Utilities */
import { formatPrice, formatTime } from "../../../../utilities";
import {Rating} from "../../../general";
import { FaEye } from "react-icons/fa";

const Price = ({ price }) => (
    <h3 className="text-indigo-700 text-xl font-semibold">{formatPrice(price?.value, price?.currency, true)}</h3>
);

const GamePreview = ({ game }) => {
    return (
        <>
            <Link
                to={Routes.Games.Details.replace(":id", game?._id)}
                className="relative flex flex-col flex-row items-center bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out"
            >
                {/* View count label with increased z-index */}
                <div className="absolute top-2 right-2 flex items-center bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-full p-1">
                    <FaEye size={25}/>
                    <span className="ml-1 text-sm">{game?.view_count}</span> {/* Replace 123 with your dynamic view count */}
                </div>

                {/* Ensure the image container is square */}
                <div className="flex-shrink-0 mt-8">
                    <img className="h-48 w-48 object-cover rounded-xl" src={game?.icon} alt={`Logo of ${game?.title}`} />
                    <div className="mt-8">
                        <Rating
                            linkToReviews={game?.url}
                            totalReviews={game?.reviewsCount}
                            rating={game?.score}
                        />
                    </div>
                </div>

                <div className="p-6 flex flex-col justify-between w-full">
                    <div>
                        <h3 className="font-semibold text-lg mb-2 text-blue-600 dark:text-blue-400">{game?.title}</h3>
                        <ul className="text-gray-600 dark:text-gray-300 text-sm">
                            <li className="mb-1">Price: <span className="font-medium text-blue-600 dark:text-blue-400">Contact Sales</span></li>
                            {/*<Price price={game?.price} />*/}
                            {/*<li className="mb-1">Monthly Profit: <span className="font-medium text-blue-600 dark:text-blue-400"><Price price={game?.monthly_profit} /></span></li>*/}
                            {/*<li className="mb-1">Total Profit: <span className="font-medium text-blue-600 dark:text-blue-400"><Price price={game?.total_profit} /></span></li>*/}
                            <li className="mb-1">Developer: <span className="font-medium text-blue-600 dark:text-blue-400">{game?.developer}</span></li>
                            <li className="mb-1">Seller: <span className="font-medium text-blue-600 dark:text-blue-400">Melih Bahri</span></li>
                            <li className="mb-1">Release Date: <span className="font-medium text-blue-600 dark:text-blue-400"><Badge>{formatTime(new Date(game?.releaseDate), false)}</Badge></span></li>
                        </ul>
                    </div>
                    <button className="mt-4 self-start bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition-colors duration-200">
                        More Info
                    </button>
                </div>
            </Link>
        </>

    );
}

export default GamePreview;