import React from 'react';

const Legal = () => {

    // Function to download the PDF
    const downloadPDF = () => {
        // Replace with your modified Google Drive link
        const pdfUrl = 'https://drive.google.com/uc?export=download&id=18nlNBKFYOrzN8CLVehmn1FLmx7wIXMdy';

        // Create an anchor element
        const link = document.createElement('a');
        link.href = pdfUrl;

        // Set the download attribute with a value of the desired file name
        link.setAttribute('download', 'file.pdf');

        // Append the anchor element to the body
        document.body.appendChild(link);

        // Programmatically click the anchor element to trigger the download
        link.click();

        // Remove the anchor element from the body
        document.body.removeChild(link);
    };

    return (
        <div>
            <section className="bg-black relative bg-cover bg-center bg-no-repeat h-[440px]">
                <div className="absolute inset-0 bg-black dark:bg-black sm:bg-black sm:from-white/95 sm:to-white/25 dark:from-gray-900/95 dark:to-gray-900/25"></div>

                <div className="relative mx-auto max-w-screen-xl px-4 py-4 sm:px-6 lg:flex lg:items-center lg:px-8">
                    <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right mt-12">
                        <h1 className="text-left text-3xl text-white font-extrabold sm:text-5xl">
                            Legal Services
                            <strong className="block font-extrabold text-white">And Templates</strong>
                        </h1>

                        <p className="mt-4 max-w-lg sm:text-xl/relaxed text-white text-left">
                            Engage a friendly, vetted, verified lawyer through our platform by purchasing a legal services
                            package or one of our asset-specific template document bundles.
                        </p>

                        {/* <div className="mt-8 flex flex-wrap gap-4 text-center">
                    <a
                        href="#"
                        className="block w-full rounded-full bg-blue-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-blue-700 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
                    >
                        View Options
                    </a>
                </div> */}
                    </div>
                </div>
            </section>

            <section>
                <div className="mx-auto max-w-screen-2xl px-4 py-8 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        <div className="rounded-2xl bg-blue-600 p-8 md:p-12 lg:px-16 lg:py-24">
                            <div className="mx-auto max-w-xl text-center">
                                <h2 className="text-left text-2xl font-bold text-white md:text-3xl">
                                    Are You Ready To Buy a Game Business?
                                </h2>

                                <p className="sm:text-lg/relaxed text-white text-left hidden sm:mt-4 sm:block">
                                    Selling or buying a business can be just as, if not more challenging,
                                    than building and running one! It can be tempting to get the deal done quickly and move forward.
                                    It is important however to seek proper advice and document the terms of sale clearly.
                                    This will ensure the sale is legally binding and that assets are identified accurately and transferred correctly.
                                    It also helps you to avoid and resolve disputes in future.
                                </p>

                                {/* <div className="mt-4 md:mt-8">
                            <a
                                href="#"
                                className="inline-block rounded-full border border-white bg-white px-12 py-3 text-sm font-medium text-blue-500 transition hover:bg-transparent hover:text-white focus:outline-none focus:ring focus:ring-yellow-400"
                            >
                                Get Started Today
                            </a>
                        </div> */}
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-4 md:grid-cols-1 lg:grid-cols-2">
                            <img
                                alt=""
                                src="https://images.unsplash.com/photo-1542744095-fcf48d80b0fd?q=80&w=3276&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                className="rounded-2xl h-40 w-full object-cover sm:h-56 md:h-full"
                            />
                            <img
                                alt=""
                                src="https://static.wixstatic.com/media/67f882_c43918bc57d541dfadf4f653d3dbc51c~mv2.jpg/v1/fill/w_420,h_434,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_0065_edited.jpg"
                                className="rounded-2xl h-40 w-full object-cover sm:h-56 md:h-full"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-white dark:bg-gray-900">
                <div className="container flex flex-col items-center px-4 py-12 mx-auto text-center">
                    <h2 className="max-w-2xl mx-auto text-2xl font-semibold tracking-tight text-gray-800 xl:text-3xl dark:text-white">
                        Legal Templates
                    </h2>

                    <p className="max-w-4xl mt-6 text-xl text-start text-gray-900 dark:text-gray-300">
                        Purchase and download in PDF form our template legal documents. We have templates for every stage of the sale process, from the letter of intent to the transfer of a specific asset or an entity.
                    </p>

                    <div>
                        <div className="w-full max-w-md px-8 py-4 mt-16 bg-white rounded-2xl shadow-lg dark:bg-gray-800">
                            <h2 className="text-start mt-2 text-2xl font-semibold text-gray-800 dark:text-white md:mt-0">
                                Asset Sale Template
                            </h2>

                            <p className="text-start mt-2 text-lg text-gray-900 dark:text-gray-300">
                                An asset sale involves the transfer of assets from one party to another.
                                Purchase a bundle of template documents specific to our asset type below.
                                Each bundle includes an asset purchase agreement and bill of sale.
                                A bill of sale documents the transfer of ownership.
                            </p>

                            <p className="font-bold text-start mt-2 text-gray-900 dark:text-gray-300">
                                Documents:
                            </p>

                            <p className="text-start text-gray-900 dark:text-gray-300">
                                1. Asset Purchase Agreement
                            </p>

                            <p className="text-start text-gray-900 dark:text-gray-300">
                                2. Bill of Sale
                            </p>

                            <div className="flex justify-end mt-4">
                                <button onClick={downloadPDF} className="inline-flex items-center justify-center w-full px-6 py-2 text-white duration-300 bg-blue-600 rounded-lg hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80" role="link">
                                    DOWNLOAD
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </div>

    );
};

export default Legal;
