import { formatPrice, formatTime } from "../../../../utilities";
import { MonetizationMethodBadge } from "../../common";

const GameDetailsCard = ({ game }) => {
    const price = formatPrice(game?.price?.value, game?.price?.currency, true);

    return (
        <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6 mb-6 shadow-sm dark:shadow-gray-600">
            <div className="grid grid-cols-2 md:grid-cols-2 gap-6">
                <div className="flex flex-col items-center md:items-start">
                    <div className="text-gray-600 dark:text-gray-400 text-lg">
                        Price
                    </div>
                    <div className="text-2xl font-bold text-blue-600 dark:text-blue-400">
                        Contact Sales
                    </div>
                </div>
                <div className="flex flex-col items-center md:items-start">
                    <div className="text-gray-600 dark:text-gray-400 text-lg">
                        App Age
                    </div>
                    <strong className="text-xl text-gray-800 dark:text-gray-200">
                        {formatTime(new Date(game?.releaseDate), false)}
                    </strong>
                </div>
                <div className="flex flex-col items-center md:items-start">
                    <div className="text-gray-600 dark:text-gray-400 text-lg">
                        Listing Date
                    </div>
                    <strong className="text-xl text-gray-800 dark:text-gray-200">
                        {formatTime(new Date(game?.createdAt))}
                    </strong>
                </div>
                <div className="flex flex-col items-center md:items-start">
                    <div className="text-gray-600 dark:text-gray-400 text-lg">
                        Monetization
                    </div>
                    <div className="flex flex-wrap justify-center md:justify-start">
                        {game?.monetization_methods?.map((method, index) => (
                            <MonetizationMethodBadge method={method} key={index} className="mt-2 mr-2" />
                        ))}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default GameDetailsCard;
