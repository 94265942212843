import { createContext, useContext, useState } from "react";

const GamePublishContext = createContext({});

export function UseContextProvider({ children }) {
    const [game, setGame] = useState({
        price: {
            value : undefined,
            currency: "USD"
        },
        monthly_profit: {
            value : undefined,
            currency: "USD"
        },
        total_profit: {
            value : undefined,
            currency: "USD"
        }
    });

    const [isGameLinkVerified, setIsGameLinkVerified] = useState(false);

    const [isGameLinkLoading, setIsGameLinkLoading] = useState(false);

    const [gameDetailsFromStore, setGameDetailsFromStore] = useState();

    const [confirmationCode, setConfirmationCode] = useState();

    const valuesToPublish = {
        gameForPublish: [game, setGame],
        gameLinkVerification: [isGameLinkVerified, setIsGameLinkVerified],
        gameLinkLoading: [isGameLinkLoading, setIsGameLinkLoading],
        gameDetailsFromStoreState: [gameDetailsFromStore, setGameDetailsFromStore],
        confirmationCodeState: [confirmationCode, setConfirmationCode]
    };

    return (
        <GamePublishContext.Provider value={ valuesToPublish }>
            {children}
        </GamePublishContext.Provider>
    );
}

export function useGamePublishContext() {

    const {
        gameForPublish,
        gameLinkVerification,
        gameLinkLoading,
        gameDetailsFromStoreState,
        confirmationCodeState
    } = useContext(GamePublishContext);

    return { gameForPublish, gameLinkVerification, gameLinkLoading, gameDetailsFromStoreState, confirmationCodeState };
}