import React from "react";
// import { Carousel } from "flowbite-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const DashboardContent = () => {
    return (
        <div className="">
            <div class="bg-white">
                <div class="mx-auto flex max-w-lg flex-col px-4 py-10 lg:max-w-screen-xl lg:flex-row">
                    <div class="max-w-lg pt-10 pr-10">
                        <h2 class="mb-7 text-3xl font-medium text-gray-700 sm:text-4xl">Acquisition Process</h2>
                        <p class="mb-10 text-lg leading-relaxed text-gray-600 sm:leading-loose">Ultimately, our goal is to deliver tangible results that drive value for your business. Whether it's expanding market share, accessing new technologies, or enhancing operational efficiency, we strive to achieve the strategic objectives that led to the acquisition in the first place. Our ongoing support and guidance ensure that you realize the full potential of the acquisition and position your business for continued growth and success.</p>
                        <p class="mb-6 text-lg leading-relaxed text-gray-600 sm:leading-loose">Before finalizing any acquisition, we conduct comprehensive due diligence. This involves meticulously examining all aspects of the potential purchase, including financial records, market positioning, intellectual property, and legal obligations. Our aim is to ensure transparency, identify potential risks, and uncover opportunities that align with your strategic goals.</p>
                        <a href="#" class="border-b-4 border-blue-600 pb-2 text-lg font-bold text-blue-600">Contact Sales</a>
                    </div>
                    <div class="-order-1 lg:order-1">
                        <div class="mb-8">
                            <Carousel>
                                <div>
                                    <img className="shadow-blue-500/10 mb-7 h-full w-full rounded-xl object-contain shadow-lg" src="https://i.postimg.cc/7L4g9qSM/Screenshot-2024-05-02-at-19-49-58.png" alt="" />
                                    <p className="legend">Acquisition Details</p>
                                </div>
                                <div>
                                    <img className="shadow-blue-500/10 mb-7 h-full w-full rounded-xl object-contain shadow-lg" src="https://i.postimg.cc/gkwqkSYR/Screenshot-2024-05-02-at-19-49-11.png" alt="" />
                                    <p className="legend">Letter Of Intent</p>
                                </div>
                                <div>
                                    <img className="shadow-blue-500/10 mb-7 h-full w-full rounded-xl object-contain shadow-lg" src="https://i.postimg.cc/qvys2N6v/Screenshot-2024-05-02-at-19-49-18.png" alt="" />
                                    <p className="legend">Legal Contract</p>
                                </div>
                                <div>
                                    <img className="shadow-blue-500/10 mb-7 h-full w-full rounded-xl object-contain shadow-lg" src="https://i.postimg.cc/8PqdFR5Y/Screenshot-2024-05-02-at-19-49-37.png" alt="" />
                                    <p className="legend">Game Transfer</p>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardContent;
