import { Spinner } from "flowbite-react";

const LoadingSpinner = () => {
    return (
        <div className="flex flex-col gap-2">
            <div className="text-center">
                <Spinner aria-label="loading-component" size="lg" />
            </div>
        </div>
    );
}

export default LoadingSpinner;