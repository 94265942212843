import decode from 'jwt-decode';
import { TokenErrorMessages } from '../messages/error';
import isEmpty from "lodash.isempty";
/* Configurations */
import configs from "../configurations";

const setAccessToken = (accessToken) => localStorage.setItem('access_token', accessToken);

const getAccessToken = () => localStorage.getItem('access_token');

const setRefreshToken = (refreshToken) => localStorage.setItem('refresh_token', refreshToken);

const setRefreshTokenExpirationTime = (expirationTime) => localStorage.setItem('refresh_token_expiration_time', expirationTime);

const getRefreshTokenExpirationTime = () => {
    /* this method returns the expiration time of the refresh token */
    const expirationTime = localStorage.getItem('refresh_token_expiration_time');
    return new Date(expirationTime);
}

const setAccessTokenExpirationTime = (expirationTime) => localStorage.setItem('access_token_expiration_time', expirationTime);

const getAccessTokenExpirationTime = () => {
    /* this method returns the expiration time of the access token */
    const expirationTime = localStorage.getItem('access_token_expiration_time');
    return new Date(expirationTime);
}

const getRefreshToken = () => localStorage.getItem('refresh_token');

const isTokenValid = (token) => {
    /* this method computes whether a given token is valid or not */

    /* first, check if there is a token or not */
    if (isEmpty(token)) return false;

    /* second try to decode the token, if not, then the token is not valid */
    try {
        decode(token);

    } catch (error) {
        return false; /* if the token is not decoded, then it is not valid */
    }

    /* if the token is decoded, then check if it is expired or not */
    return !isTokenExpired(token);
}

const isTokenExpired = (token) => {
    /* this method computes whether the token is expired or not, based on the expiration time */
    try {

        const decoded = decode(token);

        if (decoded.exp < Date.now() / 1000) return true; /* the token is expired */
        else return false; /* the token is not expired, valid as rock */

    } catch (error) {
        throw new Error(TokenErrorMessages.TOKEN_NOT_IN_VALID_FORMAT);
    }
}

const isAccessTokenAboutToExpire = () => {
    /* this method computes whether the access token is about to expire or not */

    /* first, check whether the access token is valid or not */

    const accessToken = getAccessToken();
    const isValid = isTokenValid(accessToken);

    if(isValid === false){
        // if token is not valid, indicate that access token is about to expire
        // this scenario also check whether the token has expired or not,
        // if the token has already expired, isValid return false
        return true;
    }
    else {
        // the token might be valid but close to its expiration time
        const tokenExpirationTime = getAccessTokenExpirationTime();
        const interval = configs.getConfig("ACCESS_TOKEN_EXPIRATION_THRESHOLD_IN_MS");
        const currentTime = new Date();

        // if the difference between the current time and the expiration time
        // is less than two minutes, then the access token is about to expire,
        // therefore it must be refreshed
        if( tokenExpirationTime - currentTime < interval) return true; /* indicating that the token is too close to its expiration time */
        else return false;

    }
}

const setAuthTokens = ( { accessToken, accessTokenExpirationTime, refreshToken, refreshTokenExpirationTime } ) => {

    if(!isEmpty(accessToken)) setAccessToken(accessToken)
    else throw new Error(TokenErrorMessages.ACCESS_TOKEN_NOT_PROVIDED);

    if(!isEmpty(accessTokenExpirationTime)) setAccessTokenExpirationTime(accessTokenExpirationTime);

    if(!isEmpty(refreshToken)) setRefreshToken(refreshToken)
    else throw new Error(TokenErrorMessages.REFRESH_TOKEN_NOT_PROVIDED);

    if(!isEmpty(refreshTokenExpirationTime)) setRefreshTokenExpirationTime(refreshTokenExpirationTime);
}

const removeAuthTokens = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expiration_time');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expiration_time');
}

const TokenService = {
    setAccessToken,
    setAccessTokenExpirationTime,
    getAccessToken,
    setRefreshToken,
    setRefreshTokenExpirationTime,
    getRefreshToken,
    setAuthTokens,
    isTokenValid,
    isAccessTokenAboutToExpire,
    removeAuthTokens
}

export default TokenService;