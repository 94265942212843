import TokenService from "./token.service";
import { AuthBroker } from "../agents";

const getAccessToken = () => {
    return TokenService.getAccessToken();
}

const getRefreshToken = ()=> {
    return TokenService.getRefreshToken();
}

const isAuthenticated = () => {
    /* this method computes whether the user is authenticated, i.e. logged in,  or not */
    const refreshToken = getRefreshToken();
    return TokenService.isTokenValid(refreshToken);
}

const refreshAuthTokens = async () => {
    /* this method is used to refresh both access and refresh token */

    /* first, check whether the user is authenticated or not */
    if(!isAuthenticated()) throw new Error("User is not authenticated, so we cannot refresh the tokens");

    const response  = await AuthBroker.post("/refresh-tokens", {
        refreshToken: getRefreshToken()
    });

    const { token: accessToken, expires: accessTokenExpirationTime } = response.access;
    const { token: refreshToken, expires: refreshTokenExpirationTime } = response.refresh;

    TokenService.setAuthTokens({
        accessToken,
        accessTokenExpirationTime,
        refreshToken,
        refreshTokenExpirationTime
    });

}

const login = async (email, password) => {
    /* this function logs in a user given a valid email and password */

    const response = await AuthBroker.post("/login", {
        email,
        password
    });

    const { token: accessToken, expires: accessTokenExpirationTime } = response.tokens.access;
    const { token: refreshToken, expires: refreshTokenExpirationTime } = response.tokens.refresh;

    TokenService.setAuthTokens({
        accessToken,
        accessTokenExpirationTime,
        refreshToken,
        refreshTokenExpirationTime
    });

    localStorage.setItem('user', JSON.stringify(response.user));

    return response;
}

const register = async (name, email, password) => {
    /* this function registers a new user */
        
    const configs = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    const response = await AuthBroker.post("/register",
        { name, email, password },
        configs
    );

    const { token: accessToken, expires: accessTokenExpirationTime } = response?.tokens?.access;
    const { token: refreshToken, expires: refreshTokenExpirationTime } = response?.tokens?.refresh;

    TokenService.setAuthTokens({
        accessToken,
        accessTokenExpirationTime,
        refreshToken,
        refreshTokenExpirationTime
    });

    localStorage.setItem('user', JSON.stringify(response?.user));

    return response;
}

const getUser = () => {
    return JSON.parse(localStorage.getItem('user'));
}

const logout = async () => {

    /* this function logs out a user by sending a logout request to the backend by removing the refresh token from the database */
    const configs = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    await AuthBroker.post(
        "/logout",
        { refreshToken: getRefreshToken() },
            configs);

    localStorage.removeItem('user');
    TokenService.removeAuthTokens();
}

const AuthenticationService = {
    isAuthenticated,
    getAccessToken,
    getRefreshToken,
    refreshAuthTokens,
    login,
    register,
    getUser,
    logout
};

export default AuthenticationService;