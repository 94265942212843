// src/components/game/details/GameStatsDetails.js
import React from 'react';
import { formatPrice, formatTime } from "../../../utilities";
import { FaDollarSign, FaDownload, FaCalendarAlt, FaMoneyBillWave, FaTags, FaUser, FaStar } from 'react-icons/fa';
import { GiClockwork } from 'react-icons/gi';

// Add formatNumber if it's available in your utilities module, otherwise define it locally
const formatNumber = (number) => {
    if (number < 1000) {
        return number.toString();
    }
    const thousands = number / 1000;
    const formatted = thousands >= 10 ? Math.round(thousands) : thousands.toFixed(1);
    return `${formatted}K`;
};

const GameStatsDetails = ({ game }) => {
    if (!game) return null;

    const monthlyProfit = formatPrice(game?.monthly_profit?.value, game?.monthly_profit?.currency, false);
    const totalProfit = formatPrice(game?.total_profit?.value, game?.total_profit?.currency, false);

    return (
        <div className="flow-root rounded-lg border border-gray-200 dark:border-gray-700 py-3 shadow-sm dark:bg-gray-800">
            <dl className="-my-3 divide-y divide-gray-200 dark:divide-gray-700 text-sm">
                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-100 dark:even:bg-gray-900 sm:grid-cols-3 sm:gap-4">
                    <dt className="flex items-center font-medium text-gray-900 dark:text-gray-300">
                        <FaDollarSign className="mr-2" /> Monthly Profit
                    </dt>
                    <dd className="text-gray-900 dark:text-gray-400 sm:col-span-2">{monthlyProfit}</dd>
                </div>

                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-100 dark:even:bg-gray-900 sm:grid-cols-3 sm:gap-4">
                    <dt className="flex items-center font-medium text-gray-900 dark:text-gray-300">
                        <FaMoneyBillWave className="mr-2" /> Total Profit
                    </dt>
                    <dd className="text-gray-900 dark:text-gray-400 sm:col-span-2">{totalProfit}</dd>
                </div>

                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-100 dark:even:bg-gray-900 sm:grid-cols-3 sm:gap-4">
                    <dt className="flex items-center font-medium text-gray-900 dark:text-gray-300">
                        <FaDownload className="mr-2" /> Total Downloads
                    </dt>
                    <dd className="text-gray-900 dark:text-gray-400 sm:col-span-2">{formatNumber(game?.total_downloads)}</dd>
                </div>

                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-100 dark:even:bg-gray-900 sm:grid-cols-3 sm:gap-4">
                    <dt className="flex items-center font-medium text-gray-900 dark:text-gray-300">
                        <FaCalendarAlt className="mr-2" /> Monthly Downloads
                    </dt>
                    <dd className="text-gray-900 dark:text-gray-400 sm:col-span-2">{formatNumber(game?.monthly_downloads)}</dd>
                </div>

                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-100 dark:even:bg-gray-900 sm:grid-cols-3 sm:gap-4">
                    <dt className="flex items-center font-medium text-gray-900 dark:text-gray-300">
                        <GiClockwork className="mr-2" /> Game Age
                    </dt>
                    <dd className="text-gray-900 dark:text-gray-400 sm:col-span-2">{formatTime(new Date(game?.releaseDate), false)}</dd>
                </div>

                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-100 dark:even:bg-gray-900 sm:grid-cols-3 sm:gap-4">
                    <dt className="flex items-center font-medium text-gray-900 dark:text-gray-300">
                        <FaTags className="mr-2" /> Monetization Methods
                    </dt>
                    <div className="flex flex-col sm:col-span-2">
                        {game?.monetization_methods?.map((method, index) => (
                            <dd key={index} className="text-gray-900 dark:text-gray-400">{method}</dd>
                        ))}
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-100 dark:even:bg-gray-900 sm:grid-cols-3 sm:gap-4">
                    <dt className="flex items-center font-medium text-gray-900 dark:text-gray-300">
                        <FaUser className="mr-2" /> Developer
                    </dt>
                    <dd className="text-gray-900 dark:text-gray-400 sm:col-span-2">{game.developer}</dd>
                </div>

                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-100 dark:even:bg-gray-900 sm:grid-cols-3 sm:gap-4">
                    <dt className="flex items-center font-medium text-gray-900 dark:text-gray-300">
                        <FaStar className="mr-2" /> Reviews Count
                    </dt>
                    <dd className="text-gray-900 dark:text-gray-400 sm:col-span-2">{game.reviewsCount}</dd>
                </div>

                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-100 dark:even:bg-gray-900 sm:grid-cols-3 sm:gap-4">
                    <dt className="flex items-center font-medium text-gray-900 dark:text-gray-300">
                        <FaStar className="mr-2" /> Rating
                    </dt>
                    <dd className="text-gray-900 dark:text-gray-400 sm:col-span-2">{game.score.toFixed(1)}</dd>
                </div>
            </dl>
        </div>


    );
};

export default GameStatsDetails;
