import React, {useState} from 'react';
import OfferService from "../../services/offer.service";

const OfferApprovedSection = ({ currentOffer, openModal, refreshAsContract }) => {

    const [fullName, setFullName] = useState('');
    const [legalAddress, setLegalAddress] = useState('');

    const handleUpdateFullNameAndLegalAddress = async () => {
        try {
            const response = await OfferService.handleUpdateFullNameAndLegalAddress(currentOffer?._id, { fullName, legalAddress });
            console.log(response);
            // refreshAsContract();
            // openModal();
            // handleUpdateStatus('LegalContract');
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpdateStatus = async (status) => {
        try {
            const response = await OfferService.handleUpdateStatus(currentOffer?._id, status);
            refreshAsContract();
            openModal();
            handleUpdateFullNameAndLegalAddress();
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="App max-w-4xl mx-auto p-4">
            <div className="container mx-auto py-8">

                <div className="mb-8 flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                    <div className="flex items-center justify-center w-12 bg-emerald-500">
                        <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
                        </svg>
                    </div>

                    <div className="px-4 py-2 -mx-3">
                        <div className="mx-3">
                            <span className="font-semibold text-emerald-500 dark:text-emerald-400">LOI Approved by Broker</span>
                            <p class="text-sm text-gray-600 dark:text-gray-200">Your Acquisition on Next Step!</p>
                        </div>
                    </div>
                </div>

                <h1 className="text-2xl mb-6">Letter of Intent Approved by Broker</h1>

                <p className="text-xl font-semibold mb-6">
                    Congratulations! Your offer has been approved by our broker.
                    Now, you will receive a legal contract, prepared by Scapcher Legal Department.
                </p>

                <div className="mt-4">
                    <label htmlFor="username" className="block text-sm text-gray-500 dark:text-gray-300">Full Name</label>

                    <input
                        type="text"
                        value={fullName}
                        placeholder={currentOffer?.fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        className="block  mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
                </div>

                <div className="mt-4">
                    <label htmlFor="address" className="block text-sm text-gray-500 dark:text-gray-300">Legal Address</label>

                    <input
                        type="text"
                        value={legalAddress}
                        placeholder={currentOffer?.legalAddress}
                        onChange={(e) => setLegalAddress(e.target.value)}
                        className="block  mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
                </div>

                <button
                    onClick={() => handleUpdateStatus('LegalContract')}
                    className="mt-4 px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform rounded-3xl bg-emerald-500 hover:bg-emerald-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                    Proceed to Legal Contract
                </button>

                <div className="mt-8 cursor-not-allowed">
                    <h2>Legal Contract Example</h2>
                    <iframe
                        className="cursor-not-allowed w-full h-screen"
                        title="Google Document Preview"
                        src={`https://docs.google.com/document/d/${"1HAGUO1yLZYjlxmrrFsnQyJi3doWzAuGIEIhhh-wHSnE"}/preview`}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>

                <p className="mt-8">
                    Contract includes all purchase terms & conditions about the acquisition
                </p>

            </div>
        </div>
    );
};

export default OfferApprovedSection;
