import { Badge } from "flowbite-react";
/* Enums */
import { Platforms } from "../../../enums";

const Platform = ( { platform, size, rounded } ) => {

    /* decide on the platform color first */
    /* use blue for iOS and green for Android */
    const platformColor = platform === Platforms.IOS ? "info" : "success";

    /* platform name is rendered differently than it is stored on database */
    const platformName = platform === Platforms.IOS ? "iOS" : "Android";


    return (
        <Badge
            className={`${rounded} && "rounded-full" pl-3 pr-3`}
            color={`${platformColor}`}
            size={size}>
            {platformName}
        </Badge>
    );
};

/* default props */
Platform.defaultProps = {
    size: "sm",
    rounded: true
};

export default Platform;