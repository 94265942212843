import React from 'react';
import { FaCheckCircle } from "react-icons/fa";
import { FaWindowClose } from "react-icons/fa";

const ApproveCard = ({ game, openPopup }) => {

    const handleApprove = () => {
        openPopup("Game Gonna be Approved?", "Are You Sure To Change as Approved", game?._id, "approve");
    }

    const handleReject = () => {
        openPopup("Game Gonna be Rejected?", "Are You Sure To Change as Rejected", game?._id, "reject");
    }

    return (
        <div className="flex items-center bg-white shadow-lg rounded-lg overflow-hidden w-full h-[120px] my-1 p-4 space-x-4">
            <img src={game?.icon} alt={game?.name} className="w-20 h-20 object-cover rounded-lg" />
            <div className="flex flex-col flex-grow text-xs w-full">
                <span className="text-black font-bold text-xs">
                    {game?.title}
                </span>
                <span className="text-gray-400 font-bold text-xs">
                    {game?.developer}
                </span>
            </div>
            <div>
                <span className="inline-flex overflow-hidden rounded-md border bg-white shadow-sm">
                    <button
                        className="inline-block border-e p-3 text-green-600 hover:bg-gray-50 focus:relative"
                        title="Approve Product"
                        onClick={handleApprove}
                    >
                        <FaCheckCircle size={15}/>
                    </button>
                    <button
                        className="inline-block p-3 text-red-600 hover:bg-gray-50 focus:relative"
                        title="Reject Product"
                        onClick={handleReject}
                    >
                        <FaWindowClose size={15}/>
                    </button>
                </span>
            </div>
        </div>
    );
};

export default ApproveCard;