import { AuthenticationConstants } from "../constants";

const { LOGIN, REGISTER, LOGOUT } = AuthenticationConstants;

const userLoginReducer = ( state = {}, action ) => {
    switch(action.type) {
        case LOGIN.REQUEST:
            return { loading: true };
        case LOGIN.SUCCESS:
            return { loading: false, userData: action.payload };
        case LOGIN.FAILURE:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

const userRegisterReducer = ( state = {}, action ) => {
    switch(action.type) {
        case REGISTER.REQUEST:
            return { loading: true };
        case REGISTER.SUCCESS:
            return { loading: false, userData: action.payload };
        case REGISTER.FAILURE:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

const userLogoutReducer = ( state = {}, action ) => {
    switch(action.type) {
        case LOGOUT.REQUEST:
            return { loading: true };
        case LOGOUT.SUCCESS:
            return { loading: false };
        case LOGOUT.FAILURE:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

const AuthenticationReducers = {
    userLoginReducer,
    userRegisterReducer,
    userLogoutReducer
};

export default AuthenticationReducers;