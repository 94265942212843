/* Import Configurations */
import applicationConfigs from './application.config'
import authenticationConfigs from "./authentication.config";

const allConfigurations = [applicationConfigs, authenticationConfigs];


class ConfigurationInjector {
    /* this class collects all the configurations and injects them into the application */

    #configurations = {}; /* a private property that holds all the configurations */

    #loadConfigurationFiles(){
        /* this method will read all the configuration files and store them in the configurations object */

        /* for each configuration file, store the configurations in the configurations object */
        allConfigurations.forEach(config => {
            Object.keys(config).forEach(key => {
                this.#configurations[key] = config[key];
            });
        });
    }

    constructor() {
        /* read all the configuration files store them in an object */
        this.#loadConfigurationFiles();
    }

    getConfig(key) {
        /* when a key is provided, this method finds the necessary configuration in all the different configuration files */
        return this.#configurations[key];
    }
}

export default new ConfigurationInjector();