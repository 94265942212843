import { Rating as FlowBiteRating } from "flowbite-react";

/*
    Definitions of the Props

    linkToReviews: the link to the reviews of the game in App/Play Store
    rating: the rating of the game, ranging between 0-5
    totalReviews: the number of reviews
 */

const Rating = ( { rating, totalReviews, linkToReviews } ) => {

    const stars = [];

    for( let iterator = 1; iterator <= 5; iterator++ ) {
        stars.push(
            <FlowBiteRating.Star filled = {iterator < rating} />
        );
    }

    return (
        <FlowBiteRating>
            {stars}
            <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                {rating.toFixed(1)}
            </p>
            <span className="mx-1.5 h-1 w-1 rounded-full bg-gray-500 dark:bg-gray-400" />
            <a href = {linkToReviews}
                className = "text-sm font-medium text-gray-900 underline hover:no-underline">
                {totalReviews} reviews
            </a>
        </FlowBiteRating>
    );
}

export default Rating;