// WhatsAppButton.js
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = ({ phoneNumber }) => {
    const handleWhatsAppClick = () => {
        const message = encodeURIComponent("YourMessage");
        window.open(`https://wa.me/${phoneNumber}?text=${message}`, "_blank");
    };

    return (
        <button onClick={handleWhatsAppClick}
                className="mt-2 bg-emerald-600 flex gap-x-3 text-sm sm:text-base items-center justify-center text-white rounded-full hover:bg-emerald-500 duration-300 transition-colors border border-transparent px-8 py-3">

            <FaWhatsapp className="items-center justify-center w-10 h-10" />

            <span>Contact via WhatsApp</span>
        </button>
    );
};

export default WhatsAppButton;
