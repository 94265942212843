import React, { useState, useEffect } from 'react';
import {BlogService} from "../../services";
import PackageDealCard from "./../components/PackageDealCard";

const PackageDeals = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await BlogService.getPackageDeals(); // Replace with actual endpoint
                setBlogs(response);
                setLoading(false);
                console.log(response);
                // setGameContent(response.games);
            } catch (error) {
                console.error('Error fetching blogs:', error);
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <div>
            <div className="App max-w-4xl mx-auto p-4">
                <div className="container mx-auto py-8">
                    <h1 className="text-3xl font-semibold mb-6 dark:text-white">Special Offers and Exclusive Deals</h1>
                    <p className="mt-2 text-xl font-normal dark:text-white text-gray-600">Only for exclusive members</p>

                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        blogs.map((blog) => (
                            <div key={blog._id}>
                                <PackageDealCard blog={blog}/>
                            </div>
                        ))
                    )}

                </div>
            </div>
        </div>
    );
};

export default PackageDeals;
