import React from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';

function DateDifferenceComponent({ fetchedDate }) {
    // Provide a fallback date if fetchedDate is undefined
    const dateToUse = fetchedDate || new Date().toISOString();

    let dateDifference;
    try {
        // Calculate the difference using the formatDistanceToNow function
        dateDifference = formatDistanceToNow(new Date(dateToUse), { addSuffix: true });
    } catch (error) {
        // Handle any potential parsing errors
        dateDifference = 'Invalid date';
    }

    return (
        <div>
            <p className="text-xs text-gray-500 dark:text-gray-500 text-right">{dateDifference}</p>
        </div>
    );
}

export default DateDifferenceComponent;
