import React, { useEffect, useState } from "react";
import {
    FaCheckCircle,
    FaTag
} from "react-icons/fa";
import {IoEye, IoFlag, IoPerson} from "react-icons/io5";
import {FaFileInvoiceDollar} from "react-icons/fa6";
import DateDifferenceComponent from "./DateDifferenceComponent";

const FomoAlert = () => {
    const [fomo, setFomo] = useState();
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const fetchFomoAlert = async () => {
            try {
                const response = await fetch("https://my-fomo-service-4641d5d07326.herokuapp.com/fomo-alerts/fomo-flow");
                const data = await response.json();
                setFomo(data);
            } catch (error) {
                console.error("Failed to fetch FOMO alerts", error);
            }
        };

        const interval = setInterval(fetchFomoAlert, 2000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const animationInterval = setInterval(() => {
            setAnimate(true);
            setTimeout(() => {
                setAnimate(false);
            }, 1000); // Disable animation after 1 second
        }, 3000); // Enable animation every 3 seconds

        return () => clearInterval(animationInterval);
    }, []);



    const renderTitleWithHighlight = () => {
        if (fomo?.highlight?.text) {
            const { text } = fomo.highlight;
            let textColorClass = '';

            switch (fomo.type) {
                case 'auth':
                    textColorClass = 'text-blue-700 font-bold';
                    break;
                case 'view':
                    textColorClass = 'text-pink-700 font-bold';
                    break;
                case 'sale':
                    textColorClass = 'text-green-700 font-bold';
                    break;
                case 'discount':
                    textColorClass = 'text-purple-700 font-bold';
                    break;
                case 'milestone':
                    textColorClass = 'text-orange-700 font-bold';
                    break;
                default:
                    textColorClass = 'text-gray-700 font-bold';
                    break;
            }

            const highlightClass = textColorClass;

            const titleParts = fomo.title.split(text);

            return (
                <span>
                    {titleParts[0]}
                    <span className={highlightClass}>{text}</span>
                    {titleParts.slice(1).join('')}
                </span>
            );
        }
        return fomo?.title;
    };

    const renderIcon = (type) => {
        switch (type) {
            case 'auth':
                return <span className={`w-6 h-6 bg-blue-200 rounded-full flex items-center justify-center mr-2`}>
                    <IoPerson  className={`h-3 w-3 text-blue-700`}  />
                </span>;
            case 'view':
                return <span className={`w-6 h-6 bg-pink-200 rounded-full flex items-center justify-center mr-2`}>
                    <IoEye className={`h-3 w-3 text-pink-700`} />
                </span>;
            case 'discount':
                return <span className={`w-6 h-6 bg-purple-200 rounded-full flex items-center justify-center mr-2`}>
                    <FaTag className={`h-3 w-3 text-purple-700`}  />
                </span>;
            case 'milestone':
                return <span className={`w-6 h-6 bg-orange-200 rounded-full flex items-center justify-center mr-2`}>
                    <IoFlag className={`h-3 w-3 text-orange-700`}  />
                </span>;
            case 'sale':
                return <span className={`w-6 h-6 bg-green-200 rounded-full flex items-center justify-center mr-2`}>
                    <FaFileInvoiceDollar className={`h-3 w-3 text-green-700`} />
                </span>;
            default: return <span className={`w-6 h-6 bg-blue-200 rounded-full flex items-center justify-center mr-2`}>
                    <IoPerson  className={`h-3 w-3 text-blue-700`}  />
                </span>;
        }
    };

    return (
        <div className="fixed bottom-4 left-4 z-50 flex">
            <ul className="relative">
                <li
                    className={`mt-8 relative min-w-[350px] max-w-[420px] min-h-[150px] max-h-[200px] transform rounded-lg bg-white p-4 shadow-lg outline outline-2 outline-gray-200 transition-all duration-300 hover:scale-105 hover:shadow-xl ${animate ? 'animate-fade-up' : ''}`}>
                    <div>
                        {/* Second Div */}
                        <div className="flex items-center text-xs">
                            {renderIcon(fomo?.type)}

                            <p className="text-xs text-gray-700 dark:text-gray-700">{fomo?.type}</p>
                        </div>

                        {/* Third Div */}
                        <div className="mt-3">
                            <p className="left-2 w-4/6 text-sm">
                                {renderTitleWithHighlight()}
                            </p>
                        </div>

                    </div>

                    {/* Fourth Div */}
                    <div className="bottom-4 left-4 absolute flex items-center text-xs">
                        <FaCheckCircle className="h-4 w-4 text-blue-500" />
                        <p className="ml-1 text-xs text-gray-700 dark:text-gray-700">
                            Verified by <span className="text-xs font-bold">Nudgify</span>
                        </p>
                    </div>

                    {/* First Div: Text positioned top-right */}
                    <div className="absolute right-3 top-3 ml-32 pl-32 text-xs">
                        <DateDifferenceComponent fetchedDate={fomo?.time} />
                    </div>

                    <div className="absolute bottom-4 right-4 ml-32 text-xs text-gray-500">
                        <img
                            src={fomo?.image}
                            alt="group"
                            className="inline-block rounded-xl"
                            width={75}
                            height={75}
                        />
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default FomoAlert;
