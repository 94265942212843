import React, {useEffect, useState} from 'react';
import { FaCheckCircle } from "react-icons/fa";
import { FaWindowClose } from "react-icons/fa";
import Moment from "moment";


const UserCard = ({ user, openPopup }) => {
    const [newUser, setNewUser] = useState();

    useEffect(() => {
        setNewUser(user);
    });

    const makeAdmin = () => {
        openPopup("User Role Gonna Change?", "Are You Sure To Change User Role", user?.id, "admin");
    }

    const makeUser = () => {
        openPopup("User Role Gonna Change?", "Are You Sure To Change User Role", user?.id, "user");
    }

    return (
        <div className="flex items-center bg-white shadow-lg rounded-lg overflow-hidden w-full h-[120px] my-1 p-4 space-x-4">
            <img src={user?.profilePicture} alt={user?.name} className="w-20 h-20 object-cover rounded-lg" />
            <div className="flex flex-col flex-grow text-xs w-full">
                <span className="text-black font-bold text-xs">
                    {user?.name}
                </span>
                <span className="text-black font-bold text-xs">
                    {user?.email}
                </span>
                <span className="text-gray-400 font-bold text-xs">
                    Email Verified: {user?.isEmailVerified.toString()}
                </span>
                <span className="text-gray-400 font-bold text-xs">
                    Created At: {Moment(user?.createdAt).format('LL')}
                </span>
                <span className="text-gray-400 font-bold text-xs">
                    Role: {user?.role}
                </span>
            </div>
            <div>
                <span className="inline-flex overflow-hidden rounded-md border bg-white shadow-sm">
                    <button
                        className="inline-block border-e p-3 text-green-600 hover:bg-gray-50 focus:relative"
                        title="Approve Product"
                        onClick={makeAdmin}
                    >
                        <FaCheckCircle size={15}/>
                    </button>
                    <button
                        className="inline-block p-3 text-red-600 hover:bg-gray-50 focus:relative"
                        title="Reject Product"
                        onClick={makeUser}
                    >
                        <FaWindowClose size={15}/>
                    </button>
                </span>
            </div>
        </div>
    );
};

export default UserCard;