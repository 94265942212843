/* Components */
import {
    GameDetails,
    GameLink,
    MonetizationDetails,
    Success
} from "./steps/index";


const stepsList = [
    {
        title: 'Store Link',
        description: 'Link to the Game',
        index: 0,
        step: 1,
        component: <GameLink/>,
    },
    {
        title: 'Game Details',
        description: 'Describe Your Game',
        index: 1,
        step: 2,
        component: <GameDetails/>,
    },
    {
        title: 'Monetization Details',
        description: 'Monetization Methods, Total Profit, etc.',
        index: 2,
        step: 3,
        component: <MonetizationDetails/>,
    },
    {
        title: 'Success',
        description: 'Get Your Confirmation Code',
        index: 3,
        step: 4,
        component: <Success/>,
    }
];

class Steps {

    constructor(stepsList) {
        this.GAME_LINK = stepsList[0];
        this.GAME_DETAILS = stepsList[1];
        this.MONETIZATION_DETAILS = stepsList[2];
        this.SUCCESS = stepsList[3];
        this.all = stepsList;
    }

    isFinalStep(stepIndex) {
        /* this method is used to check if the current step is the final step */
        /* do not forget that step index starts from 1 */
        return stepIndex === this.all?.length;
    }

    isFirstStep(stepIndex) {
        /* this method is used to check if the current step is the first step */
        /* do not forget that step index starts from 1 */
        return stepIndex === 1;
    }

    getComponentByStep(currentStep) {
        /* this method is used to get the component to be rendered for the provided step */

        /* check if the provided argument current step is a valid argument */
        if (currentStep < 1 || currentStep > this.all?.length) {
            return;
        }

        return this.all[currentStep - 1]?.component;
    }

}

export default new Steps(stepsList);