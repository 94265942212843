import React from 'react';

const OfferCompletedSection = ({ openModal }) => {
    return (
        <div className="App max-w-4xl mx-auto p-4">
            <div className="container mx-auto py-8">

                <div className="flex mb-8 w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                    <div className="flex items-center justify-center w-12 bg-emerald-500">
                        <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z" />
                        </svg>
                    </div>

                    <div className="px-4 py-2 -mx-3">
                        <div className="mx-3">
                            <span className="font-semibold text-emerald-500 dark:text-emerald-400">Purchase Completed</span>
                            <p className="text-sm text-gray-600 dark:text-gray-200">Successful Acquisition</p>
                        </div>
                    </div>
                </div>

                <h1 className="text-2xl mb-6">Acquisition Completed</h1>

                <p className="text-xl font-semibold mb-6">Scapcher has approved all terms and conditions, and the acquisition is now marked as completed.</p>

                <div className="mt-8">
                    <label className="block text-sm text-gray-500 dark:text-gray-300">LOI</label>

                    <input disabled type="text" placeholder="LOI URL" className="block bg-gray-50 cursor-not-allowed mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
                </div>

                <div className="mt-8">
                    <label className="block text-sm text-gray-500 dark:text-gray-300">Legal Contract</label>

                    <input disabled type="text" placeholder="Contract URL" className="block bg-gray-50 cursor-not-allowed mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
                </div>

                <div className="mt-8">
                    <label className="block text-sm text-gray-500 dark:text-gray-300">Payment Method</label>

                    <input disabled type="text" placeholder="Payment Method" className="block bg-gray-50 cursor-not-allowed mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
                </div>

                <div className="mt-8">
                    <label className="block text-sm text-gray-500 dark:text-gray-300">Payment Bill</label>

                    <input disabled type="text" placeholder="Payment Bill URL" className="block bg-gray-50 cursor-not-allowed mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
                </div>

                <div className="mt-8">
                    <label className="block text-sm text-gray-500 dark:text-gray-300">Source Code</label>

                    <input disabled type="text" placeholder="Source Code URL" className="block bg-gray-50 cursor-not-allowed mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
                </div>

            </div>
        </div>
    );
};

export default OfferCompletedSection;
