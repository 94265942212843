import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {BlogService} from "../../services";
import SteamService from "../../services/steam.service";

const SteamDetails = () => {
    const { id } = useParams(); // Assuming you're using React Router
    const [game, setGame] = useState(null);

    useEffect(() => {
        const fetchGame = async () => {
            try {
                const response = await SteamService.fetchSteamGame(id);
                setGame(response);
            } catch (error) {
                console.error('Error fetching game details', error);
            }
        };

        fetchGame();
    }, [id]);

    if (!game) {
        return <div>Loading...</div>;
    }

    return (
        <div className="mt-8 max-w-4xl mx-auto p-8 bg-white shadow-md rounded-lg">
            <div className="flex items-center mb-6">
                <img src={game.icon} alt={`${game.title} Icon`} className="w-16 h-16 mr-4" />
                <div>
                    <h1 className="text-3xl font-bold">{game.title}</h1>
                    <h2 className="text-xl text-gray-600">{game.subtitle}</h2>
                </div>
            </div>
            <div className="mb-6">
                <p className="text-gray-700">{game.description}</p>
            </div>
            <div className="mb-6">
                <a href={game.url} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                    {game.url}
                </a>
            </div>
            <div className="mb-6">
                <h3 className="text-xl font-bold mb-2">Screenshots</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {game.screenshots.map((screenshot, index) => (
                        <img key={index} src={screenshot} alt={`Screenshot ${index + 1}`} className="w-full h-auto rounded-lg shadow-md" />
                    ))}
                </div>
            </div>
            <div className="mb-6">
                <h3 className="text-xl font-bold mb-2">Video</h3>
                {game.video ? (
                    <div></div>
                ) : (
                    <p className="text-gray-500">No video available</p>
                )}
            </div>
            <div className="mb-6">
                <h3 className="text-xl font-bold mb-2">Developer</h3>
                <p className="text-gray-700">{game.developer}</p>
            </div>
            <div className="mb-6">
                <h3 className="text-xl font-bold mb-2">Submitted By</h3>
                <p className="text-gray-700">Melih Bahri AKTAN</p>
            </div>
        </div>
    );
};

export default SteamDetails;
