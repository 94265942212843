import React, { useEffect, useState } from 'react';
import { getAnalytics } from "firebase/analytics";

/* Helper Components */
import {GiLetterBomb} from "react-icons/gi";
import {FaArrowLeft} from "react-icons/fa6";
import PopupDefault from "../../components/popups/PopupDefault";
import LoadingGrid from "../../components/general/Loading/LoadingGrid";
import OfferService from "../../services/offer.service";
import LOICard from "../mobile-components/LOICard";
import TableOfferAdminCard from "../table-components/TableOfferAdminCard";


const LettersOfIntent = () => {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const analytics = getAnalytics();

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertDesc, setAlertDesc] = useState('');

    const [selectedOffer, setSelectedOffer] = useState();
    const [currentState, setCurrentState] = useState();

    const [firstDivOpen, setFirstDivOpen] = useState(true);
    const [secondDivOpen, setSecondDivOpen] = useState(false);

    const [currentOffer, setCurrentOffer] = useState();
    const [user, setUser] = useState();

    useEffect(() => {
        fetchOffers();
    }, []);

    const fetchOffers = async () => {
        try {
            const pendingOffers = await OfferService.getOffers();
            setOffers(pendingOffers);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    const fetchOffersAndReloadPage = () => {
        setIsAlertOpen(false);
        fetchOffers();
    };

    const deleteOffer = async () => {
        try {
            const response = await OfferService.deleteOffer(selectedOffer);
            console.log(response);
            fetchOffersAndReloadPage();
        }
        catch (error) {
            console.log(error);
        }
    }

    const onOpenPopup = (title, desc, id, state) => {
        setAlertTitle(title);
        setAlertDesc(desc);
        setSelectedOffer(id);
        setCurrentState(state);

        setIsAlertOpen(true);
    };

    const popupApprove = () =>
    {
        if(currentState === "delete")
            deleteOffer();
    }

    const openOffer = (offer) => {
        setCurrentOffer(offer);
        setFirstDivOpen(false);
        setSecondDivOpen(true);
        setCurrentState(offer?.status);
    };

    const backToOffers = () => {
        // setCurrentOffer(null);
        setFirstDivOpen(true);
        setSecondDivOpen(false);
    };

    const [offerId, setOfferId] = useState('');
    const [fullName, setFullName] = useState('');
    const [legalAddress, setLegalAddress] = useState('');
    const [appleId, setAppleId] = useState('');
    const [teamId, setTeamId] = useState('');
    const [contractLink, setContractLink] = useState('');

    const handleUpdateFullNameAndLegalAddress = async () => {
        try {
            const response = await OfferService.handleUpdateFullNameAndLegalAddress(currentOffer?._id, { fullName, legalAddress });
            console.log(response);
            setPopupTitle("Successful Update Full Name And Legal Address");
            setPopupDesc("You can continue to edit");
            openModal();
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpdateAppleIdAndTeamId = async () => {
        try {
            const response = await OfferService.handleUpdateAppleIdAndTeamId(currentOffer?._id, { appleId, teamId });
            console.log(response);
            setPopupTitle("Successful Update Apple Id And Team Id");
            setPopupDesc("You can continue to edit");
            openModal();
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpdateContractLink = async () => {
        try {
            const response = await OfferService.handleUpdateContractLink(currentOffer?._id, { contractLink });
            console.log(response);
            setPopupTitle("Successful Update Contract Link");
            setPopupDesc("You can continue to edit");
            openModal();
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpdateStatus = async (status) => {
        try {
            const response = await OfferService.handleUpdateStatus(currentOffer?._id, status);
            console.log(response);
            setPopupTitle("Successful Update Status");
            setPopupDesc("You can continue to edit");
            openModal();
        } catch (error) {
            console.log(error);
        }
    };

    // Modal state variables
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');
    const [popupDesc, setPopupDesc] = useState('');

    // Function to handle opening the modal
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Function to handle closing the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const MobileComponent = () => {
        return (
            <div className="md:hidden"> {/* This will hide the component on medium and larger screens */}
                <div className="container mx-auto mt-2">
                    <h1 className="text-xl font-bold text-black mb-4">Pending Offers</h1>
                    {loading ? (
                        <div className="flex items-center justify-center">
                            <LoadingGrid />
                        </div>
                    ) : (
                        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
                            {offers.length > 0 ? (
                                offers.map((offer, index) => (
                                    <LOICard
                                        offer={offer}
                                    />
                                ))
                            ) : (
                                <p className="text-gray-600">No pending offers found.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const DesktopComponent = () => {
        return (
            <div>
                {firstDivOpen && (
                    <div className="hidden md:block"> {/* This will hide the component on small screens */}
                        <div>
                            <section className="container px-4 mx-auto">
                                <div className="flex items-center gap-x-3 mt-8">
                                    <h2 className="text-lg font-medium text-gray-800 dark:text-white">Offers</h2>

                                    <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-gray-800 dark:text-blue-400">{offers?.length} offers</span>
                                </div>

                                <div className="flex flex-col mt-6">
                                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                                            <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">

                                                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">

                                                    <thead className="bg-gray-50 dark:bg-gray-800">
                                                    <tr>
                                                        <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                            <div className="flex items-center gap-x-3">
                                                                <input type="checkbox" className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"/>
                                                                <span>User</span>
                                                            </div>
                                                        </th>

                                                        <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                            <button className="flex items-center gap-x-2">
                                                                <span>Game</span>

                                                                <svg className="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                    <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                    <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                </svg>
                                                            </button>
                                                        </th>

                                                        <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                            <button className="flex items-center gap-x-2">
                                                                <span>Offer</span>

                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="w-4 h-4">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                                                </svg>
                                                            </button>
                                                        </th>

                                                        <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">Created At</th>

                                                        <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">Contact</th>

                                                        <th scope="col" className="relative py-3.5 px-4">
                                                            <span className="sr-only">Edit</span>
                                                        </th>
                                                    </tr>

                                                    </thead>

                                                    <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">

                                                    {
                                                        loading ? (
                                                            <div className="flex items-center justify-center col-span-3 mt-4">
                                                                <LoadingGrid />
                                                            </div>
                                                        ) : (
                                                            offers && offers?.length > 0 && offers?.map(offer => (
                                                                <TableOfferAdminCard
                                                                    key={offer._id}
                                                                    offer={offer}
                                                                    func={ () => openOffer(offer)}
                                                                    openPopup={onOpenPopup} />
                                                            ))
                                                        )
                                                    }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between mt-6">
                                    <a href="#" className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                                        </svg>

                                        <span>
                                    previous
                                </span>
                                    </a>

                                    <div className="items-center hidden lg:flex gap-x-3">
                                        <a href="#" className="px-2 py-1 text-sm text-blue-500 rounded-md dark:bg-gray-800 bg-blue-100/60">1</a>
                                        <a href="#" className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100">2</a>
                                        <a href="#" className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100">3</a>
                                        <a href="#" className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100">...</a>
                                        <a href="#" className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100">12</a>
                                        <a href="#" className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100">13</a>
                                        <a href="#" className="px-2 py-1 text-sm text-gray-500 rounded-md dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100">14</a>
                                    </div>

                                    <a href="#" className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800">
                                <span>
                                    Next
                                </span>

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                        </svg>
                                    </a>
                                </div>
                            </section>
                        </div>
                    </div>
                )}

                {secondDivOpen && (

                    <div>

                        {/* Modal or Pop-up */}
                        {isModalOpen && (
                            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm z-10">

                                <PopupDefault isOpen={isModalOpen} onClose={() => closeModal(false)} title={popupTitle} desc={popupDesc} icon={<GiLetterBomb size={30}/>}>

                                </PopupDefault>
                            </div>
                        )}

                        <div className="col-span-8 overflow-hidden rounded-xl sm:bg-gray-50 sm:px-8 sm:shadow">

                            <div className="pt-4">
                                {/* BACK TO  Acquisitions */}
                                <button onClick={backToOffers}  className="mb-8 flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                                    <FaArrowLeft size={25}/>
                                    <span className="mx-1">Back To Offers</span>
                                </button>
                            </div>

                            <hr className="mt-4 mb-8" />

                            <div>
                                <div>
                                    <h1 className="py-2 text-2xl font-semibold">Offer Details & Offer Update</h1>
                                    <h1 className="text-2xl font-semibold">Game Name: {currentOffer?.title}</h1>
                                </div>

                                <hr className="mt-4 mb-8" />

                                <h1 className="text-xl">Offer Introduction</h1>

                                <div className="w-2/6 mb-8">
                                    <div className="mt-6">
                                        <label htmlFor="offer" className=" text-sm text-gray-500 dark:text-gray-300">Offer Value</label>

                                        <input disabled type="text" placeholder={"$" + currentOffer?.offer} className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none" />
                                    </div>
                                </div>

                                <div className="w-2/6 mb-8">
                                    <div className="mt-6">
                                        <label htmlFor="email" className=" text-sm text-gray-500 dark:text-gray-300">Client Email Address</label>

                                        <input disabled type="text" placeholder={currentOffer?.email} className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none" />
                                    </div>
                                </div>

                                <div className="w-2/6 mb-8">
                                    <div className="mt-6">
                                        <label htmlFor="title" className=" text-sm text-gray-500 dark:text-gray-300">Game</label>

                                        <input disabled type="text" placeholder={currentOffer?.title} className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none" />
                                    </div>
                                </div>

                                <div className="w-2/6 mb-8">
                                    <div className="mt-6">
                                        <label htmlFor="title" className=" text-sm text-gray-500 dark:text-gray-300">Offer ID:</label>

                                        <input
                                            disabled type="text"
                                            placeholder={currentOffer?._id}
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                                            onChange={(e) => setOfferId(e.target.value)} />
                                    </div>
                                </div>

                                <hr className="mt-4 mb-8" />

                                <h1 className="text-xl">Client's Legal Information</h1>

                                <div className="w-2/6 mb-8">
                                    <div className="mt-6">
                                        <label htmlFor="title" className=" text-sm text-gray-500 dark:text-gray-300">Full Name:</label>

                                        <input
                                            type="text"
                                            value={fullName}
                                            placeholder={currentOffer?.fullName}
                                            onChange={(e) => setFullName(e.target.value)}
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none" />
                                    </div>
                                </div>

                                <div className="w-2/6 mb-8">
                                    <div className="mt-6">
                                        <label htmlFor="title" className=" text-sm text-gray-500 dark:text-gray-300">Legal Address:</label>

                                        <input
                                            type="text"
                                            value={legalAddress}
                                            placeholder={currentOffer?.legalAddress}
                                            onChange={(e) => setLegalAddress(e.target.value)}
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"/>
                                    </div>
                                </div>

                                <button
                                    onClick={handleUpdateFullNameAndLegalAddress}
                                    className="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                                    Update Full Name And Legal Address
                                </button>

                                <hr className="mt-4 mb-8" />

                                <h1 className="text-xl">Client's App Store Transfer Information</h1>

                                <div className="w-2/6 mb-8">
                                    <div className="mt-6">
                                        <label htmlFor="title" className=" text-sm text-gray-500 dark:text-gray-300">Apple ID:</label>

                                        <input
                                            type="text"
                                            value={appleId}
                                            placeholder={currentOffer?.appleId}
                                            onChange={(e) => setAppleId(e.target.value)}
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none" />
                                    </div>
                                </div>

                                <div className="w-2/6 mb-8">
                                    <div className="mt-6">
                                        <label htmlFor="title" className=" text-sm text-gray-500 dark:text-gray-300">Team ID:</label>

                                        <input
                                            type="text"
                                            value={teamId}
                                            placeholder={currentOffer?.teamId}
                                            onChange={(e) => setTeamId(e.target.value)}
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none" />
                                    </div>
                                </div>

                                <button
                                    onClick={handleUpdateAppleIdAndTeamId}
                                    className="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                                    Update Apple ID and Team ID
                                </button>

                                <hr className="mt-4 mb-8" />

                                <h1 className="text-xl">Contract</h1>

                                <div className="w-2/6 mb-8">
                                    <div className="mt-6">
                                        <label htmlFor="title" className=" text-sm text-gray-500 dark:text-gray-300">Contract Url:</label>

                                        <input
                                            type="text"
                                            value={contractLink}
                                            placeholder={currentOffer?.contractLink}
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                                            onChange={(e) => setContractLink(e.target.value)} />
                                    </div>
                                </div>

                                <button
                                    onClick={handleUpdateContractLink}
                                    className="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                                    Update Contract Link
                                </button>

                                <hr className="mt-4 mb-8" />

                                <h1 className="text-xl">Offer Status</h1>

                                <div className="grid grid-cols-3 gap-2 w-2/4 mt-8 mb-8">

                                    <button
                                        onClick={() => handleUpdateStatus('LOISent')}
                                        className="px-6 py-4 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                                        Update Status to LOI Sent
                                    </button>

                                    <button
                                        onClick={() => handleUpdateStatus('LOIApproved')}
                                        className="px-6 py-4 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                                        Update Status to LOI Approved
                                    </button>

                                    <button
                                        onClick={() => handleUpdateStatus('LegalContract')}
                                        className="px-6 py-4 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                                        Update Status to Legal Contract
                                    </button>

                                    <button
                                        onClick={() => handleUpdateStatus('Payment')}
                                        className="px-6 py-4 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                                        Update Status to Payment
                                    </button>

                                    <button
                                        onClick={() => handleUpdateStatus('GameTransfer')}
                                        className="px-6 py-4 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                                        Update Status to Game Transfer
                                    </button>

                                    <button
                                        onClick={() => handleUpdateStatus('AcquisitionCompleted')}
                                        className="px-6 py-4 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                                        Update Status to Acquisition Completed
                                    </button>
                                </div>

                                <hr className="mt-4 mb-8" />

                            </div>
                        </div>

                    </div>

                )}

            </div>
        );
    };

    return (
        <div className="App">
            <MobileComponent />
            <DesktopComponent />

            {/* Overlay for blurry background */}
            {isAlertOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm z-10"></div>
            )}
            <PopupDefault
                isOpen={isAlertOpen}
                onClose={() => setIsAlertOpen(false)}
                title={alertTitle}
                desc={alertDesc}
                icon={<GiLetterBomb size={30}/>}
                onApproveButton={popupApprove} />

        </div>
    );
}

export default LettersOfIntent;
