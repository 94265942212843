import React, {Component, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Routes from "../../routes";

import { MdAccountCircle } from "react-icons/md";
import { MdPendingActions } from "react-icons/md";
import { MdEditDocument } from "react-icons/md";
import { MdMail, MdSupervisorAccount } from "react-icons/md";

const DashboardSidebar = () => {
    const [selectedButton, setSelectedButton] = useState(null);

    useEffect(() => {
        setSelectedButton("ApprovedGames");
    }, []);

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };

        return (
            <div className="w-16 flex-col justify-between border-e bg-white">
                <div className="flex h-screen w-16 flex-col justify-between border-e bg-white">
                    <div>

                        <button onClick={() => handleButtonClick('Account')} href={Routes.Admin.Account} className="inline-flex size-16 items-center justify-center">
                            <span className="grid size-10 place-content-center rounded-lg bg-blue-400 text-xs text-white">
                                MB
                            </span>
                        </button>

                        <div className="border-t border-gray-400">

                            <div className="px-2">

                                {/* Account - Button */}
                                <Link to={Routes.Admin.Account}>
                                    <button
                                        onClick={() => handleButtonClick('Account')}
                                        className="group relative flex justify-center rounded px-2 py-1.5 hover:bg-gray-50"
                                    >
                                        {selectedButton === 'Account' ? <MdAccountCircle size={30} style={{ color: 'blue' }}/> : <MdAccountCircle size={30} style={{ color: 'gray' }}/> }

                                        <span
                                            className="invisible absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white group-hover:visible"
                                        >
                                            Account
                                        </span>
                                    </button>
                                </Link>

                                <ul className="space-y-1 border-t border-gray-100 pt-4">

                                    {/* Pending Games - Button */}
                                    <Link to={Routes.Admin.PendingGames} className="py-4">
                                        <button
                                            onClick={() => handleButtonClick('PendingGames')}
                                            className="t group relative flex justify-center rounded px-2 py-1.5"
                                        >
                                            {selectedButton === 'PendingGames' ? <MdPendingActions size={30} style={{ color: 'blue' }}/> : <MdPendingActions size={30} style={{ color: 'gray' }}/> }

                                            <span
                                                className="invisible absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white group-hover:visible"
                                            >
                                                Pending Games
                                            </span>
                                        </button>
                                    </Link>

                                    {/* Pending Games - Button */}
                                    <Link to={Routes.Admin.RejectedGames} className="py-4">
                                        <button
                                            onClick={() => handleButtonClick('RejectedGames')}
                                            className="t group relative flex justify-center rounded px-2 py-1.5"
                                        >
                                            {selectedButton === 'RejectedGames' ? <MdPendingActions size={30} style={{ color: 'blue' }}/> : <MdPendingActions size={30} style={{ color: 'gray' }}/> }

                                            <span
                                                className="invisible absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white group-hover:visible"
                                            >
                                                Rejected Games
                                            </span>
                                        </button>
                                    </Link>

                                    {/* Listings - Button */}
                                    <Link to={Routes.Admin.ApprovedGames}>
                                        <button
                                            onClick={() => handleButtonClick('ApprovedGames')}
                                            className="group relative flex justify-center rounded px-2 py-1.5 hover:bg-gray-50"
                                        >
                                            {selectedButton === 'ApprovedGames' ? <MdEditDocument size={30} style={{ color: 'blue' }}/> : <MdEditDocument size={30} style={{ color: 'gray' }}/>}

                                            <span
                                                className="invisible absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white group-hover:visible"
                                            >
                                            Listings
                                        </span>
                                        </button>
                                    </Link>

                                    {/* Users - Button*/}
                                    <Link to={Routes.Admin.Users}>
                                        <button
                                            onClick={() => handleButtonClick('Users')}
                                            className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50"
                                        >
                                            {selectedButton === 'Users' ? <MdSupervisorAccount size={30} style={{ color: 'blue' }}/> : <MdSupervisorAccount size={30} style={{ color: 'gray' }}/>}

                                            <span
                                                className="invisible absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white group-hover:visible"
                                            >
                                            Users
                                        </span>
                                        </button>
                                    </Link>

                                    {/* LOI - Button*/}
                                    <Link to={Routes.Admin.LOI}>
                                        <button
                                            onClick={() => handleButtonClick('LOI')}
                                            className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50"
                                        >
                                            {selectedButton === 'LOI' ? <MdMail size={30} style={{ color: 'blue' }}/> : <MdMail size={30} style={{ color: 'gray' }}/>}

                                            <span
                                                className="invisible absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white group-hover:visible"
                                            >
                                            LOI
                                        </span>
                                        </button>
                                    </Link>

                                    {/* Steam Listings - Button */}
                                    <Link to={Routes.Admin.SteamGames}>
                                        <button
                                            onClick={() => handleButtonClick('SteamGames')}
                                            className="group relative flex justify-center rounded px-2 py-1.5 hover:bg-gray-50"
                                        >
                                            {selectedButton === 'SteamGames' ? <MdEditDocument size={30} style={{ color: 'blue' }}/> : <MdEditDocument size={30} style={{ color: 'gray' }}/>}

                                            <span
                                                className="invisible absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white group-hover:visible"
                                            >
                                            Steam Games
                                        </span>
                                        </button>
                                    </Link>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2">
                        <form action="#">
                            <button
                                type="submit"
                                className="group relative flex w-full justify-center rounded-lg px-2 py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="size-5 opacity-75 h-[30px] w-[30px]"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                    />
                                </svg>

                                <span
                                    className="invisible absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white group-hover:visible"
                                >
                                Logout
                            </span>
                            </button>
                        </form>
                    </div>

                </div>
            </div>
        );
};

export default DashboardSidebar;