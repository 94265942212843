import React from "react";
import YouTubePlayer from "../YouTubePlayer";

const GameMedia = ({ game }) => {
    return (
        <div className="mb-8 flex overflow-x-auto space-x-8 h-[572px] mt-6 rounded-xl">
            {game?.video_url && (
                <YouTubePlayer url={game?.video_url} className="flex-shrink-0 w-[320px]" />
            )}
            {game.screenshots.map((screenshot, index) => (
                <img
                    key={index}
                    src={screenshot}
                    alt={`Screenshot ${index}`}
                    className="flex-shrink-0 w-[320px] h-[572px] object-cover rounded-xl"
                />
            ))}
        </div>
    );
};

export default GameMedia;
