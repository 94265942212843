import React from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import { FaFileSignature, FaHandshake, FaTasks, FaChartLine } from 'react-icons/fa';
import DashboardContent from "../../components/landing/DashboardContent";

const SaleProcess = () => {
    // Step data with icons
    const steps = [
        { title: "Due Diligence", icon: <FaFileSignature size={45} />, content: "Before finalizing any acquisition, we conduct comprehensive due diligence. This involves meticulously examining all aspects of the potential purchase, including financial records, market positioning, intellectual property, and legal obligations. Our aim is to ensure transparency, identify potential risks, and uncover opportunities that align with your strategic goals." },
        { title: "Deal Terms", icon: <FaHandshake size={45} />, content: "Once due diligence is complete, we engage in negotiations to define the terms of the deal. This includes determining the purchase price, payment structure, warranties, and any contingencies. Our team works closely with you to ensure that the agreed-upon terms are fair, favorable, and conducive to a successful transaction." },
        { title: "Execute Acquisition", icon: <BiCheckCircle size={45} />, content: "With the deal terms finalized, we proceed to execute the acquisition agreement. Our legal and financial experts draft a comprehensive contract that reflects the agreed-upon terms and complies with all regulatory requirements. We guide you through every step of the process, from obtaining necessary approvals to facilitating the transfer of ownership." },
        { title: "Post-Acquisition", icon: <FaTasks size={45} />, content: "Following the acquisition, our focus shifts to integration and post-acquisition management. We develop a detailed integration plan aimed at seamlessly merging your business with the acquired entity. This involves aligning systems, processes, and cultures to maximize synergies and ensure a smooth transition. Our team remains committed to supporting you through the post-acquisition phase, addressing any challenges that may arise and optimizing performance for long-term success." },
        { title: "Results", icon: <FaChartLine size={45} />, content: "Ultimately, our goal is to deliver tangible results that drive value for your business. Whether it's expanding market share, accessing new technologies, or enhancing operational efficiency, we strive to achieve the strategic objectives that led to the acquisition in the first place. Our ongoing support and guidance ensure that you realize the full potential of the acquisition and position your business for continued growth and success." },
    ];

    return (
        <div>
            <DashboardContent/>

            <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-4xl text-blue-600">
                    <h2 className="mt-6 text-center text-3xl font-bold text-gray-900 sm:text-4xl">Sale Process Description</h2>
                </div>
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-4xl grid grid-cols-2 gap-6">
                    {steps.map((step, index) => (
                        <Step key={index} title={step.title} icon={step.icon} content={step.content} stepNumber={index + 1} />
                    ))}
                </div>
            </div>
        </div>
    );
}

const Step = ({ title, icon, content, stepNumber }) => {
    return (
        <div className="border border-blue-500 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/10 bg-white overflow-hidden sm:rounded-3xl relative">
            <div className="p-6">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <span className="text-black">{icon}</span>
                    </div>
                    <div className="ml-4 text-lg font-medium">
                        <h3 className="text-lg font-medium text-black">{title}</h3>
                    </div>
                </div>
                <div className="mt-2 text-lg text-gray-600">{content}</div>
            </div>
        </div>
    );
}

export default SaleProcess;
