import {configureStore, combineReducers, applyMiddleware} from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

/* Import Reducers */
import { AuthenticationReducers } from "../reducers";
/* Helpers */
import initialUserData from "./helpers/initial_user_data";


let reducers = combineReducers({
    userLogin: AuthenticationReducers.userLoginReducer,
    userRegister: AuthenticationReducers.userRegisterReducer,
    userLogout: AuthenticationReducers.userLogoutReducer
});

/* initial state of the application is stored here */
let initialState = {
    userLogin: initialUserData
};

const middleware = [thunk]

const store = configureStore({reducer: reducers}, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
