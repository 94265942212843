import { GameBroker } from "../agents";

const fetchFeaturedGames = async () => {
    const response = await GameBroker.get("/");
    return response;
};

const fetchPendingGames = async () => {
    const response = await GameBroker.get("/pending");
    return response;
};

const fetchRejectedGames = async () => {
    const response = await GameBroker.get("/rejected");
    return response;
};

const fetchSimpleGames = async () => {
    const response = await GameBroker.get("/simple-games");
    return response;
};

const fetchPremiumGames = async () => {
    const response = await GameBroker.get("/premium-games");
    return response;
};

const fetchExclusiveGames = async () => {
    const response = await GameBroker.get("/exclusive-games");
    return response;
};

const fetchAppStoreGames = async () => {
    const response = await GameBroker.get("/app-store");
    return response;
};

const fetchPlayStoreGames = async () => {
    const response = await GameBroker.get("/play-store");
    return response;
};

const fetchIdleGames = async () => {
    const response = await GameBroker.get("/idle-games");
    return response;
};

const fetchCasualGames = async () => {
    const response = await GameBroker.get("/casual-games");
    return response;
};

const fetchGame = async (id) => {
    const response = await GameBroker.get(`/${id}`);
    return response;
};

const publish = async (game) => {
    const response = await GameBroker.post("/", game);
    return response;
};

const fetchDetailsFromStore = async (url) => {
    /* this service is used to fetch the details of a game from the Play Store or App Store based on the game's link */

    const response = await GameBroker.get("/fetch/from-store", { url: url });

    return response;
}

const approveGame = async (id) => {
    const response = await GameBroker.put(`/${id}/approve`);
    return response;
};

const rejectGame = async (id) => {
    const response = await GameBroker.put(`/${id}/reject`);
    return response;
};

const gameViewed = async (id) => {
    const response = await GameBroker.put(`/${id}/viewed`);
    return response;
};

const updateListing = async (id, newGame) => {
    const response = await GameBroker.put(`/${id}/update`, newGame);
    console.log(newGame);
    console.log(response);
    return response;
};

const getGenre = async (id) => {
    const response = await GameBroker.get(`/games/${id}`);
    return response;
};

const setAllGameTypesToNormal = async (id) => {
    const response = await GameBroker.put(`/games/update-all-game-types`);
    return response;
};

const GameService = {
    fetchFeaturedGames,
    fetchGame,
    approveGame,
    rejectGame,
    publish,
    fetchDetailsFromStore,
    fetchPendingGames,
    fetchRejectedGames,
    gameViewed,
    updateListing,
    fetchSimpleGames,
    fetchPremiumGames,
    fetchExclusiveGames,
    getGenre,
    fetchAppStoreGames,
    fetchPlayStoreGames,
    fetchIdleGames,
    fetchCasualGames,
    setAllGameTypesToNormal
};

export default GameService;