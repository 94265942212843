

function parseDeveloper(game) {
    /* this function parses out the developer data from the given game */

    /* first get the developer data from the game */
    if(!game?.submittedBy) throw new Error("Developer data must be provided.");

    const developerData = game?.submittedBy;

    let developer = {};

    developer.profilePicture = developerData?.profilePicture;
    developer.email = developerData?.email;
    developer.name = developerData?.name;
    developer.id = developerData?._id;
    developer.phoneNumber = developerData?.phoneNumber;
    developer.submittedBy = game?.submittedBy.id;

    return developer;
}

export default parseDeveloper;