import Routes from "../../../../routes";
import { Link } from "react-router-dom";
import React from "react";
import { BsCheckCircleFill } from "react-icons/bs"; // Importing the verification icon

const SellerCard = ({ developer }) => (
    <div>
        <div className="mx-auto mt-6 flex max-w-xs flex-col items-center rounded-xl border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 px-4 py-4 text-center md:max-w-full md:flex-row md:items-start md:text-left">
            <div className="mb-4 md:mr-6 md:mb-0">
                <img className="rounded-lg object-cover" src="https://media.licdn.com/dms/image/v2/D4D03AQGGC52SPxNeGg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1720267752208?e=1729728000&v=beta&t=ehgiPG7OumBhgFfYNSLAf3nPCODxrcpnAtK9WPheI4o" alt="" />
            </div>
            <Link to={Routes.Authentication.Profile.replace(":id", developer?.submittedBy)} className="">
                <div className="flex items-center">
                    <p className="text-xl font-medium text-gray-700 dark:text-gray-100">{developer?.name}</p>
                    <BsCheckCircleFill className="ml-2 text-blue-500" size={15} /> {/* Adding the verification icon next to the name */}
                </div>
                <p className="mb-4 text-sm font-medium text-gray-500 dark:text-gray-400">Game Broker and M&A Advisor</p>
                <div className="flex space-x-2">
                    <div className="flex flex-col items-center rounded-xl bg-gray-100 dark:bg-gray-700 px-4 py-2">
                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Listings</p>
                        <p className="text-3xl font-medium text-gray-600 dark:text-gray-100">300</p>
                    </div>
                    <div className="flex flex-col items-center rounded-xl bg-gray-100 dark:bg-gray-700 px-4 py-2">
                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Transactions</p>
                        <p className="text-3xl font-medium text-gray-600 dark:text-gray-100">65</p>
                    </div>
                    <div className="flex flex-col items-center rounded-xl bg-gray-100 dark:bg-gray-700 px-4 py-2">
                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Audience</p>
                        <p className="text-3xl font-medium text-gray-600 dark:text-gray-100">1.5k</p>
                    </div>
                </div>
                <div className="mb-3"></div>
                <div className="flex space-x-2">
                    <button className="w-full rounded-lg border-2 border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 px-4 py-2 font-medium text-gray-500 dark:text-gray-300">Message</button>
                    <button className="w-full rounded-lg border-2 border-transparent bg-blue-600 dark:bg-blue-500 px-4 py-2 font-medium text-white">Follow</button>
                </div>
            </Link>
        </div>

    </div>
);

export default SellerCard;
