import React, { useState, useEffect } from 'react';
import {BlogService} from "../../services";
import {Link} from "react-router-dom";
import Routes from "../../routes";

const ExclusiveBlogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await BlogService.getExclusiveBlogs(); // Replace with actual endpoint
                setBlogs(response);
                setLoading(false);
                console.log(response);
                // setGameContent(response.games);
            } catch (error) {
                console.error('Error fetching blogs:', error);
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <section className="py-10 mt-16">
            <h1 className="mb-12 text-center font-sans text-5xl font-bold">Exclusive Blogs</h1>
            <p className="mt-2 text-center text-xl font-normal text-gray-600">Only for exclusive members</p>
            <div className="mx-auto grid max-w-screen-lg justify-center px-4 sm:grid-cols-2 sm:gap-4 sm:px-8 md:grid-cols-3">

                {loading ? (
                    <div>Loading...</div>
                ) : (
                    blogs.map((blog) => (
                        <div key={blog._id}>
                            <article className="mx-auto my-4 flex w-full flex-col overflow-hidden rounded-2xl border border-gray-300 bg-white text-gray-900 transition hover:translate-y-2 hover:shadow-lg">
                                <Link to={Routes.Blog.BlogDetails.replace(":id", blog?._id)}>
                                    <img src={blog?.blogImage} className="h-56 w-full object-cover" alt="" />
                                    <div className="flex-auto px-6 py-5">
                                        <span className="mb-2 flex items-center text-sm font-semibold">
                                            <svg className="mr-1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.272 10.445L18 2m-8.684 8.632L5 2m7.761 8.048L8.835 2m5.525 0l-1.04 2.5M6 16a6 6 0 1 0 12 0a6 6 0 0 0-12 0Z" /></svg>
                                            {blog?.blogTag}</span>
                                        <h3 className="mt-4 mb-3 text-xl font-semibold xl:text-2xl">{blog.blogTitle}</h3>
                                        <p className="mb-4 text-base font-light">{blog.blogSubtitle}</p>
                                        <Link to={Routes.Blog.BlogDetails.replace(":id", blog?._id)} className="inline-block cursor-pointer select-none rounded-full border border-gray-800 bg-gray-800 px-2 py-1 text-center align-middle text-sm font-semibold leading-normal text-white no-underline shadow-sm">Learn More</Link>
                                    </div>
                                </Link>
                            </article>
                        </div>
                    ))
                )}

            </div>
        </section>
    );
};

export default ExclusiveBlogs;
