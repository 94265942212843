import React, {useState} from 'react';
import {Link} from "react-router-dom";
import Routes from "../../routes";
import Moment from 'moment';

const TableUserAdminCard = ({ user, openPopup }) => {
    const [developer, setDeveloper] = useState();
    const [newUser, setNewUser] = useState();

    const makeAdmin = () => {
        const titleText = user?.name + "'s Role Will Be Admin";
        openPopup(titleText, "Are You Sure To Change User As Admin?", user?.id, "admin");
    }

    const makeUser = () => {
        const titleText = user?.name + "'s User Role Will Be User";
        openPopup(titleText, "Are You Sure To Change User As User?", user?.id, "user");
    }

    const makeNormal = () => {
        const titleText = user?.name + " Will Be Normal User, Not Exclusive";
        openPopup(titleText, "Are You Sure To Change User As Normal User?", user?.id, "make-normal");
    }

    const makeExclusive = () => {
        const titleText = user?.name + " Will Be Exclusive User, Not Normal";
        openPopup(titleText, "Are You Sure To Change User As Exclusive User?", user?.id, "make-exclusive");
    }

    const deleteUser = () => {
        const titleText = user?.name + " Will Delete";
        openPopup(titleText, "Are You Sure To Delete User?", user?.id, "delete");
        console.log("delete user");
    }

    return (
        <tr>
            <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                <div className="inline-flex items-center gap-x-3">
                    <input type="checkbox" className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"/>

                    <div className="flex items-center gap-x-2">
                        <img className="object-cover w-10 h-10 rounded-lg" src={user?.profilePicture} alt=""/>
                        <div>
                            <h2 className="font-medium text-gray-800 dark:text-white ">{user?.name}</h2>
                            <p className="text-sm font-normal text-gray-600 dark:text-gray-400">{user?.email}</p>
                        </div>
                    </div>
                </div>
            </td>

            <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">

                {
                    user?.isEmailVerified ? (
                        <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                            <span className="h-1.5 w-1.5 rounded-full bg-emerald-500"></span>
                            <h2 className="text-sm font-normal text-emerald-500">{user?.isEmailVerified.toString()}</h2>
                        </div>
                    ) : (
                        <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-red-100/60 dark:bg-gray-800">
                            <span className="h-1.5 w-1.5 rounded-full bg-red-500"></span>
                            <h2 className="text-sm font-normal text-red-500">{user?.isEmailVerified.toString()}</h2>
                        </div>
                    )
                }

            </td>

            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{Moment(user?.createdAt).format('LL')}</td>

            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user?.role}</td>

            <td className="px-4 py-4 text-sm whitespace-nowrap">
                <div className="flex items-center gap-x-2">
                    <Link to={user?.linkedin} className="underline px-3 py-1 text-xs text-indigo-500 rounded-full dark:bg-gray-800 bg-indigo-100/60">Linkedin</Link>
                    <Link to={user?.twiter} className="underline px-3 py-1 text-xs text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60">Twitter</Link>
                    <div className="rounded-2xl dark:bg-gray-800 bg-pink-100/60">
                        <p className="px-3 py-1 text-xs text-pink-500">Phone</p>
                        <p className="px-3 py-1 text-xs text-pink-500">{user?.phoneNumber}</p>
                    </div>
                </div>
            </td>

            <td className="px-4 py-4 text-sm whitespace-nowrap">
                <div className="flex items-center gap-x-6">

                    {
                        user?.role === "user" ? (
                            <div className="flex items-center gap-x-6">

                                <button onClick={makeAdmin} className="text-red-500 transition-colors duration-200 hover:text-red-300 focus:outline-none">
                                    MAKE ADMIN
                                </button>

                                <button onClick={deleteUser} className="text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </button>

                            </div>
                        ) : (
                            <div className="flex items-center gap-x-6">

                                <button onClick={makeUser} className="text-emerald-500 transition-colors duration-200 hover:text-emerald-300 focus:outline-none">
                                    MAKE USER
                                </button>

                                <Link to={Routes.Admin.UserEdit.replace(":id", user?._id)} className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                </Link>

                                <button onClick={deleteUser} className="text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </button>

                            </div>
                        )
                    }

                    {
                        user?.isExclusive === "normal" ? (
                            <div className="flex items-center gap-x-6">

                                <button onClick={makeExclusive} className="text-red-500 transition-colors duration-200 hover:text-red-300 focus:outline-none">
                                    MAKE EXCLUSIVE
                                </button>

                            </div>
                        ) : (
                            <div className="flex items-center gap-x-6">

                                <button onClick={makeNormal} className="text-emerald-500 transition-colors duration-200 hover:text-emerald-300 focus:outline-none">
                                    MAKE NORMAL
                                </button>

                            </div>
                        )
                    }

                </div>
            </td>
        </tr>
    );
};

export default TableUserAdminCard;