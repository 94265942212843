import React, { useState } from 'react';
import { BlogService } from '../../services';
import PopupDefault from "../../components/popups/PopupDefault";
import { GiLetterBomb } from "react-icons/gi";
import he from "he";
import ReactQuill from "react-quill";

const BlogPublish = () => {
    const [formData, setFormData] = useState({
        blogTitle: '',
        blogSubtitle: '',
        blogImage: '',
        blogTag: '',
        featuredBlog: false,
        dealValueNormal: '',
        dealValueDiscount: '',
        content: [{
            title: '',
            content: '',
            img: ''
        }],
        games: [{
            title: '',
            logo: '',
            link: ''
        }],
        blogType: '',
        publisher_description: ''
    });

    /*
    numberStats: [{
            title: '',
            value: 0
        }],
        stringStats: [{
            title: '',
            value: ''
        }],
        booleanStats: [{
            title: '',
            value: false
        }],
    * */

    const [isAlertOpen, setIsAlertOpen] = useState(false);

    // gameContent
    const [gameContent, setGameContent] = useState([{ title: '', logo: '', link: '' }]);
    // blogContent
    const [blogContent, setBlogContent] = useState([{ title: '', content: '', img: '' }]);

    const [customText, setCustomText] = useState('');

    // const [htmlString, setHtmlString] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(formData);
            const response = await BlogService.publishBlog(formData);
            console.log('Blog published successfully:', response);
            setIsAlertOpen(true);
            // Optionally, redirect to the published blog or show a success message
        } catch (error) {
            console.error('Error publishing blog:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBlogTypeChange = (type) => {
        setFormData(prevState => ({
            ...prevState,
            blogType: type
        }));
    };

    const handleGameTitleChange = (e, index) => {
        const newChildren = [...gameContent];
        newChildren[index].title = e.target.value;
        setGameContent(newChildren);
        // setFormData({...formData, games: [...gameContent]});
    };

    const handleGameSubtitleChange = (e, index) => {
        const newChildren = [...gameContent];
        newChildren[index].subtitle = e.target.value;
        setGameContent(newChildren);
        // setFormData({...formData, games: [...gameContent]});
    };

    const handleGameLogoChange = (e, index) => {
        const newChildren = [...gameContent];
        newChildren[index].logo = e.target.value;
        setGameContent(newChildren);
        // setFormData({...formData, games: [...gameContent]});
    };

    const handleGameLinkChange = (e, index) => {
        const newChildren = [...gameContent];
        newChildren[index].link = e.target.value;
        setGameContent(newChildren);
        // setFormData({...formData, games: [...gameContent]});
    };

    const addGameChild = () => {
        setGameContent([...gameContent, { title: '', logo: '', link: '' }]);
    };

    const removeGameChild = (index) => {
        const newChildren = [...gameContent];
        newChildren.splice(index, 1);
        setGameContent(newChildren);
    };

    const handleBlogTitleChange = (e, index) => {
        const newChildren = [...blogContent];
        newChildren[index].title = e.target.value;
        setBlogContent(newChildren);
        // setFormData({...formData, content: [...blogContent]});
    };

    const handleBlogContentChange = (e, index) => {
        const newChildren = [...blogContent];
        newChildren[index].content = e.target.value;
        setBlogContent(newChildren);
        // setFormData({...formData, content: [...blogContent]});
    };

    const handleBlogImgChange = (e, index) => {
        const newChildren = [...blogContent];
        newChildren[index].img = e.target.value;
        setBlogContent(newChildren);
        // setFormData({...formData, content: [...blogContent]});
    };

    const addBlogChild = () => {
        setBlogContent([...blogContent, { title: '', content: '', img: '' }]);
    };

    const removeBlogChild = (index) => {
        const newChildren = [...blogContent];
        newChildren.splice(index, 1);
        setBlogContent(newChildren);
    };

    const initializeData = () => {
        setFormData({...formData, games: [...gameContent]});
        setFormData({...formData, content: [...blogContent]});
    };

    const toggleFeaturedBlog = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            featuredBlog: !prevFormData.featuredBlog
        }));
    };

    const handleGameDescriptionChange = (value) => {
        setCustomText(value);
        setFormData({...formData, "publisher_description": customText});
        console.log(value);
    };

    const htmlString = he.decode(formData.publisher_description);

    return (
        <div className="col-span-8 overflow-hidden rounded-xl sm:bg-gray-50 sm:px-8 sm:shadow">
            {/* Overlay for blurry background */}
            {isAlertOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm z-10"></div>
            )}

            <PopupDefault isOpen={isAlertOpen} onClose={() => setIsAlertOpen(false)} title={"Blog Publish Successfull"} desc={"Blog published on /exclusive-blogs"} icon={<GiLetterBomb size={30}/>}>
            </PopupDefault>

            <div className="container mx-auto w-full">
                <h1 className="text-3xl font-bold mb-4 mt-4">Publish a New Blog</h1>
                <form onSubmit={handleSubmit} className="max-w-lg">

                    <div className="flex justify-between mb-4">
                        <button type="button" className={`px-6 py-2 font-medium tracking-wide capitalize transition-colors duration-300 transform rounded-lg focus:outline-none ${formData.blogType === 'normal-blog' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'}`} onClick={() => handleBlogTypeChange('normal-blog')}>
                            Normal Blog
                        </button>
                        <button type="button" className={`px-6 py-2 font-medium tracking-wide capitalize transition-colors duration-300 transform rounded-lg focus:outline-none ${formData.blogType === 'exclusive-blog' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'}`} onClick={() => handleBlogTypeChange('exclusive-blog')}>
                            Exclusive Blog
                        </button>
                        <button type="button" className={`px-6 py-2 font-medium tracking-wide capitalize transition-colors duration-300 transform rounded-lg focus:outline-none ${formData.blogType === 'package-deals' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'}`} onClick={() => handleBlogTypeChange('package-deals')}>
                            Package Deals
                        </button>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="blogTitle" className="block mb-1">Blog Title</label>
                        <input type="text" id="blogTitle" name="blogTitle" value={formData.blogTitle} onChange={handleChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="blogSubtitle" className="block mb-1">Blog Subtitle</label>
                        <input type="text" id="blogSubtitle" name="blogSubtitle" value={formData.blogSubtitle} onChange={handleChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="blogImage" className="block mb-1">Blog Image</label>
                        <input type="text" id="blogImage" name="blogImage" value={formData.blogImage} onChange={handleChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="blogTag" className="block mb-1">Blog Tag</label>
                        <input type="text" id="blogTag" name="blogTag" value={formData.blogTag} onChange={handleChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="featuredBlog" className="block mb-1">Is Featured Blog?</label>
                        <label>
                            Featured Blog:
                            <input
                                type="checkbox"
                                checked={formData.featuredBlog}
                                onChange={toggleFeaturedBlog}
                            />
                        </label>
                        <br />
                        <button type="button" onClick={toggleFeaturedBlog}>
                            {formData.featuredBlog ? 'Unfeature' : 'Feature'} Blog
                        </button>
                    </div>

                    {/* Add fields for game content */}
                    {
                        formData?.blogType === "package-deals" ? (
                            <div>
                                <div className="mb-4 mt-14">
                                    <label htmlFor="dealValueNormal" className="block mb-1">Deal Value (Normal)</label>
                                    <input type="text" id="dealValueNormal" name="dealValueNormal" placeholder="14500" value={formData.dealValueNormal} onChange={handleChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="dealValueDiscount" className="block mb-1">Deal Value (Discount)</label>
                                    <input type="text" id="dealValueDiscount" name="dealValueDiscount" placeholder="10000" value={formData.dealValueDiscount} onChange={handleChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
                                </div>
                            </div>
                        ) : (
                            <div>

                            </div>
                        )
                    }

                    {/* Listing Description */}
                    <div className="">
                        <label htmlFor="listingDescription" className="block text-md font-medium">Listing Description</label>
                        <ReactQuill className="" theme="snow" value={customText} onChange={handleGameDescriptionChange} />

                        {/* Render parsed HTML here */}
                        <div className="w-full h-full form_input">
                            <label htmlFor="message" className="form_label">RECENT DESCRIPTION</label>
                            <p className="form_field_description">Copy and paste it to editor input.</p>
                            <div dangerouslySetInnerHTML={{ __html: htmlString }} />
                        </div>

                    </div>

                    {/* Add fields for blog content */}
                    <div className="mb-4 mt-14">
                        {blogContent.map((blog, index) => (
                            <div key={index}>

                                <label
                                    className="block mb-1">
                                    Blog Title
                                </label>

                                <input
                                    type="text"
                                    value={blog.title}
                                    onChange={(e) => handleBlogTitleChange(e, index)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:border-blue-500" />

                                <label
                                    className="block mb-1">
                                    Blog Content
                                </label>

                                <input
                                    type="text"
                                    value={blog.content}
                                    onChange={(e) => handleBlogContentChange(e, index)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:border-blue-500" />

                                <label
                                    className="block mb-1">
                                    Blog Img
                                </label>

                                <input
                                    type="text"
                                    value={blog.img}
                                    onChange={(e) => handleBlogImgChange(e, index)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:border-blue-500" />

                                <button onClick={() => removeBlogChild(index)} className="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-red-600 rounded-lg hover:bg-red-500 focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-80">
                                    Remove
                                </button>

                            </div>
                        ))}

                        <button onClick={addBlogChild}  className="mt-4 px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                            Add Child
                        </button>

                    </div>

                    {/* Add fields for game content */}
                    {
                        formData?.blogType === "package-deals" ? (
                            <div className="mb-4 mt-14">
                                {gameContent.map((game, index) => (
                                    <div key={index}>
                                        <label
                                            className="block mb-1">
                                            Game Title
                                        </label>

                                        <input
                                            type="text"
                                            value={game.title}
                                            onChange={(e) => handleGameTitleChange(e, index)}
                                            className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:border-blue-500" />

                                        <label
                                            className="block mb-1">
                                            Game Subtitle
                                        </label>

                                        <input
                                            type="text"
                                            value={game.subtitle}
                                            onChange={(e) => handleGameSubtitleChange(e, index)}
                                            className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:border-blue-500" />

                                        <label
                                            className="block mb-1">
                                            Game Logo
                                        </label>

                                        <input
                                            type="text"
                                            value={game.logo}
                                            onChange={(e) => handleGameLogoChange(e, index)}
                                            className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:border-blue-500" />

                                        <label
                                            className="block mb-1">
                                            Game Link
                                        </label>

                                        <input
                                            type="text"
                                            value={game.link}
                                            onChange={(e) => handleGameLinkChange(e, index)}
                                            className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:border-blue-500" />

                                        <button onClick={() => removeGameChild(index)} className="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-red-600 rounded-lg hover:bg-red-500 focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-80">Remove</button>
                                    </div>
                                ))}
                                <button onClick={addGameChild} className="mt-4 px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">Add Child</button>
                            </div>
                        ) : (
                            <div>

                            </div>
                        )
                    }

                    <div className="mt-12 grid-cols-2 mb-8">

                        <button type="submit" className="ml-8 bg-emerald-500 text-white px-4 py-2 rounded-md hover:bg-emerald-600">Publish Blog</button>
                    </div>

                    <button onClick={() => initializeData()} className="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                        Initialize Data (before publish)
                    </button>

                </form>
            </div>

        </div>
    );
};

export default BlogPublish;
