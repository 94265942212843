import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import UserService from "../../services/user.service";
import {formatPrice} from "../../utilities";
import Moment from 'moment';
import ProfileDetails from "./ProfileDetails";
import { FaLinkedin, FaTwitter, FaCheckCircle } from 'react-icons/fa';
import { FaCircleMinus } from 'react-icons/fa6';
import YouCanBookMe from "./YouCanBookMe";
import WhatsAppButton from "./WhatsAppButton";

const UserProfile = () => {
    const {id} = useParams(); /* get the id of the game from the url */
    const [user, setUser] = useState();

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await UserService.fetchUser(id);
                if(!user)
                    setUser(response);
                // setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    });

    const Badge = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="-ms-1 me-1.5 h-4 w-4"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
            </svg>
        );
    };

    const transactionsTotalling = formatPrice(user?.transactionsTotalling, "USD", false);

    return (
        <div className="flex flex-col lg:flex-row justify-center items-start mt-8">

            {/* User Details */}
            <div className="lg:w-2/4 px-2">

                <div className="flex flex-col items-center lg:items-start bg-white shadow-lg rounded-lg overflow-hidden">
                    <div className="m-4">
                        <div className="flex flex-col lg:flex-row items-center gap-x-2">
                            {/* User Profile Picture */}
                            <div className="relative">
                                <img
                                    className="object-cover w-32 h-32 lg:w-36 lg:h-36 rounded-full ring ring-gray-300 dark:ring-gray-600"
                                    src={user?.profilePicture}
                                    alt="Profile"
                                />
                                <span className="absolute bottom-0 right-2 w-6 h-6 rounded-full bg-emerald-500 ring-1 ring-white"></span>
                            </div>

                            <div className="space-y-2 pl-4 text-center lg:text-left">
                                <h1 className="text-xl font-semibold text-gray-700 capitalize dark:text-white">{user?.name}</h1>
                                <p className="text-base underline text-black dark:text-black">{user?.email}</p>
                                <p className="text-base underline text-black dark:text-black">+{user?.phoneNumber}</p>
                                <p className="text-base text-gray-500 dark:text-gray-400">Member since: {Moment(user?.createdAt).format('LL')}</p>
                                <p className="text-base text-gray-500 dark:text-gray-400">Role: {user?.role}</p>
                                <span className="inline-flex items-center justify-center rounded-full bg-green-100 px-2.5 py-0.5 text-green-700">
                                    <Badge />
                                    <p className="whitespace-nowrap text-sm">{user?.currentlyOwns} transactions totalling {transactionsTotalling}+</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* About section */}
                <div className="mt-2 bg-white shadow-lg rounded-lg overflow-hidden">
                    <div className="m-4">
                        <p className="font-bold text-lg pt-2">About</p>
                        <p className="text-gray-600">{user?.about}</p>
                    </div>
                </div>

                <ProfileDetails user={user}/>
            </div>

            {/* Social Media and Verification */}
            <div className="lg:w-1/4 lg:mr-4 w-full px-6">

                <div className="">

                    {/* Verification */}
                    <div className="mb-4 lg:mb-2 bg-white shadow-lg rounded-lg overflow-hidden lg:w-full">
                        <div className="p-4">
                            <p className="font-bold mb-4">{user?.name}'s Verification</p>
                            {/* <div className="border-b-2 border-gray-300 mb-4"></div> */}
                            <div className="flex flex-col space-y-2">
                                <VerificationLabel label="Email Address" verified={user?.isEmailVerified} />
                                <VerificationLabel label="Phone" verified={"true"} />
                                <VerificationLabel label="Identity" verified={"true"} />
                                <VerificationLabel label="LinkedIn" verified={"true"} />
                                <VerificationLabel label="Twitter" verified={"true"} />
                            </div>
                        </div>
                    </div>

                    {/* Social Media Accounts */}
                    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                        <div className="p-4">
                            <p className="font-bold">{user?.name}'s Social Media Accounts</p>
                            {/* <div className="border-b-2 border-gray-300"></div> */}
                        </div>
                        <div className="flex items-center justify-center lg:justify-start h-[55px] lg:ml-4 pb-4">
                            <Link to={user?.linkedin} target="_blank" rel="noopener noreferrer" className="mr-4">
                                <FaLinkedin className="w-7 h-7 text-blue-600" />
                            </Link>

                            <Link to={user?.twitter} target="_blank" rel="noopener noreferrer">
                                <FaTwitter className="w-7 h-7 text-blue-600" />
                            </Link>
                        </div>
                    </div>

                    <div className="rounded-lg border border-gray-100 text-center shadow-xl">
                        <div className="px-6 py-5">
                            <p className="font-bold">Contact with Broker</p>

                            <div className="mt-4 space-y-2">

                                <WhatsAppButton phoneNumber={"+44 7951 823263"}/>

                            </div>


                        </div>

                    </div>

                    {/*<YouCanBookMe />*/}

                </div>

            </div>
        </div>
    );
};

const VerificationLabel = ({ label, verified }) => {
    return (
        <div className="flex items-center">
            {verified ? <FaCheckCircle className="text-green-600" size={20} /> : <FaCircleMinus className="text-gray-400" size={20} />}
            <p className="pl-4 mr-2">{label}</p>
        </div>
    );
};

export default UserProfile;
