import {GameBroker, UserBroker} from "../agents";

const getUsers = async () => {
    const response = await UserBroker.get("/");
    return response;
};

const fetchUser = async (id) => {
    const response = await UserBroker.get(`/${id}`);
    return response;
};

const publishUser = async (offer) => {
    const response = await UserBroker.post("/", offer);
    return response;
};

const updateUser = async (id, newUser) => {
    const response = await UserBroker.put(`/${id}`, newUser);
    console.log(newUser);
    console.log(response);
    return response;
};

const makeAdmin = async (id) => {
    const response = await UserBroker.put(`/${id}/make-admin`);
    return response;
};

const makeUser = async (id) => {
    const response = await UserBroker.put(`/${id}/make-user`);
    return response;
};

const makeNormal = async (id) => {
    const response = await UserBroker.put(`/${id}/make-normal`);
    return response;
};

const makeExclusive = async (id) => {
    const response = await UserBroker.put(`/${id}/make-exclusive`);
    return response;
};

const deleteUser = async (id) => {
    const response = await UserBroker.delete(`/${id}`);
    return response;
};

const UserService = {
    getUsers,
    publishUser,
    updateUser,
    fetchUser,
    makeAdmin,
    makeUser,
    deleteUser,
    makeNormal,
    makeExclusive
};

export default UserService;