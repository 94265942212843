/* Contexts */
import {useGamePublishContext} from "../contexts";
/* Enums */
import {Currencies, MonetizationMethods} from "../../../../enums";
/* Form Metadata */
import FormMetadata from "./data/form_metadata";
/* Styles */
import "./styles/form_styles.css"
import Select from "react-select";
import {useState} from "react";

const MonetizationDetails = () => {

  const {gameForPublish} = useGamePublishContext();
  const [game, setGame] = gameForPublish;
  const [selectedMonetizationMethods, setSelectedMonetizationMethods] = useState();

  // Initial state for revenueProofs
  const [revenueProofs, setRevenueProofs] = useState(new Array(6).fill(''));
  const [isOpen, setIsOpen] = useState(false); // State to handle collapsible behavior

  // Function to update specific revenue proof based on index
  const handleInputChange = (index, value) => {
    const updatedProofs = [...revenueProofs];
    updatedProofs[index] = value;
    setRevenueProofs(updatedProofs);
    setGame({...game, revenueProofs: [...updatedProofs]});
  };

  const handlePriceChangeForMonthlyProfit = (event) => {
    const value = event?.target?.value;
    setGame({...game, monthly_profit: {...game.monthly_profit, value: value}});
  }

  const handlePriceChangeForTotalProfit = (event) => {
    const value = event?.target?.value;
    setGame({...game, total_profit: {...game.total_profit, value: value}});
  }

  const handleCurrencyChangeForMonthlyProfit = (event) => {
    const value = event?.target?.value;
    setGame({...game, monthly_profit: {...game.monthly_profit, currency: value}});
  }

  const handleCurrencyChangeForTotalProfit = (event) => {
    const value = event?.target?.value;
    setGame({...game, total_profit: {...game.total_profit, currency: value}});
  }

  return (
    <div className="flex flex-col ">
      {/* Step Title */}
      <h1 className="text-3xl font-medium leading-tight">{FormMetadata.monetization_details.step_title}</h1>
      {/* Step Description */}
      <div className="mt-3 text-s leading-8 text-gray-500">
        {FormMetadata.monetization_details.step_description}
        <br/>
      </div>

      {/* Monthly Profit */}
      <div className="form_field_container">

        <label htmlFor="message"
               className="form_label">{FormMetadata.monetization_details.fields.monthly_profit.title}
        </label>

        <p
          className="form_field_description">{FormMetadata.monetization_details.fields.monthly_profit.brief_description}
        </p>

        <div className="relative mt-1 rounded-md shadow-sm">

          <div className="form_currency_symbol_container">
            <span className="form_currency_symbol">{Currencies[game.monthly_profit?.currency]?.symbol || "$"}</span>
          </div>

          <input type="text" name="monthly_profit" id="value"
                 value={game?.monthly_profit?.value || ""}
                 onChange={handlePriceChangeForMonthlyProfit}
                 className="form_monetary_input"
                 placeholder={FormMetadata.monetization_details.fields.monthly_profit.placeholder}/>

          <div className="absolute inset-y-0 right-0 flex items-center">

            <label htmlFor="currency" className="sr-only">
              Currency
            </label>

            <select id="currency"
                    name="monthly_profit"
                    onChange={handleCurrencyChangeForMonthlyProfit}
                    value={game?.monthly_profit?.currency || "USD"}
                    className="form_currency">
              {
                Object
                  .keys(Currencies)
                  .map((key) =>
                    (<option key={key} value={Currencies[key]?.code}>{Currencies[key]?.code}</option>))
              }
            </select>

          </div>

        </div>

      </div>

      {/* Total Profit */}
      <div className="form_field_container">
        <label htmlFor="message"
               className="form_label">{FormMetadata.monetization_details.fields.total_profit.title}</label>
        <p
          className="form_field_description">{FormMetadata.monetization_details.fields.total_profit.brief_description}</p>
        <div className="relative mt-1 rounded-md shadow-sm">
          <div className="form_currency_symbol_container">
            <span className="form_currency_symbol">{Currencies[game.total_profit?.currency]?.symbol || "$"}</span>
          </div>
          <input type="text"
                 name="total_profit"
                 id="value"
                 onChange={handlePriceChangeForTotalProfit}
                 value={game?.total_profit?.value || ""}
                 className="form_monetary_input"
                 placeholder={FormMetadata.monetization_details.fields.total_profit.placeholder}/>
          <div className="absolute inset-y-0 right-0 flex items-center">
            <label htmlFor="currency" className="sr-only">Currency</label>
            <select id="currency"
                    name="total_profit"
                    value={game?.total_profit?.currency || "USD"}
                    onChange={handleCurrencyChangeForTotalProfit}
                    className="form_currency">
              {
                Object
                  .keys(Currencies)
                  .map((key) =>
                    (<option key={key} value={Currencies[key]?.code}>{Currencies[key]?.code}</option>))
              }
            </select>
          </div>
        </div>
      </div>

      {/* Monetization Methods */}
      <div className="form_field_container">
        <label htmlFor="message"
               className="form_label">{FormMetadata.monetization_details.fields.monetization_methods.title}</label>
        <p
          className="form_field_description">{FormMetadata.monetization_details.fields.monetization_methods.brief_description}</p>
        <Select
          name="monetization_methods"
          onChange={(event) => {
            setSelectedMonetizationMethods(event);
            setGame({...game, "monetization_methods": event.map((method) => method.value)})
          }}
          isMulti={true}
          isSearchable={false}
          value={selectedMonetizationMethods}
          options = {
            Object
                .values(MonetizationMethods)
                .map(({label, value}) => {
                  return {
                    value,
                    label
                  }
          })}
        />
      </div>

      <div className="my-4">
        {/* Collapsible toggle button */}
        <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? 'Hide Revenue Proofs' : 'Show Revenue Proofs'}
        </button>

        {/* Collapsible div */}
        {isOpen && (
            <div className="mt-4 bg-gray-100 p-4 rounded">
              {revenueProofs.map((proof, index) => (
                  <input
                      key={index}
                      type="text"
                      placeholder={`Revenue Proof #${index + 1}`}
                      value={proof}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      className="my-2 p-2 bg-white border rounded w-full"
                  />
              ))}
            </div>
        )}
      </div>

      {/* Proof of Revenue */}
      <div className="form_field_container">
        <label htmlFor="message"
               className="form_label">{FormMetadata.monetization_details.fields.proof_of_revenue.title}</label>
        <p
          className="form_field_description mb-3">{FormMetadata.monetization_details.fields.proof_of_revenue.brief_description}</p>
        <div className="flex items-center justify-center w-full">
          <label htmlFor="dropzone-file"
                 className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none"
                   stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                className="font-semibold">Click to upload</span> or drag and drop</p>
              <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX.
                800x400px)</p>
            </div>
            <input id="dropzone-file" type="file" className="hidden"/>
          </label>
        </div>
      </div>
    </div>
  );
}

export default MonetizationDetails;