/* this object holds all the routes for the application */
const Routes = Object.freeze({
    Root: "/",
    Landing: {
        Services: "/services",
        About: "/about",
        Privacy: "/privacy-policy",
        TermsOfService: "/terms-of-service",
        MelihBahri: "/melihbahri",
        Legal: "/legal",
        SaleProcess: "/acquisition-process",
        Imprint: "/imprint",
    },
    Errors: {
        NotFound: "/not-found",
        ServerError: "/server-error"
    },
    Authentication: {
        Login: "/login",
        Register: "/register",
        Account: "/account",
        Profile: "/account/details/:id"
    },
    Games: {
        Featured: "/games/featured",
        Publish: "/games/publish",
        Details: "/games/details/:id",
        Edit: "/games/edit/:id",
        ExclusiveGames: "/exclusive-games",
        AppStoreGames: "/games/app-store",
        PlayStoreGames: "/games/play-store",
        GamesByGenre: "/games/:genre"
    },
    Steam: {
        Games: "/steam/games",
        Publish: "/steam/publish",
        Update: "/steam/update/:id",
        Details: "/steam/:id"
    },
    Admin: {
        Dashboard: "/admin",
        PendingGames: "/admin/pending-games",
        RejectedGames: "/admin/rejected-games",
        ApprovedGames: "/admin/approved-games",
        Account: "/admin/account",
        Users: "/admin/users",
        LOI: "/admin/letters-of-intent",
        UserEdit: "/admin/users/edit/:id",
        SteamGames: "/admin/steam-games"
    },
    UserDashboard: {
        Main: "/dashboard",
        Acquisition: "/acquisition-details/:id"
    },
    Blog: {
        BlogsAdmin: "/admin/blogs",
        BlogPublish: "/admin/blogs/publish",
        BlogUpdate: "/admin/blogs/update/:id",
        BlogDetails: "/blogs/:id",
        Blogs: "/blogs",
        ExclusiveBlogs: "/exclusive-blogs",
        PackageDeals: "/package-deals"
    },
    Investor: {
        Turkish: "/turkce-yatirimci-sunumu"
    },
    BrandMarketing: {
        Landing: "/brand-marketing"
    }
});

export default Routes;