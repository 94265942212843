import axios from 'axios';
import isEmpty from "lodash.isempty";

/* This HTTP Agent will be responsible for making the HTTP requests to the server. */
class HTTPAgent {
    constructor(baseURL, options) {

        /* destructure the HTTP Request and Response Interceptors from the options object */
        const {
            requestInterceptors,
            responseInterceptors
        } = options;

        /* create the axios instance */
        this.axios = axios.create({
            baseURL: baseURL
        });

        // attach the request interceptors to the axios instance
        requestInterceptors.forEach( requestInterceptor => ( this.axios.interceptors.request.use(config => requestInterceptor.onFulfilled(config), error => requestInterceptor.onRejected(error))));

        // attach the response interceptors to the axios instance
        responseInterceptors.forEach( responseInterceptor => (this.axios.interceptors.response.use(config =>responseInterceptor.onFulfilled(config), error => responseInterceptor.onRejected(error))));

    }

    async get(path, params, options) {
        /* this method performs a GET request to the server */

        /* if the caller function has specified the params, then add them to the options object */
        if(!isEmpty(params)) {
            options = {
                ...options,
                params: params
            }
        }

        const response =  await this.axios.get(path, options);
        return response;
    }

    async post(path, data, options) {
        /* this method performs a POST request to the server */
        const response =  await this.axios.post(path, data, options);
        return response;
    }

    async put(path, data, options) {
        /* this method performs a PUT request to the server */
        const response =  await this.axios.put(path, data, options);
        return response;
    }

    async delete(path, options) {
        /* this method performs a DELETE request to the server */
        const response =  await this.axios.delete(path, options);
        return response;
    }

    async request(config) {
        /* this method performs a request to the server */
        const response =  await this.axios.request(config);
        return response;
    }
}

export default HTTPAgent;