import React from 'react';
import {Link} from "react-router-dom";
import Routes from "../../routes";

const PackageDealCard = ({blog}) => {

    return (
        <div className="mt-12 max-w-5xl mx-auto bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden lg:max-w-3/4">
            {/* Blog Category */}
            {
                blog?.blogType === "package-deals" ? (
                    <div className="px-4 py-2 bg-emerald-500 text-white dark:bg-emerald-600 text-center lg:text-left">
                        <p className="text-sm lg:text-xs uppercase">RECOMMENDED BY M&A ADVISORY</p>
                    </div>
                ) : (
                    <div className="px-4 py-2 bg-emerald-500 text-white dark:bg-emerald-600 text-center lg:text-left">
                        <p className="text-sm lg:text-xs uppercase">BLOG</p>
                    </div>
                )
            }

            {/* Blog Title */}
            <div className="px-4 py-2">
                <h2 className="text-2xl lg:text-3xl font-semibold text-gray-800 dark:text-gray-200">{blog.blogTitle}</h2>
            </div>

            {/* Blog Subtitle */}
            <div className="px-4 py-2">
                <p className="text-lg lg:text-base text-gray-600 dark:text-gray-400">{blog.blogSubtitle}</p>
            </div>

            {/* Read More Button */}
            <div className="flex justify-end px-4 py-2">
                <Link to={Routes.Blog.BlogDetails.replace(":id", blog?._id)} className="bg-emerald-500 hover:bg-emerald-600 text-white dark:bg-emerald-400 dark:hover:bg-emerald-500 font-bold py-2 px-6 rounded-3xl lg:py-3 lg:px-8">
                    Read More
                </Link>
            </div>

            {/* Divider */}
            <hr className="mx-4 border-gray-300 dark:border-gray-700"/>

            {/* Author Card */}
            <div className="px-4 py-2">
                <div className="flex items-center">
                    <img src="https://i.postimg.cc/RVXdRpCg/melihbahri-white.png" alt="Author" className="w-10 h-10 border-emerald-500 border-2 rounded-full mr-4 dark:border-emerald-400"/>
                    <div>
                        <p className="text-lg lg:text-base text-gray-800 dark:text-gray-200 font-semibold">Melih Bahri AKTAN</p>
                        <p className="text-lg lg:text-sm text-gray-600 dark:text-gray-400">Founder and CEO at Scapcher.com</p>
                    </div>
                </div>
            </div>

            {/* Divider */}
            <hr className="mx-4 border-gray-300 dark:border-gray-700"/>

            {/* Game Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 px-4 py-2">
                {
                    blog.games?.map((game) => (
                        <div key={game?._id}>
                            {/* Game Card */}
                            <div className="flex items-center">
                                <img src={game?.logo} alt="Game"
                                     className="w-16 h-16 md:w-16 md:h-16 lg:w-16 lg:h-16 rounded-md mr-2"/>
                                <div>
                                    <Link to={game?.link} className="text-lg lg:text-base text-gray-800 dark:text-gray-200 font-semibold underline">{game?.title}</Link>
                                    <p className="text-lg lg:text-sm text-gray-600 dark:text-gray-400">{game?.subtitle}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            {/* Divider */}
            <hr className="mx-4 border-gray-300 dark:border-gray-700"/>

            {/* Deal Values */}
            <div className="flex justify-end px-4 py-2">
                <div className="text-right">
                    <p className="text-lg lg:text-base text-gray-800 dark:text-gray-200 font-light">Games Worth</p>
                    <p className="text-lg lg:text-sm text-red-600 dark:text-red-400 line-through">${blog?.dealValueNormal}</p>
                </div>
                <div className="text-right ml-8">
                    <p className="text-lg lg:text-base text-gray-800 dark:text-gray-200 font-light">Package Deal Price</p>
                    <p className="text-lg lg:text-sm text-emerald-600 dark:text-emerald-400 underline">${blog?.dealValueDiscount}</p>
                </div>
            </div>
        </div>

    );
};

export default PackageDealCard;