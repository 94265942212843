import { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";

/* Services */
import { GameService } from "../../../services";
import { AuthenticationService } from "../../../services";

/* Routes */
import Routes from "../../../routes";

/* Helper Components */
import LoadingGrid from "../../general/Loading/LoadingGrid";
import GamePreview from "../featured/helpers/GamePreview";

const AppStoreGames = () => {
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(true); /* whenever the component loads, assume it's loading */
    const [error, setError] = useState();
    const analytics = getAnalytics(); // Firebase Analytics nesnesini alın
    const [sortedGames, setSortedGames] = useState([]);
    const [sortOrder, setSortOrder] = useState('reviews-high-to-low');

    useEffect(() => {
        fetchFeaturedGames();
    }, []);

    useEffect(() => {
        sortGames(sortOrder);
    }, [games, sortOrder]);

    const fetchFeaturedGames = async () => {
        /* this method fetches the featured games from the server */
        try {
            const featuredGames = await GameService.fetchAppStoreGames();
            setGames(featuredGames);
            setLoading(false); /* the request was successful, so we can stop loading */
            setError(null); /* if the request was successful, then there is no error */
            setSortOrder('reviews-high-to-low');
        }
        catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    const sortGames = (order) => {
        const sorted = [...games].sort((a, b) => {
            switch (order) {
                case 'reviews-high-to-low':
                    return b.reviewsCount - a.reviewsCount;
                case 'reviews-low-to-high':
                    return a.reviewsCount - b.reviewsCount;
                case 'price-high-to-low':
                    return b.price.value - a.price.value;
                case 'price-low-to-high':
                    return a.price.value - b.price.value;
                case 'total-profit-high-to-low':
                    return b.total_profit.value - a.total_profit.value;
                case 'total-profit-low-to-high':
                    return a.total_profit.value - b.total_profit.value;
                case 'monthly-profit-high-to-low':
                    return b.monthly_profit.value - a.monthly_profit.value;
                case 'monthly-profit-low-to-high':
                    return a.monthly_profit.value - b.monthly_profit.value;
                default:
                    return 0;
            }
        });
        setSortedGames(sorted);
    };

    const handleSortChange = (e) => {
        setSortOrder(e.target.value);
    };

    return (
        <div className="container mx-auto px-4 py-8">
            {AuthenticationService.isAuthenticated() ? (
                <div className="container mx-auto px-4 py-8 bg-gray-50 dark:bg-gray-900">
                    <div>
                        {/* Title and subtitle section */}
                        <div className="mb-10 text-center">
                            <h1 className="text-4xl font-bold text-gray-800 dark:text-gray-200 md:text-3xl">App Store Games / iOS</h1>
                        </div>

                        {/* Sort and Filter Section */}
                        <div className="flex justify-end mb-4">
                            <select
                                onChange={handleSortChange}
                                value={sortOrder}
                                className="border border-gray-300 rounded-md p-2 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300"
                            >
                                <option value="reviews-high-to-low">Reviews: High to Low</option>
                                <option value="reviews-low-to-high">Reviews: Low to High</option>
                                <option value="price-high-to-low">Price: High to Low</option>
                                <option value="price-low-to-high">Price: Low to High</option>
                                <option value="total-profit-high-to-low">Total Profit: High to Low</option>
                                <option value="total-profit-low-to-high">Total Profit: Low to High</option>
                                <option value="monthly-profit-high-to-low">Monthly Profit: High to Low</option>
                                <option value="monthly-profit-low-to-high">Monthly Profit: Low to High</option>
                            </select>
                        </div>

                        {/* Authenticated user content */}
                        <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">Welcome back!</h2>
                        <div className="grid gap-16 mt-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
                            {loading ? (
                                <div className="flex items-center justify-center col-span-3 mt-4">
                                    <LoadingGrid />
                                </div>
                            ) : (
                                sortedGames && sortedGames.length > 0 && sortedGames.map(game => (
                                    <GamePreview key={game._id} game={game} />
                                ))
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {/* Guest user content */}
                    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-800 px-6">
                        <div className="w-full max-w-2xl p-10 bg-white shadow-xl rounded-xl dark:bg-gray-900 dark:shadow-2xl">
                            <h2 className="text-4xl font-bold text-center text-gray-800 dark:text-gray-200">Discover Exclusive Game Listings</h2>
                            <p className="mt-6 text-lg text-center text-gray-600 dark:text-gray-400">
                                Join us to explore exclusive game business listings. Sign in or create an account to get started.
                            </p>
                            <div className="flex flex-col sm:flex-row gap-6 mt-10">
                                <Link
                                    to={Routes.Authentication.Login}
                                    className="w-full px-6 py-3 text-lg font-semibold text-center text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                >
                                    Log In
                                </Link>
                                <Link
                                    to={Routes.Authentication.Register}
                                    className="w-full px-6 py-3 text-lg font-semibold text-center text-white bg-green-500 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                                >
                                    Sign Up
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>

    );
}

export default AppStoreGames;