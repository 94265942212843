import React from 'react';
import ReactPlayer from 'react-player';

const YouTubePlayer = ({ url, className }) => {
    return (
        <div className={`${className}`} style={{ position: 'relative' }}>
            <ReactPlayer
                url={url}
                width="100%"
                height="100%"
                style={{ position: 'absolute', top: '0', left: '0' }}
                config={{
                    youtube: {
                        playerVars: { modestbranding: 1, rel: 0, showinfo: 0 }
                    }
                }}
            />
        </div>
    );
};

export default YouTubePlayer;
