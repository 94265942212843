import { SteamBroker } from "../agents";

const fetchSteamGames = async () => {
    const response = await SteamBroker.get("/");
    return response;
};

const publishSteamGame = async (steamGame) => {
    const response = await SteamBroker.post("/publish", steamGame);
    return response;
};

const deleteSteamGame = async (id) => {
    const response = await SteamBroker.delete(`/delete/${id}`);
    return response;
};

const fetchSteamGame = async (id) => {
    const response = await SteamBroker.get(`/${id}`);
    return response;
};

const updateSteamGame = async (id, steamGame) => {
    const response = await SteamBroker.put(`/update/${id}`, steamGame);
    return response;
};


const SteamService = {
    fetchSteamGames,
    deleteSteamGame,
    publishSteamGame,
    fetchSteamGame,
    updateSteamGame
};

export default SteamService;