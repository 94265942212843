import React from 'react';
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import {Link} from "react-router-dom";
import Routes from "../../routes";
import { FaRegStarHalfStroke } from "react-icons/fa6";

const TableGameAdminCard = ({ game, whichPage, openPopup }) => {

    const handleApprove = () => {
        const titleText = game?.title +  "'s Game Will Approved"
        openPopup(titleText, "Are You Sure To Change as Approved?", game?._id, "approve");
    }

    const handleReject = () => {
        const titleText = game?.title +  "'s Game Will Rejected"
        openPopup(titleText, "Are You Sure To Change as Rejected?", game?._id, "reject");
    }

    return (
        <tr>
            <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                <div className="inline-flex items-center gap-x-3">
                    <input type="checkbox" className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"/>

                    <div className="flex items-center gap-x-2">
                        <img className="object-cover w-10 h-10 rounded-lg" src={game?.icon} alt=""/>
                        <div>
                            <Link to={Routes.Games.Details.replace(":id", game?._id)} className="underline font-medium text-gray-800 dark:text-white ">{game?.title}</Link>
                            <p className="text-sm font-normal text-gray-600 dark:text-gray-400">{game?.developer}</p>
                            <div className="flex text-amber-300">
                                <FaRegStarHalfStroke />
                                <p className="text-sm font-normal text-gray-600 dark:text-gray-400"> {game?.reviewsCount}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                {
                    game?.approval_status === "APPROVED" ? (
                        <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                            <span className="h-1.5 w-1.5 rounded-full bg-emerald-500"></span>
                            <h2 className="text-sm font-normal text-emerald-500">{game?.gameType}</h2>
                        </div>
                    ) : (
                        <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-red-100/60 dark:bg-gray-800">
                            <span className="h-1.5 w-1.5 rounded-full bg-red-500"></span>
                            <h2 className="text-sm font-normal text-red-500">{game?.approval_status}</h2>
                        </div>
                    )
                }

            </td>
            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{game?.developer}</td>
            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">Melih Bahri Aktan</td>
            <td className="px-4 py-4 text-sm whitespace-nowrap">
                <div className="flex grid-cols-3 items-center gap-x-2">
                    <p className="px-3 py-1 text-xs text-indigo-500 rounded-full dark:bg-gray-800 bg-indigo-100/60">${game?.monthly_profit.value}/mo</p>
                    <p className="px-3 py-1 text-xs text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60">${game?.total_profit.value}/y</p>
                    <div className="rounded-2xl dark:bg-gray-800 bg-pink-100/60">
                        <p className="px-3 py-1 text-xs text-pink-500">Installs: {game?.total_downloads}</p>
                    </div>
                </div>

                <div className="flex grid-cols-3 mt-4 items-center gap-x-2">

                    {game?.revenueProofs.length == 0 && <p className="px-3 py-1 text-xs text-white rounded-full bg-black">Required Revenue Proofs</p>}

                    {game?.analyticsProofs.length == 0 && <p className="px-3 py-1 text-xs text-white rounded-full bg-black">Required Analytics Proofs</p>}

                    {game?.revenueProofs.length > 0 && <p className="px-3 py-1 text-xs text-white rounded-full bg-emerald-500">Done Revenue Proofs</p>}
                    {game?.analyticsProofs.length > 0 && <p className="px-3 py-1 text-xs text-white rounded-full bg-emerald-500">Done Revenue Proofs</p>}

                </div>

            </td>
            <td className="px-4 py-4 text-sm whitespace-nowrap">
                <div className="flex items-center gap-x-6">

                    {
                        whichPage === "approved" ? (
                            <div className="flex items-center gap-x-6">

                                <button onClick={handleReject} className="text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </button>

                                <Link to={Routes.Games.Edit.replace(":id", game?._id)} className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                </Link>

                            </div>
                        ) : (
                            <div className="flex items-center gap-x-6">
                                <button onClick={handleApprove} className="text-green-500 transition-colors duration-200 hover:text-green-300 focus:outline-none">
                                    <FaCheckCircle size={18}/>
                                </button>

                                <button onClick={handleReject} className="text-red-500 transition-colors duration-200 hover:text-red-300 focus:outline-none">
                                    <FaCircleXmark size={18}/>
                                </button>
                            </div>
                        )
                    }

                </div>
            </td>
        </tr>
    );
};

export default TableGameAdminCard;