import React from 'react';
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import {Link} from "react-router-dom";
import Routes from "../../routes";
import { FaRegStarHalfStroke } from "react-icons/fa6";

const AdminBlogCard = ({ blog, openPopup }) => {

    const handleReject = () => {
        const titleText = blog?.title +  "'s Game Will Rejected"
        openPopup(titleText, "Are You Sure To Change as Rejected?", blog?._id, "reject");
    }

    return (
        <div className="mt-4">
            <article
                className="rounded-lg border border-gray-100 bg-white p-4 shadow-sm transition hover:shadow-lg sm:p-6"
            >
                <a href="#">
                    <h3 className="mt-0.5 text-lg font-medium text-gray-900">
                        {blog?.blogTitle}
                    </h3>
                </a>

                <p className="mt-2 line-clamp-3 text-sm/relaxed text-gray-500">
                    {blog?.blogSubtitle}
                </p>

                <div className="mt-4">
                    <button onClick={handleReject} className="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-red-600 rounded-lg hover:bg-red-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                        Delete
                    </button>

                    <Link to={Routes.Blog.BlogUpdate.replace(":id", blog?._id)} className="ml-8 group mt-4 inline-flex items-center gap-1 text-sm font-medium text-blue-600">
                        Edit

                        <span aria-hidden="true" className="block transition-all group-hover:ms-0.5 rtl:rotate-180">
                                        &rarr;
                                    </span>
                    </Link>
                </div>

            </article>

        </div>
    );
};

export default AdminBlogCard;