import React from "react";

const FullScreenModal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-75">
            <button onClick={onClose} className="absolute top-4 right-4 text-white text-3xl">&times;</button>
            {children}
        </div>
    );
};

export default FullScreenModal;