
const MonetizationMethods = Object.freeze({
  ADS: {
    value: 'ads',
    label: 'Ads'
  },
  IAP: {
    value: 'in-app-purchases',
    label: 'In-App Purchases'
  },
  SUBSCRIPTIONS: {
    value: 'subscriptions',
    label: 'Subscriptions'
  }
});

export default MonetizationMethods;
