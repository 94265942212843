import React, { useState, useEffect } from 'react';
import {GiLetterBomb} from "react-icons/gi";
import {FaArrowLeft} from "react-icons/fa6";

import {Link, useParams} from "react-router-dom";
import OfferPendingSection from "../steps/OfferPendingSection";
import OfferApprovedSection from "../steps/OfferApprovedSection";
import OfferLegalContractSection from "../steps/OfferLegalContractSection";
import OfferPaymentSection from "../steps/OfferPaymentSection";
import OfferGameTransferSection from "../steps/OfferGameTransferSection";
import OfferCompletedSection from "../steps/OfferCompletedSection";

import {useSelector} from "react-redux";
import {AuthenticationService} from "../../services";
import OfferService from "../../services/offer.service";
import Routes from "../../routes";
import PopupDefault from "../../components/popups/PopupDefault";
import SellerCard from "../../components/game/details/components/SellerCard";

const AcquisitionDetails = () => {
    const {id} = useParams();

    const data = [
        {
            step: 1,
            status: "LOI Sent"
        },
        {
            step: 2,
            status: "LOI Approved"
        },
        {
            step: 3,
            status: "Legal Contract"
        },
        {
            step: 4,
            status: "Payment"
        },
        {
            step: 5,
            status: "Game Transfer"
        },
        {
            step: 6,
            status: "Acquisition Completed"
        }
    ];

    const [currentOffer, setCurrentOffer] = useState();
    const [currentState, setCurrentState] = useState({
        step: 1,
        status: "pending"
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');
    const [popupDesc, setPopupDesc] = useState('');

    const [stepContent, setStepContent] = useState(null);

    const [user, setUser] = useState({});
    const userLogoutState = useSelector(state => state.userLogout);
    const userLoginState = useSelector(state => state.userLogin);

    useEffect(() => {
        setUser(AuthenticationService.getUser());
    }, [userLoginState, userLogoutState]);

    useEffect(() => {
        fetchData();
        async function fetchData() {
            try {
                const response = await OfferService.getOfferDetailsByOfferId(id);
                setCurrentOffer(response);
                setCurrentState(response?.status);
            } catch (error) {
                fetchData();
                console.log(error);
            }
        }
        // fetchData();
    });

    useEffect(() => {
        handleShowStepContent(getStepContent(currentState.step, currentOffer), currentState.step);
    }, [currentState.step]); // Add currentState.step as a dependency

    const handleShowStepContent = (content, step) => {
        if(step <= currentState.step)
            setStepContent(content);
    };

    const refreshAsContract = () => {
        setPopupTitle("refreshAsContract");
        setPopupDesc("refreshAsContract");
        setCurrentState({step: 3, status: "Legal Contract"})
    }

    const refreshAsPayment = () => {
        setPopupTitle("refreshAsPayment");
        setPopupDesc("refreshAsPayment");
        setCurrentState({step: 4, status: "Payment"})
    }

    const refreshAsTransfer = () => {
        setPopupTitle("refreshAsTransfer");
        setPopupDesc("refreshAsTransfer");
        setCurrentState({step: 5, status: "Game Transfer"})
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <div className="mx-4 min-h-screen max-w-screen-xl sm:mx-8 xl:mx-auto">
                <h1 className="border-b py-6 text-4xl font-semibold">Scapcher Dashboard</h1>
                <div className="grid grid-cols-8 pt-3 sm:grid-cols-10">
                    <div className="relative my-4 w-56 sm:hidden">


                        <ul className="max-h-0 select-none flex-col overflow-hidden rounded-b-lg shadow-md transition-all duration-300 peer-checked:max-h-56 peer-checked:py-3">
                            {/* BACK TO  Acquisitions */}
                            <Link to={Routes.UserDashboard.Main}  className="mb-8 flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                                <FaArrowLeft size={25}/>
                                <span className="mx-1">Back To Acquisitions</span>
                            </Link>
                        </ul>

                    </div>

                    <div className="col-span-2 hidden sm:block">
                        <ul className="px-4">
                            {/* BACK TO  Acquisitions */}
                            <Link to={Routes.UserDashboard.Main}  className="mb-8 flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                                <FaArrowLeft size={25}/>
                                <span className="mx-1">Back To Acquisitions</span>
                            </Link>
                        </ul>
                    </div>

                    <div className="col-span-8 overflow-hidden rounded-xl sm:bg-gray-50 sm:px-8 sm:shadow">
                        <div className="pt-4">
                            <h1 className="py-2 text-2xl font-semibold">Acquisition Details</h1>
                        </div>
                        <hr className="mt-4 mb-8" />

                        <div>

                            {/* Modal or Pop-up */}
                            {isModalOpen && (
                                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm z-10">

                                    <PopupDefault isOpen={isModalOpen} onClose={() => closeModal(false)} title={popupTitle} desc={popupDesc} icon={<GiLetterBomb size={30}/>}>

                                    </PopupDefault>
                                </div>
                            )}

                            {/* CLIENT SECTION */}
                            <div className="mb-8 rounded-2xl shadow-lg bg-white sm:w-full md:w-full lg:w-full xl:w-2/4 2xl:w-2/4">
                                <div className="p-4">
                                    <p className="">Welcome, {user?.name}!</p>
                                    <p className="text-lg">{user?.email}</p>
                                </div>
                            </div>

                            <div className="mb-12 grid gap-8 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">

                                {/* GAME SUMMARY SECTION */}
                                <div className="rounded-3xl bg-white shadow-lg w-full">
                                    <div className="px-4 py-6 sm:px-8 sm:py-10">
                                        <div className="flow-root">
                                            <ul className="-my-8">
                                                <li className="flex flex-col space-y-3 py-6 text-left sm:flex-row sm:space-x-5 sm:space-y-0">
                                                    <div className="shrink-0 relative">
                                                        <img
                                                            className="h-24 w-24 max-w-full rounded-lg object-cover"
                                                            src={currentOffer?.icon}
                                                            alt="" />
                                                    </div>

                                                    <div className="relative flex flex-1 flex-col justify-between">
                                                        <div className="sm:col-gap-5 sm:grid sm:grid-cols-2">
                                                            <div className="pr-8 sm:pr-5">
                                                                <p className="text-base font-semibold text-gray-900">{currentOffer?.title}</p>
                                                                <Link to={"http://www.scapcher.com/account/details/65f862673499e500028a7684"} className="mx-0 mt-1 mb-0 text-sm text-gray-400 underline">Seller: Melih Bahri Aktan</Link>
                                                            </div>

                                                            <div className="mt-4 flex items-end justify-between sm:mt-0 sm:items-start sm:justify-end">
                                                                <p className="shrink-0 w-20 text-base font-semibold text-gray-900 sm:order-2 sm:ml-8 sm:text-right">${currentOffer?.offer}</p>
                                                            </div>
                                                        </div>
                                                        <div className="absolute top-0 right-0 flex sm:bottom-0 sm:top-auto">
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                        <div className="mt-6 space-y-3 border-t border-b py-8">
                                            <div className="flex items-center justify-between">
                                                <p className="text-gray-400">Subtotal</p>
                                                <p className="text-lg font-semibold text-gray-900">${currentOffer?.offer}</p>
                                            </div>
                                        </div>
                                        <div className="mt-6 flex items-center justify-between">
                                            <p className="text-sm font-medium text-gray-900">Total</p>
                                            <p className="text-2xl font-semibold text-gray-900"><span className="text-xs font-normal text-gray-400">USD </span> {currentOffer?.offer}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* SELLER SECTION */}
                                <SellerCard developer={{profilePicture: "https://i.ibb.co/0JFtKNm/melihbahri-white.png", name: "Melih Bahri Aktan", email: "info@melihbahri.com", phoneNumber: 447951823263}} />

                            </div>

                            {/* STEPS */}
                            <div>
                                <h2 className="sr-only">Steps</h2>
                                <div
                                    className="relative after:absolute after:inset-x-0 after:top-1/2 after:block after:h-0.5 after:-translate-y-1/2 after:rounded-lg after:bg-gray-50"
                                >

                                    <ProgressSteps
                                        data={data}
                                        currentState={currentState}
                                        handleShowStepContent={handleShowStepContent}
                                        currentOffer={currentOffer}
                                        openModal={openModal}
                                        refreshAsContract={refreshAsContract}
                                        refreshAsPayment={refreshAsPayment}
                                        refreshAsGameTransfer={refreshAsTransfer}/>

                                </div>
                            </div>

                            {/* CONTENT */}
                            <header className="bg-gray-50">
                                <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 py-4 sm:py-8">
                                    <div className="sm:flex sm:items-center sm:justify-between">
                                        <div className="text-center sm:text-left">
                                            {stepContent}
                                            <p class="mt-1.5 text-sm text-gray-500">{currentState?.step + " " + " " + currentState?.status}</p>
                                        </div>
                                    </div>
                                </div>
                            </header>

                        </div>

                    </div>

                </div>
            </div>

        </div>

    );
};

const ProgressSteps = ({ data, currentState, handleShowStepContent, currentOffer, openModal, refreshAsContract, refreshAsPayment, refreshAsGameTransfer }) => {
    return (
        <ol className="relative z-10 flex justify-between text-sm font-medium text-gray-500">
            {data.map((stepData, index) => (
                <button
                    onClick={() => handleShowStepContent(getStepContent(stepData.step, currentOffer, openModal, refreshAsContract, refreshAsPayment, refreshAsGameTransfer), stepData.step)}
                    key={index}
                    className={`shadow-lg flex items-center gap-2 bg-white p-2 border-2 rounded-xl ${
                        currentState?.step === stepData.step
                            ? 'border-blue-600'
                            : stepData.step < currentState?.step
                                ? 'border-emerald-500'
                                : 'cursor-not-allowed border-gray-300'
                    }`}
                >
                    {/* Step Indicator */}
                    <span
                        className={`size-6 rounded-full text-center font-bold text-white ${
                            stepData.step < currentState?.step
                                ? 'bg-emerald-500'
                                : currentState?.step === stepData.step
                                    ? 'bg-blue-600'
                                    : 'bg-gray-50 cursor-not-allowed'
                        }`}
                    >
                        {stepData.step}
                    </span>

                    {/* Step Label */}
                    <span className="hidden sm:block">
                        {stepData.status}
                    </span>

                </button>
            ))}
        </ol>
    );
};

const getStepContent = (step, currentOffer, openModal, refreshAsContract, refreshAsPayment, refreshAsGameTransfer) => {
    switch (step) {
        case 1:
            return <OfferPendingSection />;
        case 2:
            return <OfferApprovedSection currentOffer={currentOffer} openModal={openModal} refreshAsContract={refreshAsContract}/>;
        case 3:
            return <OfferLegalContractSection currentOffer={currentOffer} openModal={openModal} refreshAsPayment={refreshAsPayment}/>;
        case 4:
            return <OfferPaymentSection currentOffer={currentOffer} openModal={openModal} refreshAsGameTransfer={refreshAsGameTransfer}/>;
        case 5:
            return <OfferGameTransferSection currentOffer={currentOffer} openModal={openModal}/>;
        case 6:
            return <OfferCompletedSection currentOffer={currentOffer} openModal={openModal}/>;
        default:
            return null;
    }
};

export default AcquisitionDetails;