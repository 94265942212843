import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { FirebaseProvider } from './firebase/FirebaseContext';
import TheRoutes from './routes';
import LoadingDots from './components/general/Loading/LoadingDots';
import NotFound from './pages/error/NotFound';
import UserAccount from './components/user/UserAccount';
import UserProfile from './components/user/UserProfile';

// Dashboard
import DashboardLayout from "./components/layouts/DashboardLayout";

import {
    GamePublish,
    GameDetails
} from "./components/game";
import GameEdit from './components/game/details/GameEdit';
import LandingLayout from "./components/layouts/LandingLayout";
import { useDispatch, useSelector } from "react-redux";
import { AuthenticationService } from "./services";

import PrivacyPolicy from "./legal/privacy-policy";
import Imprint from "./legal/imprint";
import TermsOfService from "./legal/terms-of-service";

import ApprovedGames from "./admin/approved-games";
import LettersOfIntent from "./admin/letters-of-intent";
import PendingGames from "./admin/pending-games";
import Users from "./admin/users";

import BlogPublish from "./blog/publish";
import BlogUpdate from "./blog/update";
import BlogDetails from "./blog/details";

import Blogs from "./blog/blogs";
import ExclusiveBlogs from "./blog/exclusive-blogs";
import PackageDeals from "./blog/package-deals";
import BlogsLayout from "./blog/layout";

import SaleProcess from "./pages/sale-process";
import Legal from "./pages/legal";

import AcquisitionDetails from "./dashboard/acquisition-details";
import Dashboard from "./dashboard";
import SteamGames from "./steam/steam-games";
import PublishSteam from "./steam/publish";
import SteamEdit from "./steam/update";
import SteamGamesAdmin from "./admin/steam-games";
import SteamDetails from "./steam/details";
import ExclusiveGames from "./components/game/exclusive-games";
import RejectedGames from "./admin/rejected-games";
import AppStoreGames from "./components/game/app-store";
import PlayStoreGames from "./components/game/play-store";
import GamesByGenre from "./components/game/games-by-genre";
import FomoAlert from "./components/FomoAlert";

const Services = React.lazy(() => import('./components/landing/Services'));
const Login = React.lazy(() => import('./components/authentication/login'));
const Register = React.lazy(() => import('./components/authentication/register'));
const FeaturedGames = React.lazy(() => import('./components/game/featured/FeaturedGames'));

function App() {
    const [user, setUser] = useState({});
    const dispatch = useDispatch();

    const userLogoutState = useSelector(state => state.userLogout);
    const userLoginState = useSelector(state => state.userLogin);
    const { loading, error } = userLogoutState;

    useEffect(() => {
        setUser(AuthenticationService.getUser());
        if (window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
            window.location.href = `https://${window.location.hostname}${window.location.pathname}`;
        }
    }, []);  // Remove unnecessary dependencies

    return (
        <div className="dark:bg-black">
            <FomoAlert />
            <Suspense fallback={<LoadingDots />}>
                <FirebaseProvider>
                    <div>
                        <Routes>

                            {/* CORE LANDING */}
                            <Route
                                path={""}
                                element={<LandingLayout children={<FeaturedGames />} />}
                            />

                            {/* CORE LANDING */}
                            <Route
                                path={TheRoutes.Landing.Root}
                                element={<LandingLayout children={<FeaturedGames />} />}
                            />

                            <Route path="*" element={<LandingLayout children={<NotFound />} />} />

                            {/* LANDING */}
                            <Route path={TheRoutes.Landing.SaleProcess} element={<LandingLayout children={<SaleProcess />} />} />
                            <Route path={TheRoutes.Landing.Services} element={<LandingLayout children={<Services />} />} />
                            <Route path={TheRoutes.Landing.Legal} element={<LandingLayout children={<Legal />} />} />

                            {/* LEGAL */}
                            <Route path={TheRoutes.Landing.Privacy} element={<LandingLayout children={<PrivacyPolicy />} />} />
                            <Route path={TheRoutes.Landing.Imprint} element={<LandingLayout children={<Imprint />} />} />
                            <Route path={TheRoutes.Landing.TermsOfService} element={<LandingLayout children={<TermsOfService />} />} />

                            {/* AUTH */}
                            <Route path={TheRoutes.Authentication.Login} element={<LandingLayout children={<Login />} />} />
                            <Route path={TheRoutes.Authentication.Register} element={<LandingLayout children={<Register />} />} />

                            {/* ACCOUNT - PROFILE */}
                            <Route path={TheRoutes.Authentication.Account} element={<LandingLayout children={<UserAccount />} />} />
                            <Route path={TheRoutes.Authentication.Profile} element={<LandingLayout children={<UserProfile />} />} />
                            <Route path={TheRoutes.Landing.MelihBahri} element={<LandingLayout children={<UserProfile />} />} />

                            {/* GAME CLIENT */}
                            <Route path={TheRoutes.Games.Publish} element={<LandingLayout children={<GamePublish />} />} />
                            <Route path={TheRoutes.Games.Featured} element={<LandingLayout children={<FeaturedGames />} />} />
                            <Route path={TheRoutes.Games.ExclusiveGames} element={<LandingLayout children={<ExclusiveGames />} />} />
                            <Route path={TheRoutes.Games.Details} element={<LandingLayout children={<GameDetails />} />} />
                            <Route path={TheRoutes.Games.AppStoreGames} element={<LandingLayout children={<AppStoreGames />} />} />
                            <Route path={TheRoutes.Games.PlayStoreGames} element={<LandingLayout children={<PlayStoreGames />} />} />
                            <Route path={TheRoutes.Games.GamesByGenre} element={<LandingLayout children={<GamesByGenre />} />} />

                            {/* STEAM CLIENT */}
                            <Route path={TheRoutes.Steam.Games} element={<LandingLayout children={<SteamGames />} />} />
                            <Route path={TheRoutes.Steam.Details} element={<LandingLayout children={<SteamDetails />} />} />
                            {/* STEAM ADMIN */}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Steam.Publish} element={<DashboardLayout children={<PublishSteam />} />} /> : <Route path={TheRoutes.Steam.Publish} element={<LandingLayout children={<NotFound />} />} />}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Steam.Update} element={<DashboardLayout children={<SteamEdit />} />} /> : <Route path={TheRoutes.Steam.Update} element={<LandingLayout children={<NotFound />} />} />}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Admin.SteamGames} element={<DashboardLayout children={<SteamGamesAdmin />} />} /> : <Route path={TheRoutes.Steam.Update} element={<LandingLayout children={<NotFound />} />} />}

                            {/* BLOG CLIENT */}
                            <Route path={TheRoutes.Blog.Blogs} element={<LandingLayout children={<Blogs />} />} />
                            <Route path={TheRoutes.Blog.ExclusiveBlogs} element={<LandingLayout children={<ExclusiveBlogs />} />} />
                            <Route path={TheRoutes.Blog.PackageDeals} element={<LandingLayout children={<PackageDeals />} />} />
                            <Route path={TheRoutes.Blog.BlogDetails} element={<LandingLayout children={<BlogDetails />} />} />

                            {/* DASHBOARD */}
                            <Route path={TheRoutes.UserDashboard.Main} element={<LandingLayout children={<Dashboard />} />} /> : <Route path={TheRoutes.UserDashboard.Main} element={<LandingLayout children={<NotFound />} />} />
                            <Route path={TheRoutes.UserDashboard.Acquisition} element={<LandingLayout children={<AcquisitionDetails />} />} /> : <Route path={TheRoutes.UserDashboard.Acquisition} element={<LandingLayout children={<NotFound />} />} />

                            {/* BLOG ADMIN */}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Blog.BlogsAdmin} element={<LandingLayout children={<BlogsLayout />} />} /> : <Route path={TheRoutes.Admin.Blogs} element={<LandingLayout children={<NotFound />} />} />}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Blog.BlogPublish} element={<LandingLayout children={<BlogPublish />} />} /> : <Route path={TheRoutes.Admin.BlogPublish} element={<LandingLayout children={<NotFound />} />} />}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Blog.BlogUpdate} element={<LandingLayout children={<BlogUpdate />} />} /> : <Route path={TheRoutes.Admin.BlogUpdate} element={<LandingLayout children={<NotFound />} />} />}

                            {/* ADMIN GAME EDIT */}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Games.Edit} element={<DashboardLayout children={<GameEdit />} />} /> : <Route path={TheRoutes.Admin.Edit} element={<LandingLayout children={<NotFound />} />} />}

                            {/* ADMIN ROUTE */}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Admin.Dashboard} element={<DashboardLayout children={<ApprovedGames />} />} /> : <Route path={TheRoutes.Admin.Dashboard} element={<LandingLayout children={<NotFound />} />} />}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Admin.PendingGames} element={<DashboardLayout children={<PendingGames />} />} /> : <Route path={TheRoutes.Admin.PendingGames} element={<LandingLayout children={<NotFound />} />} />}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Admin.RejectedGames} element={<DashboardLayout children={<RejectedGames />} />} /> : <Route path={TheRoutes.Admin.RejectedGames} element={<LandingLayout children={<NotFound />} />} />}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Admin.ApprovedGames} element={<DashboardLayout children={<ApprovedGames />} />} /> : <Route path={TheRoutes.Admin.ApprovedGames} element={<LandingLayout children={<NotFound />} />} />}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Admin.Users} element={<DashboardLayout children={<Users />} />} /> : <Route path={TheRoutes.Admin.Users} element={<LandingLayout children={<NotFound />} />} />}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Admin.LOI} element={<DashboardLayout children={<LettersOfIntent />} />} /> : <Route path={TheRoutes.Admin.LOI} element={<LandingLayout children={<NotFound />} />} />}

                            {/* ADMIN ACCOUNT */}
                            {user?.role === 'admin' ? <Route path={TheRoutes.Admin.Account} element={<DashboardLayout children={<UserAccount />} />} /> : <Route path={TheRoutes.Admin.Account} element={<LandingLayout children={<NotFound />} />} />}

                        </Routes>
                    </div>
                </FirebaseProvider>
            </Suspense>
        </div>
    );
}

export default App;