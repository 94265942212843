/* Contexts */
import {useGamePublishContext} from "../contexts";
/* Enums */
import {Currencies} from "../../../../enums";
/* Form Metadata */
import FormMetadata from "./data/form_metadata";
/* Styles */
import "./styles/form_styles.css"
import "./styles/html_editor_styles.css"
/* Package imports */
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {convertToHTML} from 'draft-convert';

import {EditorState} from 'draft-js';
import {useState} from "react";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const GameDetails = () => {

  const {gameForPublish} = useGamePublishContext();
  const [game, setGame] = gameForPublish;
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
    const [customText, setCustomText] = useState('');

    // Initial state for analyticsProofs
    const [analyticsProofs, setAnalyticsProofs] = useState(new Array(6).fill(''));
    const [isOpen, setIsOpen] = useState(false); // State to handle collapsible behavior

    // Function to update specific analytics proof based on index
    const handleInputChange = (index, value) => {
        const updatedProofs = [...analyticsProofs];
        updatedProofs[index] = value;
        setAnalyticsProofs(updatedProofs);
        setGame({...game, analyticsProofs: [...updatedProofs]});
        console.log(updatedProofs);
    };


  const handleChange = (event) => {
    const {name, value} = event.target;
    setGame({...game, [name]: value});
  };

  const handlePriceChange = (event) => {
    const price = event.target?.value;
    setGame({...game, price: {...game.price, value: price}});
  }

  const handleCurrencyChange = (event) => {
    const currency = event.target?.value;
    setGame({...game, price: {...game.price, currency: currency}});
  }

  const handleGameDescriptionChange = (value) => {
      setCustomText(value);
      setGame({...game, "publisher_description": customText});
  };

  return (
    <div className="flex flex-col ">
      {/* Step Title */}
      <h1 className="form_step_title">{FormMetadata.game_description.step_title}</h1>
      {/* Step Description */}
      <div className="form_step_description">
        {FormMetadata.game_description.step_description}
      </div>
      {/* Price */}
      <div className="form_field_container">
        <label htmlFor="message" className="form_label">{FormMetadata.game_description.fields.price.title}</label>
        <p className="form_field_description">{FormMetadata.game_description.fields.price.brief_description}</p>
        <div className="relative mt-1 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">{Currencies[game.price?.currency]?.symbol || "$"}</span>
          </div>
          <input type="text" name="price_value" id="price"
                 className="form_monetary_input"
                 value={game?.price?.value || ""}
                 onChange={handlePriceChange}
                 placeholder={FormMetadata.game_description.fields.price.placeholder}/>
          <div className="absolute inset-y-0 right-0 flex items-center">
            <label htmlFor="currency" className="sr-only">Currency</label>
            <select id="currency"
                    name="price_currency"
                    value={game.price?.currency || "USD"}
                    onChange={handleCurrencyChange}
                    className="form_currency">
              {
                Object
                  .keys(Currencies)
                  .map((key) =>
                    (<option key={key} value={Currencies[key]?.code}>{Currencies[key]?.code}</option>))
              }
            </select>
          </div>
        </div>
      </div>

        {/* Video Link */}
        <div className="form_field_container">
            <label htmlFor="message" className="form_label">{FormMetadata.game_description.fields.video_link.title}</label>
            <p className="form_field_description">{FormMetadata.game_description.fields.video_link.brief_description}</p>
            <input
                onChange={handleChange}
                value={game["video_url"] || ""}
                name="video_url"
                placeholder={FormMetadata.game_description.fields.video_link.placeholder}
                className="form_input"
            />
        </div>

        {/* Listing Subtitle */}
        <div className="form_field_container">
            <label htmlFor="message" className="form_label">Listing Subtitle</label>
            <p className="form_field_description">Enter impressive subtitle</p>
            <input
                onChange={handleChange}
                value={game["listing_subtitle"] || ""}
                name="listing_subtitle"
                placeholder="Listing Subtitle"
                className="form_input"
            />
        </div>

        {/* Total Downloads */}
        <div className="form_field_container">
            <label htmlFor="message" className="form_label">Total Downloads</label>
            <p className="form_field_description">Enter the total number of downloads your game has received since its release.</p>
            <input
                onChange={handleChange}
                value={game["total_downloads"] || ""}
                name="total_downloads"
                placeholder="Total Downloads"
                className="form_input"
            />
        </div>

        {/* Monthly Downloads */}
        <div className="form_field_container">
            <label htmlFor="message" className="form_label">Monthly Downloads</label>
            <p className="form_field_description">Provide the number of downloads your game has received in the past month.</p>
            <input
                onChange={handleChange}
                value={game["monthly_downloads"] || ""}
                name="monthly_downloads"
                placeholder="Monthly Downloads"
                className="form_input"
            />
        </div>

        <div className="my-4">
            {/* Collapsible toggle button */}
            <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={() => setIsOpen(!isOpen)}
            >
                {isOpen ? 'Hide Analytics Proofs' : 'Show Analytics Proofs'}
            </button>

            {/* Collapsible div */}
            {isOpen && (
                <div className="mt-4 bg-gray-100 p-4 rounded">
                    {analyticsProofs.map((proof, index) => (
                        <input
                            key={index}
                            type="text"
                            placeholder={`Analytics Proof #${index + 1}`}
                            value={proof}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            className="my-2 p-2 bg-white border rounded w-full"
                        />
                    ))}
                </div>
            )}
        </div>


        {/* Publisher Description */}
      <div className="form_field_container">
        <label htmlFor="message"
               className="form_label">{FormMetadata.game_description.fields.publisher_description.title}</label>
        <p
          className="form_field_description">{FormMetadata.game_description.fields.publisher_description.brief_description}
        </p>
          <ReactQuill theme="snow" value={customText} onChange={handleGameDescriptionChange} />
      </div>
    </div>
  );
}

export default GameDetails;