import React, {useState} from 'react';
import OfferService from "../../services/offer.service";

const OfferGameTransferSection = ({ currentOffer, openModal }) => {

    const [appleId, setAppleId] = useState('');
    const [teamId, setTeamId] = useState('');

    const handleUpdateAppleIdAndTeamId = async () => {
        try {
            const response = await OfferService.handleUpdateAppleIdAndTeamId(currentOffer?._id, { appleId, teamId });
            console.log(response);
            handleUpdateStatus("AcquisitionCompleted");
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpdateStatus = async (status) => {
        try {
            const response = await OfferService.handleUpdateStatus(currentOffer?._id, status);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="App max-w-4xl mx-auto p-4">
            <div className="container mx-auto py-8">

                <div className="mb-12 flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                    <div className="flex items-center justify-center w-48 bg-blue-500">
                        <svg className="w-16 h-16 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z" />
                        </svg>
                    </div>

                    <div className="px-4 py-2 -mx-3">
                        <div className="mx-3">
                            <span className="font-semibold text-blue-500 dark:text-blue-400">Game Business Transfer - All Required Terms and Conditions</span>
                            <p class="text-sm text-gray-600 dark:text-gray-200">Source Code, App Store Game Transfer, Project Files, Files, Design Files and Project in 1-5 Days</p>
                        </div>
                    </div>
                </div>

                <h1 className="text-2xl mb-6">Game Transfer Pending From Broker</h1>

                <div className="mb-8">
                    <label htmlFor="apple_id" className="block text-sm text-gray-500 dark:text-gray-300">Apple ID</label>

                    <input
                        type="text"
                        value={appleId}
                        placeholder={currentOffer?.appleId}
                        onChange={(e) => setAppleId(e.target.value)}
                        className="block bg-gray-50 mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
                </div>

                <div className="mb-8">
                    <label htmlFor="team_id" className="block text-sm text-gray-500 dark:text-gray-300">Team ID</label>

                    <input
                        type="text"
                        value={teamId}
                        placeholder={currentOffer?.teamId}
                        onChange={(e) => setTeamId(e.target.value)}
                        className="block bg-gray-50 mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
                </div>

                <button
                    onClick={handleUpdateAppleIdAndTeamId}
                    className="mb-8 px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform rounded-3xl bg-emerald-500 hover:bg-emerald-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                    Submit Transfer Info
                </button>

                <p className="text-xl font-semibold mb-6">We are waiting for all required transfer terms from the broker, including App Store game transfer, source code transfer via Google Drive, and all necessary documents, files, projects, and assets.</p>

            </div>
        </div>
    );
};

export default OfferGameTransferSection;
