import React from 'react';

const LOICard = ({ offer }) => {
    return (
        <div className="bg-white rounded-lg shadow-md p-4">
            <div className="flex items-center mb-4">
                <img src={offer.profilePicture} alt="Profile" className="w-12 h-12 rounded-full mr-4" />
                <div>
                    <h3 className="text-xl font-semibold">{offer.name}</h3>
                    <p className="text-gray-600">{offer.email}</p>
                </div>
            </div>
            <div className="mb-4">
                <h4 className="text-lg font-semibold mb-2">{offer.title}</h4>
                <img src={offer.icon} alt="Game Icon" className="w-24 h-24" />
            </div>
            <div className="mb-2">
                <p className="text-gray-700">Offer Value: ${offer.offer}</p>
            </div>
            <div className="flex items-center">
                <span className={`inline-block px-2 py-1 text-sm font-semibold rounded-full ${offer.approvalStatus === 'APPROVED' ? 'bg-green-500 text-white' : 'bg-yellow-500 text-gray-900'}`}>
          {offer.approvalStatus}
        </span>
            </div>
        </div>
    );
};

export default LOICard;
