import React from 'react';
import {formatPrice} from "../../utilities";

const ProfileDetails = ({ user } ) => {
    const overallAcquisitionBudget = formatPrice(user?.overallAcquisitionBudget, "USD", false);
    const preferredDealSize = formatPrice(user?.preferredDealSize, "USD", false);

    return <div>
        <div className="flow-root rounded-lg border border-gray-100 py-3 shadow-sm mt-4">
            <dl className="-my-3 divide-y divide-gray-100 text-sm">
                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                    <dt className="font-medium text-gray-900">Title</dt>
                    <dd className="text-gray-700 sm:col-span-2">{user?.title}</dd>
                </div>

                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                    <dt className="font-medium text-gray-900">Name</dt>
                    <dd className="text-gray-700 sm:col-span-2">{user?.name}</dd>
                </div>

                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                    <dt className="font-medium text-gray-900">Overall acquisition budget</dt>
                    <dd className="text-gray-700 sm:col-span-2">{overallAcquisitionBudget}</dd>
                </div>

                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                    <dt className="font-medium text-gray-900">Preferred deal size</dt>
                    <dd className="text-gray-700 sm:col-span-2">{preferredDealSize}</dd>
                </div>

                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                    <dt class="font-medium text-gray-900">Currently owns</dt>
                    <dd class="text-gray-700 sm:col-span-2">
                        {user?.currentlyOwns}+
                    </dd>
                </div>
            </dl>
        </div>
    </div>
};

export default ProfileDetails;