import React from "react";
import { formatPrice } from "../../../../utilities";
import { FcBullish, FcMultipleSmartphones } from "react-icons/fc";

const GameStatsCard = ({ game }) => {
    const monthlyProfit = formatPrice(game?.monthly_profit?.value, game?.monthly_profit?.currency, false);
    const totalProfit = formatPrice(game?.total_profit?.value, game?.total_profit?.currency, false);

    const formatNumber = (number) => {
        if (number < 1000) {
            return number.toString();
        }
        const thousands = number / 1000;
        const formatted = thousands >= 10 ? Math.round(thousands) : thousands.toFixed(1);
        return `${formatted}K`;
    }

    const MoneyBadge = () => (
        <span className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-100 text-blue-600">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                />
            </svg>
        </span>
    );

    return (
        <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6 mb-6 grid grid-cols-2 sm:grid-cols-2 md:flex md:flex-row justify-around items-center text-center shadow-sm dark:shadow-gray-700">
            <div className="flex flex-col items-center mb-6 md:mb-0">
                <MoneyBadge className="text-gray-800 dark:text-gray-300" />
                <div className="pt-2 text-gray-600 dark:text-gray-400">
                    <span>Monthly Profit</span>
                </div>
                <div className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                    <strong>{monthlyProfit}</strong>
                </div>
            </div>
            <div className="flex flex-col items-center mb-6 md:mb-0">
                <MoneyBadge className="text-gray-800 dark:text-gray-300" />
                <div className="pt-2 text-gray-600 dark:text-gray-400">
                    <span>Total Profit</span>
                </div>
                <div className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                    <strong>{totalProfit}</strong>
                </div>
            </div>
            <div className="flex flex-col items-center mb-6 md:mb-0">
                <FcBullish size={50} className="text-green-600 dark:text-green-400" />
                <div className="pt-2 text-gray-600 dark:text-gray-400">
                    <span>Total Downloads</span>
                </div>
                <div className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                    <strong>{formatNumber(game?.total_downloads)}</strong>
                </div>
            </div>
            <div className="flex flex-col items-center">
                <FcMultipleSmartphones size={50} className="text-blue-600 dark:text-blue-400" />
                <div className="pt-2 text-gray-600 dark:text-gray-400">
                    <span>Monthly Downloads</span>
                </div>
                <div className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                    <strong>{formatNumber(game?.monthly_downloads)}</strong>
                </div>
            </div>
        </div>



    );
};

export default GameStatsCard;
