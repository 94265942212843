import React from 'react';

const OfferPendingSection = () => {
    return (
        <div className="cursor-not-allowed App max-w-4xl mx-auto p-4">
            <div className="container mx-auto py-8">

                <div className="flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                    <div className="flex items-center justify-center w-12 bg-emerald-500">
                        <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z" />
                        </svg>
                    </div>

                    <div className="px-4 py-2 -mx-3">
                        <div className="mx-3">
                            <span className="font-semibold text-emerald-500 dark:text-emerald-400">You Sent an LOI</span>
                            <p class="text-sm text-gray-600 dark:text-gray-200">LOI Under Review!</p>
                        </div>
                    </div>
                </div>

                <h1 className="mt-8 text-2xl mb-6">You Sent Letter of Intent</h1>

                <p className="text-xl font-semibold mb-6">
                    On this stage pending approval by Broker.
                </p>
                <p className="text-xl font-semibold mb-6">You've sent an offer to Scapcher. Our brokers are currently reviewing your offer. If it meets our criteria, it will proceed to the next step.</p>

            </div>
        </div>
    );
};

export default OfferPendingSection;
