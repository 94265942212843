import React from 'react';
import {FaArrowRight} from "react-icons/fa6";
import {Link} from "react-router-dom";
/* Routes */
import Routes from "../../routes";

const OfferCardClient = ({ offer }) => {
    return (
        <tr>
            <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                <div className="inline-flex items-center gap-x-3">
                    <div className="flex items-center gap-x-2">
                        <img className="object-cover w-10 h-10 rounded-lg"
                             src={offer?.icon}
                             alt=""/>
                        <div>
                            <h2 className="font-medium text-gray-800 dark:text-white underline">{offer?.title}</h2>
                            <p className="text-sm font-normal text-gray-600 dark:text-gray-400">Developer: Hikmet Duran</p>
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                    <span className="h-1.5 w-1.5 rounded-full bg-emerald-500"></span>

                    <h2 className="text-sm font-normal text-emerald-500">{offer?.status.status}</h2>
                </div>
            </td>
            <Link to={"https://www.scapcher.com/account/details/65f862673499e500028a7684"} className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap underline">Melih Bahri AKTAN</Link>
            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">Yes</td>
            <td className="px-4 py-4 text-sm whitespace-nowrap">
                <div className="flex items-center gap-x-2">
                    <p className="px-3 py-1 text-xs text-indigo-500 rounded-full dark:bg-gray-800 bg-indigo-100/60">${offer?.offer}</p>
                    {/* CONTINUE BUTTON */}
                </div>
            </td>

            <div className="px-4 py-4 pr-8 mr-8">
                <button
                    className="flex items-center px-1 py-1 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-3xl hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                    <Link to={Routes.UserDashboard.Acquisition.replace(":id", offer?._id)} className="mx-1">CONTINUE</Link>
                    <FaArrowRight size={10}/>
                </button>
            </div>
        </tr>
    );
};

export default OfferCardClient;