import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SteamService from "../../services/steam.service";
import Routes from "../../routes";
import {AuthenticationService} from "../../services";

const SteamGames = () => {
    const [games, setGames] = useState([]);

    useEffect(() => {
        const fetchGames = async () => {
            try {
                // const response = await axios.get('/api/steam');
                const response = await SteamService.fetchSteamGames();
                setGames(response);
            } catch (error) {
                console.error('Error fetching games', error);
            }
        };

        fetchGames();
    }, []);

    return (
        <div className="container mx-auto px-4 py-8">
            {AuthenticationService.isAuthenticated() ? (
                <div className="max-w-6xl mx-auto p-8">
                    <h1 className="text-3xl font-bold mb-8">Steam Games</h1>
                    <div className="">
                        {games.map((game) => (
                            <div key={game._id} className="mt-12 max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row">
                                <div className="md:w-1/3">
                                    <img
                                        className="w-full h-full object-cover"
                                        src={game.icon}
                                        alt={game.title}
                                        style={{ width: '460px', height: '215px' }}
                                    />
                                </div>
                                <div className="md:w-2/3 p-6 flex flex-col justify-between">
                                    <div>
                                        <h2 className="text-2xl font-bold text-blue-600">{game.title}</h2>
                                        <p className="text-gray-700 mt-2">{game.subtitle}</p>
                                    </div>
                                    <div className="mt-4">
                                        <div className="flex items-center">
                                            <span className="text-gray-600">ALL REVIEWS:</span>
                                            <a href="#" className="text-blue-500 ml-2">Mostly Positive (15)</a>
                                        </div>
                                        <div className="flex items-center mt-2">
                                            <span className="text-gray-600">RELEASE DATE:</span>
                                            <span className="ml-2 text-gray-900">12 Mar, 2023</span>
                                        </div>
                                        <div className="flex items-center mt-2">
                                            <span className="text-gray-600">DEVELOPER:</span>
                                            <a href="#" className="text-blue-500 ml-2">{game.developer}</a>
                                        </div>
                                        <div className="flex items-center mt-2">
                                            <span className="text-gray-600">PUBLISHER:</span>
                                            <a href="#" className="text-blue-500 ml-2">Nash Equilibrium</a>
                                        </div>
                                    </div>
                                    <div className="mt-4 text-right">
                                        <Link to={Routes.Steam.Details.replace(":id", game?._id)} className="bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-2 px-6 rounded-3xl lg:py-3 lg:px-8">READ MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div>
                    {/* Guest user content */}
                    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-6">
                        <div className="w-full max-w-2xl p-10 bg-white shadow-xl rounded-xl">
                            <h2 className="text-4xl font-bold text-center text-gray-800">Discover Exclusive Game Listings</h2>
                            <p className="mt-6 text-lg text-center text-gray-600">
                                Join us to explore exclusive game business listings. Sign in or create an account to get started.
                            </p>
                            <div className="flex flex-col sm:flex-row gap-6 mt-10">
                                <Link to={Routes.Authentication.Login} className="w-full px-6 py-3 text-lg font-semibold text-center text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                                    Log In
                                </Link>
                                <Link to={Routes.Authentication.Register} className="w-full px-6 py-3 text-lg font-semibold text-center text-white bg-green-500 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50">
                                    Sign Up
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    );
};

export default SteamGames;
