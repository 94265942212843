import React from 'react';

const Imprint = () => {
    return (
        <div className="mt-12 max-w-4xl mx-auto p-4 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100">
            <div className="container mx-auto py-8">
                <h1 className="text-3xl font-semibold mb-6">Imprint</h1>
                <p>
                    MELIH BAHRI LIMITED.
                </p>
                <p className="mb-4">
                    71-75 Shelton Street, Covent Garden, London, United Kingdom
                </p>
                <p>
                    Represented by Melih Bahri AKTAN
                </p>
                <a href={`mailto:${"info@melihbahri.com"}`} className="text-blue-600 dark:text-blue-400 hover:underline">
                    info@melihbahri.com
                </a>
            </div>
        </div>
    );
};

export default Imprint;
