import React, {Component, useRef, useState} from 'react';
import { Link } from "react-router-dom";
import Routes from "../../routes";
import { BsLinkedin, BsTwitter, BsInstagram } from "react-icons/bs";
import emailjs from "@emailjs/browser";


const Footer = () => {
    const handleWhatsAppClick = () => {
        const message = encodeURIComponent("YourMessage");
        window.open(`https://wa.me/${447951823263}?text=${message}`, "_blank");
    };

    return (
        <footer className="bg-gray-50 dark:bg-gray-800 mt-8">
            <div className="mx-auto grid max-w-screen-xl gap-y-8 gap-x-12 px-4 py-10 md:grid-cols-2 xl:grid-cols-4 xl:px-10">
                <div className="max-w-sm">
                    <div className="mb-6 flex h-12 items-center space-x-2">
        <span className="text-2xl font-bold">
          <span className="text-blue-600 dark:text-blue-400">Scapcher.com</span>
        </span>
                    </div>
                    <div className="text-gray-500 dark:text-gray-400">
                        #1 Marketplace for Game Business Acquisitions
                    </div>
                    <ul className="mt-8 flex justify-center gap-6 sm:justify-start md:gap-8">
                        <Link to={"https://www.linkedin.com/company/scapcher"}>
                            <BsLinkedin className="text-blue-700 dark:text-blue-500" size={25} />
                        </Link>
                        <Link to={"https://twitter.com/scapchercom"}>
                            <BsTwitter className="text-blue-700 dark:text-blue-500" size={25} />
                        </Link>
                        <Link to={"https://www.instagram.com/scapcherdotcom/"}>
                            <BsInstagram className="text-blue-700 dark:text-blue-500" size={25} />
                        </Link>
                    </ul>
                </div>

                <div className="">
                    <div className="mt-4 mb-2 font-medium xl:mb-4 text-gray-900 dark:text-gray-100">Contact Us!</div>
                    <div className="text-gray-500 dark:text-gray-400 mb-4">
                        71-75 Shelton Street, Covent Garden, London, UK
                    </div>
                    <a href={`mailto:${"sales@scapcher.com"}`} className="underline text-blue-400 dark:text-blue-300 font-bold mb-4">
                        sales@scapcher.com
                    </a>
                    <button onClick={handleWhatsAppClick} className="underline text-emerald-400 dark:text-emerald-300 font-bold mb-4 mt-4">
                        +44 7951 823263 (WhatsApp)
                    </button>
                </div>

                <div className="">
                    <div className="mt-4 mb-2 font-medium xl:mb-4 text-gray-900 dark:text-gray-100">Links</div>
                    <nav aria-label="Footer Navigation" className="text-gray-500 dark:text-gray-400">
                        <ul className="space-y-3">
                            <li><a className="hover:text-blue-600 dark:hover:text-blue-400 hover:underline" href={Routes.Games.Featured}>Marketplace</a></li>
                            <li><a className="hover:text-blue-600 dark:hover:text-blue-400 hover:underline" href={Routes.Blog.Blogs}>Blogs</a></li>
                            <li><a className="hover:text-blue-600 dark:hover:text-blue-400 hover:underline" href={Routes.Blog.ExclusiveBlogs}>Exclusive Blogs</a></li>
                            <li><a className="hover:text-blue-600 dark:hover:text-blue-400 hover:underline" href={Routes.Blog.PackageDeals}>Package Deals</a></li>
                            <li><a className="hover:text-blue-600 dark:hover:text-blue-400 hover:underline" href={Routes.Landing.Services}>Services</a></li>
                            <li><a className="hover:text-blue-600 dark:hover:text-blue-400 hover:underline" href={Routes.Landing.Legal}>Legal</a></li>
                            <li><a className="hover:text-blue-600 dark:hover:text-blue-400 hover:underline" href={Routes.Landing.SaleProcess}>Process</a></li>
                        </ul>
                    </nav>
                </div>

                <div className="">
                    <div className="mt-4 mb-2 font-medium xl:mb-4 text-gray-900 dark:text-gray-100">Subscribe to our Newsletter</div>
                    <div className="flex flex-col">
                        <div className="mb-4">
                            <input type="email" className="focus:outline-none mb-2 block h-14 w-full rounded-xl bg-gray-200 dark:bg-gray-700 px-4 sm:w-80 focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400" placeholder="Enter your email" />
                            <button className="block rounded-full bg-blue-600 dark:bg-blue-500 px-6 py-3 font-medium text-white dark:text-gray-900">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-100 dark:bg-gray-900">
                <div className="mx-auto flex max-w-screen-xl flex-col gap-y-4 px-4 py-3 text-center text-gray-500 dark:text-gray-400 sm:flex-row sm:justify-between sm:text-left">
                    <div className="">© 2024 Scapcher LLC | All Rights Reserved</div>
                    <div className="">
                        <a className="hover:text-blue-600 dark:hover:text-blue-400" href={Routes.Landing.Privacy}>Privacy Policy</a>
                        <span> | </span>
                        <a className="hover:text-blue-600 dark:hover:text-blue-400" href={Routes.Landing.TermsOfService}>Terms of Service</a>
                        <span> | </span>
                        <a className="hover:text-blue-600 dark:hover:text-blue-400" href={Routes.Landing.Imprint}>Imprint</a>
                    </div>
                </div>
            </div>
        </footer>

    );
}

export default Footer;