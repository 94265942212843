import React from 'react';
import {FaChevronDown, FaChevronUp} from "react-icons/fa";

const AnalyticsProofs = ({ game, isAnalyticsSectionOpen, setIsAnalyticsSectionOpen, handleImageClick }) => {
    return (
        <div className="bg-white dark:bg-gray-900 border-2 border-gray-300 dark:border-gray-700 rounded-lg p-4 mt-6">
            <button
                className="mb-4 text-white font-semibold py-2 px-6 border border-transparent rounded-full shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 hover:from-purple-600 hover:via-pink-600 hover:to-red-600 flex items-center justify-center space-x-2 dark:focus:ring-indigo-300"
                onClick={() => setIsAnalyticsSectionOpen(!isAnalyticsSectionOpen)}
            >
        <span>
            {isAnalyticsSectionOpen ? 'Hide Analytics Proofs' : 'Show Analytics Proofs'}
        </span>
                {isAnalyticsSectionOpen ? <FaChevronDown className="w-4 h-4" /> : <FaChevronUp className="w-4 h-4" />}
            </button>

            {isAnalyticsSectionOpen && (
                <div className="grid grid-cols-2 gap-2">
                    {game.analyticsProofs.map((image, index) => (
                        <div key={index} className="overflow-hidden rounded-md py-2 cursor-pointer" onClick={() => handleImageClick(image)}>
                            <div className="text-center mb-2">
                                <p className="text-sm font-semibold text-gray-700 dark:text-gray-300">Analytics Proof {index + 1}</p>
                            </div>
                            <img src={image} alt={`Analytics Image ${index + 1}`} className="h-48 object-cover object-center w-auto rounded-md border border-gray-300 dark:border-gray-700" />
                        </div>
                    ))}
                </div>
            )}
        </div>

    );
};

export default AnalyticsProofs;
