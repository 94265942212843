import React, {useCallback, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";

/* Services */
import {GameService} from "../../../services";

/* Routes */
import Routes from "../../../routes";

import LoadingElement from "../../general/Loading/LoadingElement";
import parseDeveloper from "./utilities/parseDeveloper.utility";
import {formatPrice} from "../../../utilities";
import FormMetadata from "../publish/steps/data/form_metadata.json";
import {Currencies} from "../../../enums";
import GameInfoCard from "./components/GameInfoCard";

import ReactQuill from "react-quill";
import he from "he";
import Select from "react-select";

const genreOptions = [
    { value: 'idle', label: 'Idle' },
    { value: 'action', label: 'Action' },
    { value: 'adventure', label: 'Adventure' },
    { value: 'arcade', label: 'Arcade' },
    { value: 'board', label: 'Board' },
    { value: 'card', label: 'Card' },
    { value: 'casino', label: 'Casino' },
    { value: 'casual', label: 'Casual' },
    { value: 'educational', label: 'Educational' },
    { value: 'puzzle', label: 'Puzzle' },
    { value: 'racing', label: 'Racing' },
    { value: 'role-playing', label: 'Role-Playing' },
    { value: 'simulation', label: 'Simulation' },
    { value: 'sports', label: 'Sports' },
    { value: 'strategy', label: 'Strategy' },
    { value: 'trivia', label: 'Trivia' },
    { value: 'runner', label: 'Runner' },
];

const GameEdit = () => {
    /* Initialize navigation hook */
    const navigate = useNavigate();

    const {id} = useParams(); /* get the id of the game from the url */

    const [game, setGame] = useState();
    const [developer, setDeveloper] = useState();

    const [loading, setLoading] = useState( true );
    const [error, setError] = useState();

    // Initial state for analyticsProofs
    const [analyticsProofs, setAnalyticsProofs] = useState(new Array(6).fill(''));
    const [isOpenAnalytics, setIsOpenAnalytics] = useState(false); // State to handle collapsible behavior

    // Initial state for revenueProofs
    const [revenueProofs, setRevenueProofs] = useState(new Array(6).fill(''));
    const [isOpenRevenue, setIsOpenRevenue] = useState(false); // State to handle collapsible behavior

    const [customText, setCustomText] = useState('');

    // State for controlling the alert
    const [showAlert, setShowAlert] = useState(false);

    const [selectedOption, setSelectedOption] = useState(null);

    const catchError = useCallback( (error) => {
        /* get the error code from the error object */
        const code = error?.response?.status;

        switch ( code ) {
            case 404:
                /* if the game is not found, redirect to the not found page */
                navigate( Routes.Errors.NotFound );
                break;
            case 500:
                /* if the server is down, redirect to the server error page */
                navigate( Routes.Errors.ServerError );
                break;
            default:
                /* if the error has no code, redirect to the not found page */
                /* render the error */
                setError( error );
        }
    }, [navigate]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await GameService.fetchGame(id);
                setGame(response);
                setLoading(false);
                console.log(response);
                console.log(id);
                const developerData = parseDeveloper(response);
                setDeveloper(developerData);
                console.log(developerData);

                // Check if analyticsProofs is null, undefined, or has a length of 0
                if (!response.analyticsProofs || response.analyticsProofs.length === 0) {
                    setAnalyticsProofs(new Array(6).fill(''));
                } else {
                    setAnalyticsProofs(response.analyticsProofs);
                }

                if (!response.revenueProofs || response.revenueProofs.length === 0) {
                    setRevenueProofs(new Array(6).fill(''));
                } else {
                    setRevenueProofs(response.revenueProofs);
                }

                setLoading(false);
            } catch (error) {
                catchError(error);
            }
        }

        fetchData();
    }, [catchError, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setGame((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
    };

    // Function to update specific analytics proof based on index
    const handleInputChangeAnalytics = (index, value) => {
        const updatedProofs = [...analyticsProofs];
        updatedProofs[index] = value;
        setAnalyticsProofs(updatedProofs);
        setGame({...game, analyticsProofs: [...updatedProofs]});
    };

    // Function to update specific revenue proof based on index
    const handleInputChangeRevenue = (index, value) => {
        const updatedProofs = [...revenueProofs];
        updatedProofs[index] = value;
        setRevenueProofs(updatedProofs);

        setGame({...game, revenueProofs: [...updatedProofs]});
    };

    const handlePriceChange = (event) => {
        const price = event.target?.value;
        setGame({...game, price: {...game.price, value: price}});
    }

    const handlePriceChangeForMonthlyProfit = (event) => {
        const value = event?.target?.value;
        setGame({...game, monthly_profit: {...game.monthly_profit, value: value}});
    }

    const handlePriceChangeForTotalProfit = (event) => {
        const value = event?.target?.value;
        setGame({...game, total_profit: {...game.total_profit, value: value}});
    }

    const handleCurrencyChangeForMonthlyProfit = (event) => {
        const value = event?.target?.value;
        setGame({...game, monthly_profit: {...game.monthly_profit, currency: value}});
    }

    const handleUpdate = async () => {
        try {
            console.log({id, game});
            const response = await GameService.updateListing(id, game);
            console.log(response);
            setShowAlert(true);
        }
        catch (error) {
            console.log(error);
        }
    }

    if(loading) {
        return <div className="mt-36 mb-36">
            <LoadingElement/>
        </div>;
    }

    const handleGameDescriptionChange = (value) => {
        setCustomText(value);
        setGame({...game, "publisher_description": customText});
        console.log(value);
    };

    const handleGameTypeChange = (type) => {
        setGame(prevState => ({
            ...prevState,
            gameType: type
        }));
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setGame(prevDetails => ({
            ...prevDetails,
            genre: selectedOption.value
        }));
    };

    // const htmlString = he.decode(game.publisher_description);

    return (
        <div className="relative">
            {/* GAME EDIT*/}
            <div className="App max-w-4xl mx-auto p-4">
                <GameInfoCard game={game} />
                <div className="container p-6">
                    <h1 className="text-2xl font-bold mb-4">Edit Listing</h1>

                    <div className="flex justify-between mb-4">
                        <button type="button" className={`px-6 py-2 font-medium tracking-wide capitalize transition-colors duration-300 transform rounded-lg focus:outline-none ${game.gameType === 'simple' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'}`} onClick={() => handleGameTypeChange('simple')}>
                            Normal Game
                        </button>
                        <button type="button" className={`px-6 py-2 font-medium tracking-wide capitalize transition-colors duration-300 transform rounded-lg focus:outline-none ${game.gameType === 'premium' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'}`} onClick={() => handleGameTypeChange('premium')}>
                            Premium Game
                        </button>
                        <button type="button" className={`px-6 py-2 font-medium tracking-wide capitalize transition-colors duration-300 transform rounded-lg focus:outline-none ${game.gameType === 'exclusive' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'}`} onClick={() => handleGameTypeChange('exclusive')}>
                            Exclusive Game
                        </button>
                        <button type="button" className={`px-6 py-2 font-medium tracking-wide capitalize transition-colors duration-300 transform rounded-lg focus:outline-none ${game.gameType === 'package' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'}`} onClick={() => handleGameTypeChange('package')}>
                            Package Game
                        </button>
                    </div>

                    <Select
                        value={selectedOption}
                        onChange={handleSelectChange}
                        options={genreOptions}
                    />

                    {/* Game Details Section */}
                    <section className="mb-8 mt-12">
                        <h2 className="text-xl font-semibold mb-3">Game Price</h2>
                        <div className="space-y-4">

                            {/* Listing Price */}
                            <div className="relative mt-1 rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <span className="text-gray-500 sm:text-sm">{Currencies[game.price?.currency]?.symbol || "$"}</span>
                                </div>
                                <input type="text" name="price_value" id="price"
                                       className="form_monetary_input"
                                       value={game?.price?.value || ""}
                                       onChange={handlePriceChange}
                                       placeholder={FormMetadata.game_description.fields.price.placeholder} />
                                <div className="absolute inset-y-0 right-0 flex items-center">
                                    <label htmlFor="currency" className="sr-only">Currency</label>
                                    <select id="currency"
                                            name="price_currency"
                                            value={game.price?.currency || "USD"}
                                            className="form_currency">
                                        {
                                            Object
                                                .keys(Currencies)
                                                .map((key) =>
                                                    (<option key={key} value={Currencies[key]?.code}>{Currencies[key]?.code}</option>))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="mt-8 space-y-4">
                                <div className="">
                                    <label htmlFor="listing_subtitle" className="block text-md font-medium">Listing Subtitle</label>
                                    <input
                                        onChange={handleChange}
                                        value={game["listing_subtitle"] || ""}
                                        name="listing_subtitle"
                                        placeholder="Listing Subtitle"
                                        className="form_input">
                                    </input>
                                </div>
                            </div>

                            <div className="mt-8 space-y-4">
                                <div className="">
                                    <label htmlFor="video_url" className="block text-md font-medium">Video URL</label>
                                    <input
                                        onChange={handleChange}
                                        value={game["video_url"] || ""}
                                        name="video_url"
                                        placeholder="video url"
                                        className="form_input">
                                    </input>
                                </div>
                            </div>

                            {/* Listing Description */}
                            <div className="">
                                <label htmlFor="listingDescription" className="block text-md font-medium">Listing Description</label>
                                <ReactQuill className="" theme="snow" value={customText} onChange={handleGameDescriptionChange} />

                                {/* Render parsed HTML here */}
                                <div className="w-full h-full form_input">
                                    <label htmlFor="message" className="form_label">RECENT DESCRIPTION</label>
                                    <p className="form_field_description">Copy and paste it to editor input.</p>

                                    {/*HTML STRING*/}
                                    <div/>

                                </div>

                            </div>

                            {/* Total Downloads */}
                            <div className="form_field_container">
                                <label htmlFor="message" className="form_label">Total Downloads</label>
                                <p className="form_field_description">Enter the total number of downloads your game has received since its release.</p>
                                <input
                                    onChange={handleChange}
                                    value={game["total_downloads"] || ""}
                                    name="total_downloads"
                                    placeholder="Total Downloads"
                                    className="form_input"
                                />
                            </div>

                            {/* Monthly Downloads */}
                            <div className="form_field_container">
                                <label htmlFor="message" className="form_label">Monthly Downloads</label>
                                <p className="form_field_description">Provide the number of downloads your game has received in the past month.</p>
                                <input
                                    onChange={handleChange}
                                    value={game["monthly_downloads"] || ""}
                                    name="monthly_downloads"
                                    placeholder="Monthly Downloads"
                                    className="form_input"
                                />
                            </div>

                            <div className="my-4">
                                {/* Collapsible toggle button */}
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                    onClick={() => setIsOpenAnalytics(!isOpenAnalytics)}
                                >
                                    {isOpenAnalytics ? 'Hide Analytics Proofs' : 'Show Analytics Proofs'}
                                </button>

                                {/* Collapsible div */}
                                {isOpenAnalytics && (
                                    <div className="w-full h-full mt-4 bg-red-100 p-4 rounded">
                                        {analyticsProofs.map((proof, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                placeholder={`Analytics Proof #${index + 1}`}
                                                value={proof}
                                                onChange={(e) => handleInputChangeAnalytics(index, e.target.value)}
                                                className="form_monetary_input"
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>

                        </div>
                    </section>

                    {/* Monetization Section */}
                    <section>
                        <h2 className="text-xl font-semibold mb-3">Monetization</h2>
                        <div className="space-y-4">

                            {/* Monthly Profit */}
                            <div className="form_field_container">

                                <label htmlFor="message"
                                       className="form_label">{FormMetadata.monetization_details.fields.monthly_profit.title}
                                </label>

                                <p
                                    className="form_field_description">{FormMetadata.monetization_details.fields.monthly_profit.brief_description}
                                </p>

                                <div className="relative mt-1 rounded-md shadow-sm">

                                    <div className="form_currency_symbol_container">
                                        <span className="form_currency_symbol">{Currencies[game.monthly_profit?.currency]?.symbol || "$"}</span>
                                    </div>

                                    <input type="text" name="monthly_profit" id="value"
                                           value={game?.monthly_profit?.value || ""}
                                           onChange={handlePriceChangeForMonthlyProfit}
                                           className="form_monetary_input"
                                           placeholder={FormMetadata.monetization_details.fields.monthly_profit.placeholder}/>

                                    <div className="absolute inset-y-0 right-0 flex items-center">

                                        <label htmlFor="currency" className="sr-only">
                                            Currency
                                        </label>

                                        <select id="currency"
                                                name="monthly_profit"
                                                onChange={handleCurrencyChangeForMonthlyProfit}
                                                value={game?.monthly_profit?.currency || "USD"}
                                                className="form_currency">
                                            {
                                                Object
                                                    .keys(Currencies)
                                                    .map((key) =>
                                                        (<option key={key} value={Currencies[key]?.code}>{Currencies[key]?.code}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Total Profit */}
                            <div className="form_field_container">
                                <label htmlFor="message"
                                       className="form_label">{FormMetadata.monetization_details.fields.total_profit.title}</label>
                                <p
                                    className="form_field_description">{FormMetadata.monetization_details.fields.total_profit.brief_description}</p>
                                <div className="relative mt-1 rounded-md shadow-sm">
                                    <div className="form_currency_symbol_container">
                                        <span className="form_currency_symbol">{Currencies[game.total_profit?.currency]?.symbol || "$"}</span>
                                    </div>
                                    <input type="text"
                                           name="total_profit"
                                           id="value"
                                           onChange={handlePriceChangeForTotalProfit}
                                           value={game?.total_profit?.value || ""}
                                           className="form_monetary_input"
                                           placeholder={FormMetadata.monetization_details.fields.total_profit.placeholder}/>
                                    <div className="absolute inset-y-0 right-0 flex items-center">
                                        <label htmlFor="currency" className="sr-only">Currency</label>
                                        <select id="currency"
                                                name="total_profit"
                                                value={game?.total_profit?.currency || "USD"}
                                                className="form_currency">
                                            {
                                                Object
                                                    .keys(Currencies)
                                                    .map((key) =>
                                                        (<option key={key} value={Currencies[key]?.code}>{Currencies[key]?.code}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="my-4">
                                {/* Collapsible toggle button */}
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                    onClick={() => setIsOpenRevenue(!isOpenRevenue)}
                                >
                                    {isOpenRevenue ? 'Hide Revenue Proofs' : 'Show Revenue Proofs'}
                                </button>

                                {/* Collapsible div */}
                                {isOpenRevenue && (
                                    <div className="mt-4 bg-blue-100 p-4 rounded">
                                        {revenueProofs.map((proof, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                placeholder={`Revenue Proof #${index + 1}`}
                                                value={proof}
                                                onChange={(e) => handleInputChangeRevenue(index, e.target.value)}
                                                className="form_monetary_input"
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>

                        </div>
                    </section>

                    {/* Update Listing Button */}
                    <div className="mt-8">
                        <button onClick={handleUpdate} type="submit" className="px-4 py-2 bg-green-500 text-white rounded hover:bg-blue-700">Update Listing</button>
                    </div>
                </div>
            </div>

            {/* Overlay for blurry background */}
            {showAlert && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm z-10"></div>
            )}

            {/* Alert */}
            {showAlert && (
                <div role="alert" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-xl border border-gray-100 bg-white p-4 z-20">
                    <div className="flex items-start gap-4">
                    <span className="text-green-600">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="h-6 w-6"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </span>

                        <div className="flex-1">
                            <strong className="block font-medium text-gray-900"> Changes saved </strong>

                            <p className="mt-1 text-sm text-gray-700">Your product changes have been saved.</p>

                            <div className="mt-4 flex gap-2">
                                <Link
                                    to={Routes.Games.Details.replace(":id", game?._id)}
                                    className="inline-flex items-center gap-2 rounded-lg bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700"
                                >
                                    <span className="text-sm"> Preview </span>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        className="h-4 w-4"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                        />
                                    </svg>
                                </Link>

                                <Link to={Routes.Admin.ApprovedGames} className="block rounded-lg px-4 py-2 text-gray-700 transition hover:bg-gray-50">
                                    <span className="text-sm">Edit Other Games</span>
                                </Link>
                            </div>
                        </div>

                        <button onClick={() => setShowAlert(false)} className="text-gray-500 transition hover:text-gray-600">
                            <span className="sr-only">Dismiss popup</span>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default GameEdit;

// <div dangerouslySetInnerHTML={{ __html: htmlString }} />
