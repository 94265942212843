
/* This component is used to render the loading state for the game details preview component */
const GameDetailsPreviewLoading = ( { loading } ) => {
    return (
        <div className={`max-w-xs rounded-lg bg-white shadow-lg dark:bg-neutral-700 ${loading && "animate-pulse"}`}
             aria-hidden="true">
            <div className="h-80 w-80 rounded-t bg-gray-700"></div>
            <div className="p-6">
                <h5 className="mb-2 text-xl font-medium text-neutral-900 dark:text-white">
                    <span
                        className="inline-block min-h-[1em] w-6/12 flex-auto cursor-wait bg-current align-middle opacity-50"></span>
                </h5>
                <p className={`mb-4 ${loading && "animate-pulse"} text-base text-neutral-700 dark:text-white`}>
                    <span
                        className="inline-block min-h-[1em] w-7/12 flex-auto cursor-wait bg-current align-middle opacity-50"></span>
                    <span
                        className="inline-block min-h-[1em] w-4/12 flex-auto cursor-wait bg-current align-middle opacity-50"></span>
                    <span
                        className="inline-block min-h-[1em] w-4/12 flex-auto cursor-wait bg-current align-middle opacity-50"></span>
                    <span
                        className="inline-block min-h-[1em] w-6/12 flex-auto cursor-wait bg-current align-middle opacity-50"></span>
                    <span
                        className="inline-block min-h-[1em] w-8/12 flex-auto cursor-wait bg-current align-middle opacity-50"></span>
                </p>
            </div>
        </div>
    );
}

GameDetailsPreviewLoading.defaultProps = {
    loading: false
}

export default GameDetailsPreviewLoading;