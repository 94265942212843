import { useState } from "react";
/* Contexts */
import { useGamePublishContext } from "../contexts";
/* Services */
import { GameService } from "../../../../services";
import isEmpty from "lodash.isempty";
/* Styles */
import "./styles/game_link_styles.css"
import "./styles/form_styles.css"
/* Metadata */
import FormMetadata from "./data/form_metadata";

const GameLink = () => {

    const { gameForPublish } = useGamePublishContext();
    const [ game, setGame ] = gameForPublish;

    const { gameLinkVerification } = useGamePublishContext();
    const [ isGameLinkVerified, setIsGameLinkVerified ] = gameLinkVerification;

    const { gameLinkLoading } = useGamePublishContext();
    const [ isGameLinkLoading, setIsGameLinkLoading ] = gameLinkLoading;

    const { gameDetailsFromStoreState } = useGamePublishContext();
    const [ gameDetailsFromStore, setGameDetailsFromStore ] = gameDetailsFromStoreState;

    const [ gameLinkFetchError, setGameLinkFetchError ] = useState(undefined);

    const handleChange = (event) => {

        const { name, value } = event.target;

        setGame({ ...game, [name]: value });

        /* right after the user changes the URL, the verification status is reset */
        setIsGameLinkVerified(false);
    };


    const handleLinkVerification = async () => {

        setIsGameLinkLoading(true);
        setGameDetailsFromStore(undefined);
        /* when the details are loading, there is no details to show */

        try {
            const gameDetails = await GameService.fetchDetailsFromStore(game?.url);

            setGameDetailsFromStore(gameDetails);
            setIsGameLinkVerified(true); /* if the details are fetched, the link is verified */
            setGameLinkFetchError(undefined); /* if the details are fetched, there is no error */
        }
        catch (error) {
            /* link verification failed */
            setIsGameLinkVerified(false); /* if the details are not fetched, the link is not verified */
            setGameLinkFetchError(error?.message); /* if the details are not fetched, there is an error */
        }
        setIsGameLinkLoading(false); /* either way, the loading is done */
    };

    return (
        <div className="flex flex-col">
            {/* Step Title */}
            <h1 className="form_step_title">{FormMetadata.game_link.step_title}</h1>
            {/* Step Description */}
            <div className="form_step_description">
                {FormMetadata.game_link.step_description}
            </div>
            {/* Game URL */}
            <div className="form_field_container">
                <div className="form_label">
                    {FormMetadata.game_link.fields.url.title}
                </div>
                <div className="flex flex-row">
                    <div className="w-full">
                    <input
                        onChange ={handleChange}
                        value = { game["url"] || "" }
                        name = "url"
                        placeholder = {FormMetadata.game_link.fields.url.placeholder}
                        className = {`${isGameLinkVerified ? "form_valid_input" : "form_input"}`}/>
                        {
                            gameLinkFetchError &&
                                <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span
                                    className="font-medium mr-2">{gameLinkFetchError}</span></p>
                        }
                    </div>
                    <button onClick = {handleLinkVerification}
                            disabled = {isGameLinkVerified || isEmpty(game?.url)}
                            className = {`${(isGameLinkVerified || isEmpty(game?.url)) ? "verify_link_button_disabled": "verify_link_button_enabled"} mt-3`}>
                        ✓
                    </button>
                </div>
            </div>
        </div>
    );
}

export default GameLink;