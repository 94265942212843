import React, { useState, useEffect } from 'react';
import DashboardAccount from "./sections/DashboardAccount";
import DashboardAcquisitions from "./sections/DashboardAcquisitions";
import DashboardBilling from "./sections/DashboardBilling";
import DashboardNotifications from "./sections/DashboardNotifications";
import {AuthenticationService} from "../services";
import UserService from "../services/user.service";

const Dashboard = () => {

    // State to keep track of active content
    const [activeContent, setActiveContent] = useState('acquisitions'); // Default to 'account'

    const [user, setUser] = useState();

    useEffect(() => {
        const userID = AuthenticationService.getUser().id;
        async function fetchData() {
            try {
                const response = await UserService.fetchUser(userID);
                if(!user)
                    setUser(response);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    });

    // Function to handle click on navbar buttons
    const handleClick = (content) => {
        setActiveContent(content);
    };

    return (
        <div>
            <div className="mx-4 min-h-screen max-w-screen-xl sm:mx-8 xl:mx-auto">
                <h1 className="border-b py-6 text-4xl font-semibold dark:text-white">Scapcher Dashboard</h1>
                <div className="grid grid-cols-8 pt-3 sm:grid-cols-10">
                    <div className="relative my-4 w-56 sm:hidden">
                        <input className="peer hidden" type="checkbox" name="select-1" id="select-1" />

                        <label htmlFor="select-1" className="flex w-full cursor-pointer select-none rounded-lg border p-2 px-3 text-sm text-gray-700 ring-blue-700 peer-checked:ring">
                            Accounts
                        </label>

                        <svg xmlns="http://www.w3.org/2000/svg" className="pointer-events-none absolute right-0 top-3 ml-auto mr-5 h-4 text-slate-700 transition peer-checked:rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>

                        <ul className="max-h-0 select-none flex-col dark:text-white overflow-hidden rounded-b-lg shadow-md transition-all duration-300 peer-checked:max-h-56 peer-checked:py-3">
                            <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white"
                                onClick={() => handleClick('acquisitions')}>
                                My Acquisitions
                            </li>
                            <li className="cursor-pointer px-3 py-2 text-sm dark:text-white text-slate-600 hover:bg-blue-700 hover:text-white"
                                onClick={() => handleClick('account')}>
                                Account
                            </li>
                            <li className="cursor-pointer px-3 py-2 text-sm dark:text-white text-slate-600 hover:bg-blue-700 hover:text-white"
                                onClick={() => handleClick('billing')}>
                                Billing
                            </li>
                            <li className="cursor-pointer px-3 py-2 text-sm dark:text-white text-slate-600 hover:bg-blue-700 hover:text-white"
                                onClick={() => handleClick('Settings')}>
                                Settings
                            </li>

                        </ul>

                    </div>

                    <div className="col-span-2 hidden sm:block">
                        <ul>
                            {/* My Acquisitions Button */}
                            <li
                                className={`mt-5 cursor-pointer dark:text-white border-l-2 px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700 ${
                                    activeContent === 'acquisitions' ? 'border-l-blue-700 text-blue-700' : 'border-transparent'
                                }`}
                                onClick={() => handleClick('acquisitions')}
                            >
                                My Acquisitions
                            </li>

                            {/* Account Button */}
                            <li
                                className={`mt-5 cursor-pointer border-l-2 dark:text-white px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700 ${
                                    activeContent === 'account' ? 'border-l-blue-700 text-blue-700' : 'border-transparent'
                                }`}
                                onClick={() => handleClick('account')}
                            >
                                Account
                            </li>

                            {/* Billing Button */}
                            <li
                                className={`mt-5 cursor-pointer border-l-2 px-2 dark:text-white py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700 ${
                                    activeContent === 'billing' ? 'border-l-blue-700 text-blue-700' : 'border-transparent'
                                }`}
                                onClick={() => handleClick('billing')}
                            >
                                Billing
                            </li>

                            {/* Settings Button */}
                            <li
                                className={`mt-5 cursor-pointer border-l-2 px-2 dark:text-white py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700 ${
                                    activeContent === 'Settings' ? 'border-l-blue-700 text-blue-700' : 'border-transparent'
                                }`}
                                onClick={() => handleClick('Settings')}
                            >
                                Settings
                            </li>

                        </ul>
                    </div>

                    {/* CONTENT HERE */}
                    {/* ACCOUNT SETTINGS - CONTENT HERE */}
                    {/* Render content based on activeContent */}

                    {/* Add other content components similarly */}
                    {activeContent === 'account' && <DashboardAccount />}
                    {/* Add other content components similarly */}
                    {activeContent === 'acquisitions' && <DashboardAcquisitions user={user}/>}
                    {/* Add other content components similarly */}
                    {activeContent === 'billing' && <DashboardBilling />}
                    {/* Add other content components similarly */}
                    {activeContent === 'Settings' && <DashboardNotifications />}

                </div>
            </div>

        </div>
    );
};

export default Dashboard;
