import React, { useState, useEffect } from 'react';
import {AuthenticationService} from "../../services";
import UserService from "../../services/user.service";
import {Link} from "react-router-dom";
import Routes from "../../routes";
import BlogPublish from "../publish";
import BlogUpdate from "../update";
import PublishedBlogs from "../published-blogs";

const BlogsLayout = () => {
    const [activeContent, setActiveContent] = useState('blogs');
    const [user, setUser] = useState();

    useEffect(() => {
        const userID = AuthenticationService.getUser().id;
        async function fetchData() {
            try {
                const response = await UserService.fetchUser(userID);
                if(!user)
                    setUser(response);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    });

    const handleClick = (content) => {
        setActiveContent(content);
    };

    return (
        <div>
            <div className="mx-4 min-h-screen max-w-screen-xl sm:mx-8 xl:mx-auto">

                <h1 className="border-b py-6 text-4xl font-semibold">Scapcher Blogs</h1>

                <div className="grid grid-cols-8 pt-3 sm:grid-cols-10">

                    <div className="relative my-4 w-56 sm:hidden">
                        <input className="peer hidden" type="checkbox" name="select-1" id="select-1" />

                        <label htmlFor="select-1" className="flex w-full cursor-pointer select-none rounded-lg border p-2 px-3 text-sm text-gray-700 ring-blue-700 peer-checked:ring">
                            {activeContent}
                        </label>

                        <svg xmlns="http://www.w3.org/2000/svg" className="pointer-events-none absolute right-0 top-3 ml-auto mr-5 h-4 text-slate-700 transition peer-checked:rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>

                        <ul className="max-h-0 select-none flex-col overflow-hidden rounded-b-lg shadow-md transition-all duration-300 peer-checked:max-h-56 peer-checked:py-3">
                            <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white"
                                onClick={() => handleClick('new-blog')}>
                                New Blog
                            </li>
                            <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white"
                                onClick={() => handleClick('edit-blog')}>
                                Edit Blog
                            </li>
                            <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white"
                                onClick={() => handleClick('blogs')}>
                                Blogs
                            </li>

                            <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white">
                                Exclusive Blogs
                                <Link to={Routes.Blog.Blogs} className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white">
                                    Exclusive Blogs
                                </Link>
                            </li>

                        </ul>

                    </div>

                    <div className="col-span-2 hidden sm:block">
                        <ul>
                            {/* New Blog Button */}
                            <li
                                className={`mt-5 cursor-pointer border-l-2 px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700 ${
                                    activeContent === 'new-blog' ? 'border-l-blue-700 text-blue-700' : 'border-transparent'
                                }`}
                                onClick={() => handleClick('new-blog')}
                            >
                                New Blog
                            </li>

                            {/* Blogs Button */}
                            <li
                                className={`mt-5 cursor-pointer border-l-2 px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700 ${
                                    activeContent === 'blogs' ? 'border-l-blue-700 text-blue-700' : 'border-transparent'
                                }`}
                                onClick={() => handleClick('blogs')}
                            >
                                Blogs
                            </li>

                        </ul>
                    </div>

                    {/* CONTENT HERE */}
                    {/* ACCOUNT SETTINGS - CONTENT HERE */}
                    {/* Render content based on activeContent */}

                    {/* Add other content components similarly */}
                    {activeContent === 'new-blog' && <BlogPublish />}
                    {/* Add other content components similarly */}
                    {activeContent === 'edit-blog' && <BlogUpdate />}
                    {/* Add other content components similarly */}
                    {activeContent === 'blogs' && <PublishedBlogs />}

                </div>

            </div>

        </div>
    );
};

export default BlogsLayout;
