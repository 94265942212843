// src/FirebaseContext.js
import React, { createContext, useContext } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Firebase konfigürasyonunuz
const firebaseConfig = {
    apiKey: "AIzaSyCNmjVxu3Ksrye-D_mLGOTm03lYkUy8vn4",
    authDomain: "scapcher-client.firebaseapp.com",
    projectId: "scapcher-client",
    storageBucket: "scapcher-client.appspot.com",
    messagingSenderId: "576582100474",
    appId: "1:576582100474:web:d81f0e58de1a5ba57af043",
    measurementId: "G-TZPHPZ68YX"
};

// Firebase uygulamasını başlat
const app = initializeApp(firebaseConfig);

// Firebase Analytics nesnesini al
const analytics = getAnalytics(app);

// Firebase Context oluştur
const FirebaseContext = createContext();

// Firebase sağlayıcı componenti
export const FirebaseProvider = ({ children }) => {
    return (
        <FirebaseContext.Provider value={analytics}>
            {children}
        </FirebaseContext.Provider>
    );
};

// Firebase Context'ini kullanmak için bir hook
export const useFirebase = () => useContext(FirebaseContext);