import { AuthenticationConstants } from '../constants';

import { AuthenticationService } from "../services";

const { LOGIN, REGISTER, LOGOUT } = AuthenticationConstants;

export const login = (email, password) => async(dispatch) => {
    /* this action creator logs in a user by connecting to the backend and sending the user's credentials */

    try {
        dispatch( { type: LOGIN.REQUEST } );

        const response = await AuthenticationService.login(email, password);

        dispatch( { type: LOGIN.SUCCESS, payload: response } );

    }
    catch (error) {
        /* when the authentication fails, we need to dispatch a failure action */
        dispatch( { type: LOGIN.FAILURE,
                    payload: error.response && error.response.data ? error.response.data.message : error.message } );
    }

}

export const register = (name, email, password) => async(dispatch) => {
    /* this action creator registers a user by connecting to the backend and sending the user's credentials */
    try {
        dispatch( { type: REGISTER.REQUEST } );

        const response = await AuthenticationService.register(name, email, password);

        dispatch( { type: REGISTER.SUCCESS, payload: response } );

    }
    catch (error) {
        /* when the registration fails, we need to dispatch a failure action */
        dispatch( { type: REGISTER.FAILURE,
                    payload: error.response && error.response.data ? error.response.data.message : error.message } );
    }

}

export const logout = () => async(dispatch) => {
    /* this action creator logs out a user by sending the backend a logout request and deleting the tokens from the local storage */

    try {
        dispatch( { type: LOGOUT.REQUEST } );

        await AuthenticationService.logout();

        dispatch( { type: LOGOUT.SUCCESS } );

    }
    catch (error) {
        /* when the logout fails, we need to dispatch a failure action */
        dispatch( { type: LOGOUT.FAILURE,
                    payload: error.response && error.response.data ? error.response.data.message : error.message } );
    }

}