import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Routes from "../../routes";
import {Avatar, Dropdown } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";

/* Services */
import { AuthenticationService, GameService } from "../../services";
import NavMobile from "./NavMobile";
import Announcement from "../announcement/Announcement";

import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { FaUser, FaTachometerAlt, FaCogs, FaGamepad, FaSteam, FaTag, FaBlog } from 'react-icons/fa';
import { FaChevronDown, FaGavel, FaBook, FaStar, FaApple, FaGooglePlay, FaMobileAlt } from 'react-icons/fa';
import DarkModeToggle from "../DarkModeToggle";


const Navbar = () => {
    const [user, setUser] = useState({});
    const dispatch = useDispatch();

    const userLogoutState = useSelector(state => state.userLogout);
    const userLoginState = useSelector(state => state.userLogin);
    const { loading, error } = userLogoutState;

    const [fetchedUser, setFetchedUser] = useState();

    useEffect(() => {
        setUser(AuthenticationService.getUser());
        if (window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
            window.location.href = `https://${window.location.hostname}${window.location.pathname}`;
        }
    }, []);  // Remove unnecessary dependencies

    return (
        <div className="sticky top-0 z-50 bg-white dark:bg-gray-900 shadow dark:shadow-gray-700">
            {/* Mobile Navbar Component (hidden on md and larger screens) */}
            <div className="md:hidden">
                <NavMobile />
            </div>

            {/* Desktop Navbar Component (visible on md and larger screens) */}
            <div className="hidden md:block">
                <header className="bg-white dark:bg-gray-900">
                    <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-14 items-center justify-between">

                            {/* Logo and Home Link */}
                            <div className="md:flex md:items-center md:gap-12">
                                <DarkModeToggle/>
                                <a href="/" className="flex items-center whitespace-nowrap text-xl">
              <span className="mr-2 w-8">
                <img src="https://i.postimg.cc/3x7SQsK9/logo192.png" alt="" />
              </span>
                                    <span className="text-black dark:text-white font-bold">Scapcher.com</span>
                                </a>
                            </div>

                            {/* Navigation Links */}
                            <div className="">
                                <nav aria-label="Global">
                                    <ul className="flex items-center gap-6 text-sm font-medium text-gray-900 dark:text-gray-100">

                                        <li>
                                            <Link to={Routes.Games.Featured} className="flex flex-col items-center hover:text-blue-600 dark:hover:text-blue-400 transition">
                                                <span>Games</span>
                                                <span className="text-xs font-bold text-red-600 mt-1 underline dark:text-red-400">164 Listings 🚀</span>
                                            </Link>
                                        </li>

                                        <li className="relative">
                                            <Menu>
                                                {({ open }) => (
                                                    <>
                                                        <Menu.Button className="flex items-center text-black dark:text-white transition hover:text-blue-600 dark:hover:text-blue-400">
                                                            Categories
                                                            <FaChevronDown className="w-5 h-5 ml-1" />
                                                        </Menu.Button>
                                                        {open && (
                                                            <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 dark:ring-gray-700 focus:outline-none z-10">
                                                                <div className="py-1">
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <Link
                                                                                to={Routes.Games.AppStoreGames}
                                                                                className={`${
                                                                                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                                                                                } flex items-center gap-2 px-4 py-2 text-sm text-gray-700 dark:text-gray-300 transition-colors`}
                                                                            >
                                                                                <FaApple className="text-gray-800 dark:text-gray-400" />
                                                                                App Store
                                                                            </Link>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <Link
                                                                                to={Routes.Games.PlayStoreGames}
                                                                                className={`${
                                                                                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                                                                                } flex items-center gap-2 px-4 py-2 text-sm text-gray-700 dark:text-gray-300 transition-colors`}
                                                                            >
                                                                                <FaGooglePlay className="text-green-500" />
                                                                                Play Store
                                                                            </Link>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <Link
                                                                                to={"/games/idle"}
                                                                                className={`${
                                                                                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                                                                                } flex items-center gap-2 px-4 py-2 text-sm text-gray-700 dark:text-gray-300 transition-colors`}
                                                                            >
                                                                                <FaMobileAlt className="text-blue-500 dark:text-blue-400" />
                                                                                Idle Games
                                                                            </Link>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <Link
                                                                                to={"/games/casual"}
                                                                                className={`${
                                                                                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                                                                                } flex items-center gap-2 px-4 py-2 text-sm text-gray-700 dark:text-gray-300 transition-colors`}
                                                                            >
                                                                                <FaMobileAlt className="text-yellow-500 dark:text-yellow-400" />
                                                                                Casual Games
                                                                            </Link>
                                                                        )}
                                                                    </Menu.Item>
                                                                </div>
                                                            </Menu.Items>
                                                        )}
                                                    </>
                                                )}
                                            </Menu>
                                        </li>

                                        <li>
                                            <Link to={Routes.Landing.Legal} className="flex items-center gap-1 hover:text-blue-600 dark:hover:text-blue-400 transition">
                                                Legal
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to={Routes.Blog.Blogs} className="flex items-center gap-1 hover:text-blue-600 dark:hover:text-blue-400 transition">
                                                Blogs
                                            </Link>
                                        </li>

                                        {/* DROPDOWN */}
                                        <li className="relative">
                                            <Menu>
                                                {({ open }) => (
                                                    <>
                                                        <Menu.Button className="flex items-center text-black dark:text-white transition hover:text-blue-600 dark:hover:text-blue-400">
                                                            For Exclusive Members
                                                            <FaChevronDown className="w-5 h-5 ml-1" />
                                                        </Menu.Button>
                                                        {open && (
                                                            <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 dark:ring-gray-700 focus:outline-none z-10">
                                                                <div className="py-1">
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <Link
                                                                                to={Routes.Games.ExclusiveGames}
                                                                                className={`${
                                                                                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                                                                                } flex items-center gap-2 px-4 py-2 text-sm text-gray-700 dark:text-gray-300 transition-colors`}
                                                                            >
                                                                                <FaStar className="text-yellow-500 dark:text-yellow-400" />
                                                                                M&A Advisor's Choice
                                                                            </Link>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <Link
                                                                                to={Routes.Steam.Games}
                                                                                className={`${
                                                                                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                                                                                } flex items-center gap-2 px-4 py-2 text-sm text-gray-700 dark:text-gray-300 transition-colors`}
                                                                            >
                                                                                <FaSteam className="text-blue-800 dark:text-blue-600" />
                                                                                Steam PC Games
                                                                            </Link>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <Link
                                                                                to={Routes.Blog.PackageDeals}
                                                                                className={`${
                                                                                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                                                                                } flex items-center gap-2 px-4 py-2 text-sm text-gray-700 dark:text-gray-300 transition-colors`}
                                                                            >
                                                                                <FaTag className="text-red-600 dark:text-red-400" />
                                                                                Special Offers
                                                                            </Link>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <Link
                                                                                to={Routes.Blog.ExclusiveBlogs}
                                                                                className={`${
                                                                                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                                                                                } flex items-center gap-2 px-4 py-2 text-sm text-gray-700 dark:text-gray-300 transition-colors`}
                                                                            >
                                                                                <FaBook className="text-purple-500 dark:text-purple-400" />
                                                                                Exclusive Blogs
                                                                            </Link>
                                                                        )}
                                                                    </Menu.Item>
                                                                </div>
                                                            </Menu.Items>
                                                        )}
                                                    </>
                                                )}
                                            </Menu>
                                        </li>

                                    </ul>
                                </nav>
                            </div>

                            <div className="flex items-center gap-4">
                                {!AuthenticationService.isAuthenticated() ? (
                                    <React.Fragment>
                                        <Link
                                            to={Routes.Authentication.Login}
                                            className="rounded-3xl bg-white border-2 border-blue-600 px-5 py-2.5 text-sm font-medium text-blue-600 shadow dark:border-blue-500 dark:text-blue-400 hover:bg-blue-700 hover:text-white dark:hover:bg-blue-600 transition-colors duration-300"
                                        >
                                            Login
                                        </Link>
                                        <Link
                                            to={Routes.Authentication.Register}
                                            className="rounded-3xl bg-blue-600 px-5 py-2.5 text-sm font-medium text-white shadow dark:bg-blue-500 hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors duration-300"
                                        >
                                            Register
                                        </Link>
                                    </React.Fragment>
                                ) : (
                                    <div>
                                        <Dropdown
                                            label={
                                                <Avatar className="ring-1 ring-gray-400 dark:ring-gray-700 rounded-full" alt="User settings" img={user?.profilePicture || "https://flowbite.com/docs/images/people/profile-picture-5.jpg"} rounded={true} />
                                            }
                                            arrowIcon={false}
                                            inline={true}
                                            className="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100 shadow-md rounded-lg"
                                        >
                                            <Dropdown.Item className="flex items-center space-x-2 hover:bg-gray-100 dark:hover:bg-gray-700" href={Routes.Authentication.Account}>
                                                <FaUser className="text-blue-500 dark:text-blue-400" />
                                                <Link to={Routes.Authentication.Account}>Account</Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item className="flex items-center space-x-2 hover:bg-gray-100 dark:hover:bg-gray-700" href={Routes.UserDashboard.Main}>
                                                <FaTachometerAlt className="text-green-500 dark:text-green-400" />
                                                <Link to={Routes.UserDashboard.Main}>Dashboard</Link>
                                            </Dropdown.Item>

                                            <hr className="my-2 border-gray-300 dark:border-gray-700" />

                                            {/* ADMIN BUTTON */}
                                            {user?.role === 'admin' && (
                                                <>
                                                    <Dropdown.Item className="flex items-center space-x-2 hover:bg-gray-100 dark:hover:bg-gray-700" href={Routes.Admin.Dashboard}>
                                                        <FaCogs className="text-red-500 dark:text-red-400" />
                                                        <Link to={Routes.Admin.Dashboard}>Admin</Link>
                                                    </Dropdown.Item>

                                                    <Dropdown.Item className="flex items-center space-x-2 hover:bg-gray-100 dark:hover:bg-gray-700" href={Routes.Games.Publish}>
                                                        <FaGamepad className="text-purple-500 dark:text-purple-400" />
                                                        <Link to={Routes.Games.Publish}>Mobile Game Publish</Link>
                                                    </Dropdown.Item>

                                                    <Dropdown.Item className="flex items-center space-x-2 hover:bg-gray-100 dark:hover:bg-gray-700" href={Routes.Steam.Publish}>
                                                        <FaSteam className="text-gray-800 dark:text-gray-400" />
                                                        <Link to={Routes.Steam.Publish}>Steam Game Publish</Link>
                                                    </Dropdown.Item>

                                                    <hr className="my-2 border-gray-300 dark:border-gray-700" />

                                                    <Dropdown.Item className="flex items-center space-x-2 hover:bg-gray-100 dark:hover:bg-gray-700" href={Routes.Blog.BlogsAdmin}>
                                                        <FaBlog className="text-orange-500 dark:text-orange-400" />
                                                        <Link to={Routes.Blog.BlogsAdmin}>Blogs</Link>
                                                    </Dropdown.Item>
                                                </>
                                            )}

                                            <Dropdown.Divider />
                                        </Dropdown>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </header>
            </div>

            <Announcement />

        </div>

    );
};

export default Navbar;
