import React from "react";

const PopupDefault = ({ isOpen, onClose, title, desc, icon, onApproveButton }) => {
    if (!isOpen) return null;

    return (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 z-20">
            <div className="rounded-2xl border border-blue-100 bg-white p-4 shadow-lg sm:p-6 lg:p-8" role="alert">
                <div className="flex items-center gap-4">
                <span className="shrink-0 rounded-full bg-blue-400 p-2 text-white">
                    <div className="h-4 w-4">
                        {icon}
                    </div>
                </span>

                    <p className="font-medium sm:text-lg">{title}</p>
                </div>

                <p className="mt-4 text-gray-500">
                    {desc}
                </p>

                <div className="mt-6 sm:flex sm:gap-4">
                    <button
                        onClick={onApproveButton}
                        className="inline-block w-full rounded-lg bg-blue-500 px-5 py-3 text-center text-sm font-semibold text-white sm:w-auto"
                    >
                        Okay
                    </button>

                    <button
                        onClick={onClose}
                        className="mt-2 inline-block w-full rounded-lg bg-gray-50 px-5 py-3 text-center text-sm font-semibold text-gray-500 sm:mt-0 sm:w-auto"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PopupDefault;