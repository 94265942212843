
const AuthenticationConstants = Object.freeze({
    LOGIN: {
        REQUEST: 'USER_LOGIN_REQUEST',
        SUCCESS: 'USER_LOGIN_SUCCESS',
        FAILURE: 'USER_LOGIN_FAILURE'
    },
    LOGOUT: {
        REQUEST: 'USER_LOGOUT_REQUEST',
        SUCCESS: 'USER_LOGOUT_SUCCESS',
        FAILURE: 'USER_LOGOUT_FAILURE'
    },
    REGISTER: {
        REQUEST: 'USER_REGISTER_REQUEST',
        SUCCESS: 'USER_REGISTER_SUCCESS',
        FAILURE: 'USER_REGISTER_FAILURE'
    }
});

export default AuthenticationConstants;