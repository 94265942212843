import React from 'react';
import { FaFileSignature } from 'react-icons/fa';
import OfferService from "../../services/offer.service";

const OfferLegalContractSection = ({ currentOffer, openModal, refreshAsPayment }) => {

    const handleUpdateStatus = async (status) => {
        try {
            const response = await OfferService.handleUpdateStatus(currentOffer?._id, status);
            refreshAsPayment();
            openModal();
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="App max-w-4xl mx-auto p-4">
            <div className="container mx-auto py-8">

                <div className="flex mb-8 w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                    <div className="flex items-center justify-center w-12 bg-emerald-500">
                        <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z" />
                        </svg>
                    </div>

                    <div className="px-4 py-2 -mx-3">
                        <div className="mx-3">
                            <span className="font-semibold text-emerald-500 dark:text-emerald-400">We sent you contract with DocuSign</span>
                            <p class="text-sm text-gray-600 dark:text-gray-200">You can sign the contract on DocuSign</p>
                        </div>
                    </div>
                </div>

                <h1 className="text-2xl mb-6">Legal Contract</h1>

                <p className="text-xl font-semibold mb-6">
                    Our legal department has prepared a legal contract detailing purchase details,
                    terms, and conditions for both parties. The broker has already signed the contract,
                    and your signature is pending.
                </p>

                <div className="mt-2 mb-8 mx-auto bg-white rounded-xl overflow-hidden shadow-md">
                    <div className="p-4">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <FaFileSignature className="h-16 w-16 text-blue-500 mr-4" />
                                <h2 className="text-lg font-semibold text-gray-800">DocuSign Legal Agreement</h2>
                            </div>
                            <button onClick={() => handleUpdateStatus('Payment')} className="px-4 py-2 text-white font-semibold rounded-3xl shadow-md bg-emerald-500 hover:bg-emerald-300 focus:outline-none focus:bg-green-600 transition-colors">
                                Proceed on DocuSign
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mt-8">
                    <h2>Legal Contract Preview</h2>
                    <iframe
                        title="Google Document Preview"
                        className="w-full h-screen"
                        src={`https://docs.google.com/document/d/${"1HAGUO1yLZYjlxmrrFsnQyJi3doWzAuGIEIhhh-wHSnE"}/preview`}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>

            </div>
        </div>
    );
};

export default OfferLegalContractSection;
