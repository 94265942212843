/*
    this file provides HTTP Brokers or Agents that know the URL of the main API and directly communicates with the backend
*/

import HTTPAgent from "./HTTPAgent";
/* Default Interceptors */
import { TheRequestInterceptor, TheResponseInterceptor } from "./interceptors";


const options = {
    requestInterceptors: [ new TheRequestInterceptor() ],
    responseInterceptors: [ new TheResponseInterceptor() ]
};

export const AuthBroker = new HTTPAgent(`${process.env.REACT_APP_BACKEND_ADRESS}/auth`, options);

export const GameBroker = new HTTPAgent(`${process.env.REACT_APP_BACKEND_ADRESS}/games`, options);

export const HTTPBroker = new HTTPAgent(`${process.env.REACT_APP_BACKEND_ADRESS}`, options);

export const OfferBroker = new HTTPAgent(`${process.env.REACT_APP_BACKEND_ADRESS}/offers`, options);

export const UserBroker = new HTTPAgent(`${process.env.REACT_APP_BACKEND_ADRESS}/users`, options);

export const BlogBroker = new HTTPAgent(`${process.env.REACT_APP_BACKEND_ADRESS}/blogs`, options);

export const SteamBroker = new HTTPAgent(`${process.env.REACT_APP_BACKEND_ADRESS}/steam`, options);

export const FomoBroker = new HTTPAgent(`${process.env.REACT_APP_BACKEND_ADRESS}/fomo`, options);
