import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import Routes from "../../routes";
import {ScapcherLogo} from '../../images/logo192.png';
/* Services */
import { AuthenticationService, GameService } from "../../services";
/* Action Creators */
import { AuthenticationActions } from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Dropdown, Spinner} from "flowbite-react";

const NavMobile = () => {
    const [user, setUser] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useDispatch();

    const userLogoutState = useSelector(state => state.userLogout);
    const userLoginState = useSelector(state => state.userLogin);
    const { loading, error } = userLogoutState;

    useEffect(() => {
        setUser(AuthenticationService.getUser());
    }, [userLoginState, userLogoutState]);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="w-full h-14 bg-blue-600">
            <div className="font-bold text-xl text-white text-center pt-4">
                <Link to={Routes.Root}>SCAPCHER</Link>
            </div>

            <div className="text-white relative">

                <div className="flex justify-start pl-4">

                </div>

                {/* Hamburger Icon Container with flex and justify-end classes */}
                <div className="text-white flex text-xs justify-end pr-4">
                    <button className="text-white text-xs" onClick={toggleMenu}>
                        <svg
                            className="text-white w-8 h-8 -mt-8"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16m-7 6h7"
                            ></path>
                        </svg>
                    </button>
                </div>

                {/* Menu Items */}
                {isOpen && (
                    <div className="absolute mt-4 w-4/5 right-0 bg-white shadow-xl rounded-lg">
                        <Link to="/"
                              className="block px-4 py-2 text-s text-black hover:bg-gray-100"
                        >
                            Home
                        </Link>
                        <Link to={Routes.Root}
                              className="block px-4 py-2 text-s text-blue-500 hover:bg-gray-100"
                        >
                            Marketplace
                        </Link>

                        {!AuthenticationService.isAuthenticated() ? (
                            <div>
                                <Link to={Routes.Authentication.Login} className="block px-4 py-2 text-s text-black hover:bg-gray-100">
                                    Login
                                </Link>
                                <Link to={Routes.Authentication.Register}
                                      className="block px-4 py-2 text-s text-black hover:bg-gray-100"
                                >
                                    Register
                                </Link>
                            </div>
                        ) : (
                            <div>
                                <Link to={Routes.Games.ExclusiveGames} className="block px-4 py-2 text-s text-black hover:bg-gray-100">
                                    M&A Advisor's Choice
                                </Link>

                                <Link to={Routes.Steam.Games} className="block px-4 py-2 text-s text-black hover:bg-gray-100">
                                    Steam Games
                                </Link>

                                <Link to={Routes.Landing.Legal} className="block px-4 py-2 text-s text-black hover:bg-gray-100">
                                    Legal
                                </Link>

                                <Link to={Routes.Authentication.Account} className="block px-4 py-2 text-s text-black hover:bg-gray-100">
                                    Account
                                </Link>

                                <Link to={Routes.UserDashboard.Main}
                                      className="block px-4 py-2 text-s text-emerald-500 hover:bg-emerald-300">
                                    Dashboard
                                </Link>

                                {/* Conditionally render Admin button */}
                                {user?.role === 'admin' && (
                                    <Link to={Routes.Admin.Dashboard}
                                          className="block px-4 py-2 text-s text-black hover:bg-gray-100"
                                    >
                                        Admin
                                    </Link>
                                )}

                                {/*
                                <div
                                    className="block px-4 py-2 text-s text-blue-600"
                                >
                                    {user?.name}
                                </div>

                                <div
                                    className="block px-4 py-2 text-s text-blue-600"
                                >
                                    {user?.email}
                                </div>

                                <button
                                    className="block px-4 py-2 text-s text-red-600 hover:text-red-300"
                                    onClick={() => dispatch(AuthenticationActions.logout())}>
                                    {loading && <Spinner size="sm" light={true} />}
                                    {error && <span className="text-red-400">{error}</span>}
                                    {!loading && !error && "Logout"}
                                </button>
                                */}

                            </div>
                        )}

                    </div>
                )}
            </div>
        </div>
    );
};

export default NavMobile;
