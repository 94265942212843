// DarkModeToggle.js

import React from 'react';
import { MdDarkMode, MdLightMode } from 'react-icons/md';

const DarkModeToggle = () => {
    const toggleDarkMode = () => {
        document.documentElement.classList.toggle('dark');
    };

    return (
        <button
            onClick={toggleDarkMode}
            className="p-3 bg-white dark:bg-gray-800 rounded-full shadow-md dark:shadow-lg backdrop-blur-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300 flex items-center justify-center"
            style={{
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
                border: '1px solid rgba(0, 0, 0, 0.1)'
            }}
        >
            <span className="sr-only">Toggle Dark Mode</span>
            {document.documentElement.classList.contains('dark') ? (
                <MdLightMode className="text-gray-200 dark:text-yellow-300" size={24} />
            ) : (
                <MdDarkMode className="text-gray-800 dark:text-blue-400" size={24} />
            )}
        </button>
    );
};

export default DarkModeToggle;
