import React from "react";
import he from "he";

const ListingDescription = ({ game }) => {
    // const htmlString = he.decode(game?.publisher_description);
    return (
        <div className="mb-4 flex flex-col mt-6 p-4 dark:text-white bg-white dark:bg-gray-800 rounded-lg shadow-md">
            <p className="mt-2 font-bold text-lg text-gray-900 dark:text-white">Listing Description by Broker</p>
            <p className="mt-4 text-gray-700 dark:text-white leading-relaxed">HTML</p>
        </div>

    );
};

export default ListingDescription;
// <p className="mt-4 text-gray-700 dark:text-white leading-relaxed" dangerouslySetInnerHTML={{ __html: htmlString }} />