/* Enums */
import { MonetizationMethods } from "../../../../enums";
/* Helper Components */
import { Badge } from "flowbite-react";

const MonetizationMethodBadge = ( { method, size } ) => {

    let badgeColor;

    switch (method) {
        case MonetizationMethods.IAP.value:
            badgeColor = 'success';
            break;
        case MonetizationMethods.SUBSCRIPTIONS.value:
            badgeColor = 'pink';
            break;
        case MonetizationMethods.ADS.value:
            badgeColor = 'warning';
            break;
        default:
            badgeColor = 'gray';
            break;
    }

    return (
        <Badge
            className = {`pl-3 pr-3`}
            color = {badgeColor}
            size = {size}>
            {method}
        </Badge>
    );
}

MonetizationMethodBadge.defaultProps = {
    size: "xs"
}

export default MonetizationMethodBadge;