import React, { useState, useEffect } from 'react';
import {Link, useParams} from "react-router-dom";
import {AuthenticationService, BlogService} from "../../services";
import Routes from "../../routes";

const BlogDetails = ({ match }) => {
    const {id} = useParams();

    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);

    const [blogContent, setBlogContent] = useState([{ title: '', content: '', img: '' }]);
    const [gameContent, setGameContent] = useState([{ title: '', logo: '', link: '' }]);

    const [user, setUser] = useState();

    const [htmlString, setHtmlString] = useState('');

    useEffect(() => {
        if(!user)
            setUser(AuthenticationService.getUser());
    });

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await BlogService.fetchBlog(id); // Replace with actual endpoint
                setBlog(response);
                setHtmlString(response.publisher_description)
                setLoading(false);
                setBlogContent(response.content);
                setGameContent(response.games);
            } catch (error) {
                console.error('Error fetching blogs:', error);
                setLoading(false);
            }
        };

        fetchBlog();
    }, []);

    // const htmlString = he.decode(blog.publisher_description);

    return (
        <div>
            {loading ? (
                <div>
                    <div className="w-full max-w-md mx-auto animate-pulse p-9">
                        <h1 className="h-2 bg-gray-300 rounded-lg w-52 dark:bg-gray-600"></h1>

                        <p className="w-48 h-2 mt-6 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                        <p className="w-full h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                        <p className="w-64 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                        <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                    </div>
                    <div className="w-full max-w-md mx-auto animate-pulse p-9">
                        <h1 className="h-2 bg-gray-300 rounded-lg w-52 dark:bg-gray-600"></h1>

                        <p className="w-48 h-2 mt-6 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                        <p className="w-full h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                        <p className="w-64 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                        <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                    </div>
                </div>
            ) : (
                <div className="App max-w-4xl mx-auto p-4">

                    {/* BLOG CATEGORY */}

                    {/* Conditional rendering of edit button */}
                    {user?.role === "admin" && (
                        <Link to={Routes.Blog.BlogUpdate.replace(":id", blog?._id)} className="text-blue-600 hover:text-gray-700">
                            Edit
                        </Link>
                    )}

                    <div className="container mx-auto w-full h-14">
                        <p className="uppercase p-4 text-blue-500">package deal</p>
                    </div>

                    <div className="container mx-auto py-8">
                        <h1 className="text-3xl font-semibold mb-6">{blog.blogTitle}</h1>
                        <p className="mt-2 text-xl font-normal text-gray-600">{blog.blogSubtitle}</p>
                    </div>

                    {/* DIVIDER */}
                    <hr className="my-4"/>

                    {/* AUTHOR / WRITER CARD, with image, name and title */}
                    <div className="container mx-auto py-2 flex items-center">
                        <img src={"https://i.postimg.cc/RVXdRpCg/melihbahri-white.png"} alt="Author" className="w-16 h-16 border-emerald-500 border-2 rounded-full mr-4"/>
                        <div>
                            <p className="text-lg font-semibold">{"Melih Bahri AKTAN"}</p>
                            <p className="text-gray-600">{"Founder and CEO at Scapcher.com"}</p>
                        </div>
                    </div>

                    <div className="bg-white shadow-lg rounded-lg p-4 mb-4 flex flex-col mt-6">
                        <p className="mt-2 font-bold text-black text-lg">Blog</p>

                        {/* Render parsed HTML here */}
                        <div className="mt-4" dangerouslySetInnerHTML={{ __html: htmlString }} />

                        {/* <div>{ ReactHtmlParser(htmlString) }</div> */}

                    </div>

                    {/* Related Games, with image, name and subtitle */}
                    {/* Conditional rendering of edit button */}
                    {blog?.blogType === "package-deals" && (
                        <div>
                            {/* DIVIDER */}
                            <hr className="my-4"/>

                            <div className="container mx-auto py-8">
                                <h2 className="text-2xl mb-4">Related Games</h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 px-4 py-2">
                                    {blog.games.map((game, index) => (
                                        <div key={game?._id} className="rounded-2xl bg-emerald-200">
                                            {/* Game Card */}
                                            <div className="p-2 flex items-center">
                                                <img src={game?.logo} alt="Game"
                                                     className="w-16 h-16 md:w-16 md:h-16 lg:w-16 lg:h-16 rounded-lg mr-2"/>
                                                <div>
                                                    <Link to={game?.link} className="text-lg lg:text-base text-gray-800 font-semibold underline">{game?.title}</Link>
                                                    <p className="text-lg lg:text-sm text-gray-600">{game?.subtitle}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* DIVIDER */}
                            <hr className="my-8"/>
                        </div>
                    )}

                    {/* Blog content with array */}
                    <div className="container mx-auto py-8">
                        {blog.content.map((item, index) => (
                            <div key={index} className="mb-4">
                                <h2 className="text-2xl mb-2 font-bold">{item.title}</h2>
                                <p className="text-gray-800 text-xl mt-4">{item.content}</p>
                                {item.img && <img src={item.img} className="mt-12 rounded-2xl w-full max-w-xl"/>}
                            </div>
                        ))}
                    </div>

                    {/* DIVIDER */}
                    <hr className="my-8"/>

                </div>
            )}
        </div>
    );
};

export default BlogDetails;
